import { AddressZero } from '@ethersproject/constants'
import {
  CHAIN_ID_GOERLI,
  CHAIN_ID_HARDHAT,
  CHAIN_ID_MAIN_NET,
  CHAIN_ID_MUMBAI,
  CHAIN_ID_POLYGON,
  CHAIN_ID_ROPSTEN,
} from '@tryrolljs/design-system'
import ETHLogo from '../assets/tokens/eth.png'
import MaticLogo from '../assets/tokens/matic.png'
import { Token } from '../types'

export const CHAIN_ID_FORM_TEST_NET = 132902

// TODO: Move this to Roll UI
export const nativeTokens: Record<number, Token> = {
  [CHAIN_ID_MAIN_NET]: {
    address: AddressZero,
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
    logoURI: ETHLogo,
  },
  [CHAIN_ID_ROPSTEN]: {
    address: AddressZero,
    name: 'RopstenETH',
    symbol: 'ETH',
    decimals: 18,
    logoURI: ETHLogo,
  },
  [CHAIN_ID_MUMBAI]: {
    address: AddressZero,
    name: 'MumbaiMATIC',
    symbol: 'MATIC',
    decimals: 18,
    logoURI: MaticLogo,
  },
  [CHAIN_ID_POLYGON]: {
    address: AddressZero,
    name: 'MATIC',
    symbol: 'MATIC',
    decimals: 18,
    logoURI: MaticLogo,
  },
  [CHAIN_ID_GOERLI]: {
    address: AddressZero,
    name: 'GoerliETH',
    symbol: 'ETH',
    decimals: 18,
    logoURI: ETHLogo,
  },
  [CHAIN_ID_FORM_TEST_NET]: {
    address: AddressZero,
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    logoURI: ETHLogo,
  },
  [CHAIN_ID_HARDHAT]: {
    address: AddressZero,
    name: 'GO',
    symbol: 'GO',
    decimals: 18,
    logoURI: ETHLogo,
  },
}

// TODO: Move this to Roll UI
export const testTokens = (chainId: number): Token[] => {
  if (chainId === CHAIN_ID_GOERLI) {
    return [
      {
        address: '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
        name: 'UNI',
        symbol: 'UNI',
        decimals: 18,
        logoURI: '',
      },
      {
        address: '0x9e32c0EfF886B6Ccae99350Fd5e7002dCED55F15',
        name: 'POLY',
        symbol: 'POLY',
        decimals: 18,
        logoURI: '',
      },
      {
        address: '0x470765A99c697a4CD84aCe1696B5c59151527c51',
        name: 'TESTA',
        symbol: 'TESTA',
        decimals: 18,
        logoURI: '',
      },
      {
        address: '0xF7694047baF09618581B6b1bCad78081aA9f1bc0',
        name: 'TESTB',
        symbol: 'TESTB',
        decimals: 18,
        logoURI: '',
      },
      {
        address: '0x641f89fB6b3B382A33a6b2b3FcDCDE1580A4Bd5f',
        name: 'TESTC',
        symbol: 'TESTC',
        decimals: 18,
        logoURI: '',
      },
      {
        address: '0x07865c6E87B9F70255377e024ace6630C1Eaa37F',
        name: 'USDC',
        symbol: 'USDC',
        decimals: 6,
        logoURI: '',
      },
    ]
  }
  if (chainId === CHAIN_ID_HARDHAT) {
    return []
  }
  return []
}

export const blacklistMemberships: Record<number, string[]> = {
  [CHAIN_ID_MAIN_NET]: [
    '0xf65589f1c127a67e3b2fedc1d4801bae3f46ece4c964b234fe51f72b1ecb0ead',
    '0xe29884e0eb4ee4b0eb3d16a60bf4e797734b0a675aecf5bcd5194534ef47456e',
    '0x6302ceeb956cabba72fb1ae3cd7ff6be8308eef6970e21150b809f9822e4450a',
    '0x766d2d0a9e7c8caaec7b4673adbd7d280a990e36ab2ff49e479b316d72405ff2',
  ],
}

export const FEE_SCALE = 1000000
export const FEE_SCALE_MAX_DECIMALS = 6
