import React, { useCallback, useEffect, useMemo } from 'react'
import {
  useTheme,
  Body,
  Button,
  Caption,
  FONT_SIZE_CAPTION,
  useEthAddress,
  Anchor,
} from '@tryrolljs/design-system'
import { BigNumber, ethers } from 'ethers'
import {
  useActiveCreateReviewStepsSelector,
  useCreateReviewSteps,
  useFormValuesState,
  useGoToStep,
  useResetReviewStep,
  useUpdateActivePreview,
} from '../../hooks/selectors/create'
import { AmountToken } from '../../molecules/amountToken'
import { CreateStepHeading } from '../../molecules/createStepHeading'
import DoneStatus from '../../assets/svg/phase-progress-done.svg'
import { CreateStepsId, CreateStepStatus } from '../../types/create'
import { useCreateMembership } from '../../hooks/campaigns'
import { toastError, toastSuccess } from '../../molecules/toasts'
import ShieldTickIcon from '../../assets/svg/shield-tick.svg'
import TickCircleIcon from '../../assets/svg/tick-circle.svg'
import {
  useAllTokensAllow,
  useApproveToken,
  useFetchToken,
  useTokenAllowance,
} from '../../hooks/tokens'
import { AllowanceSpenders } from '../../types'
import { useCreateMerkleTrees } from '../../hooks/phases'
import EditIcon from '../../assets/svg/edit-icon.svg'
import {
  useAllTokensHasBalanceFromForm,
  useGetTotalRewardPerToken,
} from '../../hooks/create'
import { BalanceLabelVariant } from '../../molecules/balanceLabel'

const dataInfo = {
  title: 'Launch Contract',
  description:
    'Review your Memberships contract and launch it on the blockchain.',
}

export const CreateLaunchStep = () => {
  const theme = useTheme()
  const { activeStep } = useActiveCreateReviewStepsSelector()
  const steps = useCreateReviewSteps()
  const formValues = useFormValuesState()
  const updateActivePreview = useUpdateActivePreview()
  const resetReviewSteps = useResetReviewStep()
  const { allTokensAllow } = useAllTokensAllow()
  const { hasAllBalance } = useAllTokensHasBalanceFromForm({
    lotTokens: formValues.lotInfo,
    schedules: formValues.schedules,
  })
  const { createMembership, isLoading: isLoadingCreate } = useCreateMembership()
  const { createMerkleTrees, isCreatingMerkleTrees } = useCreateMerkleTrees()
  const reviewCb = (id: CreateStepsId) => {
    if (id === CreateStepsId.launch) {
      return createMembership()
    }
    if (id === CreateStepsId.phases) {
      return createMerkleTrees()
    }
    return updateActivePreview(id)
  }

  const reviewIsLoading = (id: CreateStepsId) => {
    if (id === CreateStepsId.launch) return isLoadingCreate
    if (id === CreateStepsId.lots) return !allTokensAllow || !hasAllBalance
    if (id === CreateStepsId.phases) return isCreatingMerkleTrees
    return false
  }

  const onShareReview = useCallback(async () => {
    const formString = JSON.stringify(formValues)
    const encodeFormString = encodeURIComponent(formString)
    const url = `${location.origin}${location.pathname}?review=${encodeFormString}`
    try {
      await navigator.clipboard.writeText(url)
      toastSuccess({
        description: 'Review URL copied to clipboard!',
      })
    } catch (err) {
      toastError({
        description: 'Error genrating review URL',
      })
    }
  }, [formValues])

  useEffect(() => {
    return () => {
      resetReviewSteps()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <CreateStepHeading
        title={dataInfo.title}
        description={dataInfo.description}
        tooltip="Your Roll Memberships contract will deployed on the Ethereum blockchain. You can view your Membership contract address and other activity on the Dashboard."
        action={{
          title: 'Share Contract for Review',
          cb: onShareReview,
        }}
      />
      <div className="mt-8 mb-6">
        <Body color={theme.text.primary}>
          By launching a memberships contract, you accept{' '}
          <Anchor href="https://tryroll.com/terms-of-service/" target="_bank">
            Roll's Protocol Terms of Service
          </Anchor>
        </Body>
      </div>
      <div className="flex flex-col gap-4">
        {steps.map((step, i) => (
          <StepReviewContainer
            {...step}
            key={step.id}
            index={i}
            isActive={activeStep.id === step.id}
            cb={() => reviewCb(step.id)}
            isLoading={reviewIsLoading(step.id)}
          >
            <RenderReviewStep id={step.id} />
          </StepReviewContainer>
        ))}
      </div>
    </div>
  )
}

const RenderReviewStep: React.FC<{ id: CreateStepsId }> = ({ id }) => {
  const theme = useTheme()
  if (id === CreateStepsId.lots) return <CreateReviewLots />
  if (id === CreateStepsId.tiers) return <CreateReviewTiers />
  if (id === CreateStepsId.phases) return <CreateReviewPhases />
  if (id === CreateStepsId.launch) {
    return (
      <div className="max-w-sm">
        <Caption weight="bold" color={theme.text.secondary}>
          Send tokens to your memberships contract to let users claim tokens and
          become members in your community.
        </Caption>
      </div>
    )
  }
  return <></>
}

interface StepReviewContainerProps {
  title: string
  id: CreateStepsId
  isActive: boolean
  status: CreateStepStatus
  index: number
  cb: () => void
  isLoading: boolean
}

const StepReviewContainer: React.FC<StepReviewContainerProps> = ({
  title,
  id,
  isActive,
  cb,
  isLoading,
  status,
  index,
  children,
}) => {
  const goToStep = useGoToStep()
  const theme = useTheme()
  return (
    <div className="py-4 px-6 border border-[#EAEEF3] rounded-2xl flex flex-col">
      <div className="flex justify-between">
        <div className="flex gap-2 items-center">
          {status === CreateStepStatus.done && <DoneStatus />}
          <Body weight="bold" color={theme.text.primary}>
            {status !== CreateStepStatus.done && `${index + 1}.`} {title}
          </Body>
        </div>
        {isActive && id !== CreateStepsId.launch && (
          <div className="flex gap-4 items-center">
            <Button
              variant="secondary"
              onPress={() =>
                goToStep({ go: id, current: CreateStepsId.launch })
              }
            >
              <div className="flex items-center gap-2">
                <EditIcon />
                <Body>Edit</Body>
              </div>
            </Button>
            <Button
              variant="secondary"
              disabled={isLoading}
              onPress={() => {
                cb()
              }}
            >
              <div className="flex items-center gap-2">
                <ShieldTickIcon />
                <Body>Confirm</Body>
              </div>
            </Button>
          </div>
        )}
        {isActive && id === CreateStepsId.launch && (
          <Button variant="secondary" onPress={cb} disabled={isLoading}>
            <Body>Send tokens and launch</Body>
          </Button>
        )}
      </div>
      {isActive && <>{children}</>}
    </div>
  )
}

interface CreateReviewLotsElemProps {
  tokenAddress: string
  totalAmount: BigNumber
}

const CreateReviewLotsElem: React.FC<CreateReviewLotsElemProps> = ({
  tokenAddress,
  totalAmount,
}) => {
  const theme = useTheme()
  const { token, isLoading: isLoadingToken } = useFetchToken(tokenAddress)
  const { allowance, isLoadingAllowance } = useTokenAllowance(
    AllowanceSpenders.memberships,
    token,
  )
  const { isLoading: isApprovingToken, approveToken } = useApproveToken()
  const hasAllowance = useMemo(() => {
    if (!token) return false
    if (allowance.lt(totalAmount)) return false
    return true
  }, [token, totalAmount, allowance])

  const isDisabled = useMemo(() => {
    return (
      !token ||
      isLoadingAllowance ||
      isLoadingToken ||
      hasAllowance ||
      isApprovingToken
    )
  }, [
    isLoadingAllowance,
    isLoadingToken,
    hasAllowance,
    isApprovingToken,
    token,
  ])

  const buttonText = useMemo(() => {
    if (isLoadingAllowance || isLoadingToken) return 'Loading token...'
    if (isApprovingToken) return 'Approving...'
    if (!token) return 'No token'
    if (!hasAllowance) return `Approve ${token.name}`
    return 'Approved'
  }, [
    isLoadingAllowance,
    isLoadingToken,
    hasAllowance,
    token,
    isApprovingToken,
  ])

  const handleApproveToken = useCallback(() => {
    if (!token) return
    approveToken({
      tokenId: token.address,
      value: totalAmount,
      spender: AllowanceSpenders.memberships,
      formatValue: ethers.utils.formatUnits(totalAmount, token.decimals),
      tokenSymbol: token.symbol,
    })
  }, [token, approveToken, totalAmount])

  return (
    <div className="flex flex-col gap-2">
      <AmountToken
        fontSize={FONT_SIZE_CAPTION}
        address={tokenAddress}
        circleSize={16}
        color={theme.text.secondary}
        bigTokenAmount={totalAmount}
        balanceVariant={BalanceLabelVariant.JustWarning}
      />
      <div className="w-fit">
        <Button
          variant="secondary"
          disabled={isDisabled}
          onPress={handleApproveToken}
        >
          <div className="flex gap-2">
            <div className="w-5">
              {hasAllowance ? <TickCircleIcon /> : <ShieldTickIcon />}
            </div>
            <Body>{buttonText}</Body>
          </div>
        </Button>
      </div>
    </div>
  )
}

export const CreateReviewLots = () => {
  const theme = useTheme()
  const { lotInfo, schedules } = useFormValuesState()
  const totalTokens = useGetTotalRewardPerToken({
    schedules,
    lotTokens: lotInfo,
  })

  const totalLots = useMemo(() => {
    return schedules.reduce((accum, curr) => accum + curr.amountTotal, 0)
  }, [schedules])

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <Caption weight="bold" color={theme.text.secondary}>
          {totalLots} Lots
        </Caption>
        {totalTokens.map((token) => (
          <CreateReviewLotsElem
            key={token.address}
            tokenAddress={token.address}
            totalAmount={token.total}
          />
        ))}
      </div>
      <div className="flex flex-col gap-2">
        <Caption weight="bold" color={theme.text.primary}>
          Amount of tokens in each lot
        </Caption>
        {lotInfo.map((lot) => (
          <AmountToken
            key={lot.lotToken}
            fontSize={FONT_SIZE_CAPTION}
            address={lot.lotToken}
            circleSize={16}
            color={theme.text.secondary}
            amount={lot.lotSize}
          />
        ))}
      </div>
    </div>
  )
}

export const CreateReviewTiers = () => {
  const { metadata } = useFormValuesState()
  const { description, website, tiers } = metadata
  return (
    <div className="flex flex-col gap-6">
      <CreateReviewTiersElem title="Description" value={description} />
      <CreateReviewTiersElem title="Website" value={website} />
      {tiers.map((tier, i) => (
        <CreateReviewTiersElem
          key={`${tier.name}-${i}`}
          title={tier.name}
          value={`Requires ${tier.lots} lots or more`}
        />
      ))}
    </div>
  )
}

const CreateReviewTiersElem: React.FC<{ title: string; value: string }> = ({
  title,
  value,
}) => {
  const theme = useTheme()
  return (
    <div className="flex flex-col gap-1">
      <Caption weight="bold" color={theme.text.primary}>
        {title}
      </Caption>
      <Caption weight="bold" color={theme.text.secondary}>
        {value}
      </Caption>
    </div>
  )
}

export const CreateReviewPhases = () => {
  const { schedules, paymentAsset, fees } = useFormValuesState()
  const userAddress = useEthAddress()
  const theme = useTheme()
  const creatorFee = useMemo(() => {
    return 100 - ((fees.referralFee || 0) + (fees.rollFee || 0))
  }, [fees])
  return (
    <div className="flex flex-col gap-8">
      <ul className="flex flex-col gap-4">
        {schedules.map((schedule, i) => (
          <CreateReviewPhaseElem
            key={i}
            title={schedule.allowlist?.isActive ? 'Allowlist' : 'Public'}
            totalLots={schedule.amountTotal}
            amountToken={schedule.pricePerLot}
            token={paymentAsset}
            date={`${schedule.startDate} ${schedule.startTime} | ${schedule.endDate} ${schedule.endTime} UTC`}
            maxLots={schedule.maxBuyPerWallet}
          />
        ))}
      </ul>
      <div className="flex flex-col gap-4">
        <Body weight="bold" color={theme.text.primary}>
          Fees and Addresses
        </Body>
        <div className="grid grid-cols-2 grid-rows-3 gap-5">
          <div className="flex flex-col gap-2">
            <Caption weight="bold" color={theme.text.primary}>
              Creator Wallet Address
            </Caption>
            <Caption weight="bold" color={theme.text.secondary}>
              {userAddress}
            </Caption>
          </div>
          <div className="flex flex-col gap-2">
            <Caption weight="bold" color={theme.text.primary}>
              Creator %
            </Caption>
            <Caption weight="bold" color={theme.text.secondary}>
              {creatorFee}%
            </Caption>
          </div>
          <div className="flex flex-col gap-2">
            <Caption weight="bold" color={theme.text.primary}>
              Referral Wallet Address
            </Caption>
            <Caption weight="bold" color={theme.text.secondary}>
              {fees.referral || '-'}
            </Caption>
          </div>
          <div className="flex flex-col gap-2">
            <Caption weight="bold" color={theme.text.primary}>
              Referral Fee %
            </Caption>
            <Caption weight="bold" color={theme.text.secondary}>
              {fees.referralFee ? `${fees.referralFee}%` : '-'}
            </Caption>
          </div>
          <div className="flex flex-col gap-2">
            <Caption weight="bold" color={theme.text.primary}>
              Roll Fee %
            </Caption>
            <Caption weight="bold" color={theme.text.secondary}>
              {fees.rollFee ? `${fees.rollFee}%` : '-'}
            </Caption>
          </div>
        </div>
      </div>
    </div>
  )
}

interface CreateReviewPhaseElemProps {
  title: string
  totalLots: number
  amountToken: string
  token: string
  date: string
  maxLots?: number
}
const CreateReviewPhaseElem: React.FC<CreateReviewPhaseElemProps> = ({
  title,
  totalLots,
  amountToken,
  token,
  date,
  maxLots,
}) => {
  const theme = useTheme()
  return (
    <li className="flex flex-col gap-1">
      <Caption weight="bold" color={theme.text.primary}>
        {title}
      </Caption>
      <Caption weight="bold" color={theme.text.secondary}>
        {totalLots} Lots
      </Caption>
      <div className="flex gap-2 items-center">
        <AmountToken
          fontSize={FONT_SIZE_CAPTION}
          address={token}
          circleSize={16}
          color={theme.text.secondary}
          amount={amountToken}
        />
        <Caption weight="bold" color={theme.text.secondary}>
          per lot
        </Caption>
      </div>
      <Caption weight="bold" color={theme.text.secondary}>
        {date}
      </Caption>
      {maxLots && (
        <Caption weight="bold" color={theme.text.secondary}>
          {maxLots} lots max per wallet
        </Caption>
      )}
    </li>
  )
}
