import React, { useMemo } from 'react'
import { Button, useTheme, Body } from '@tryrolljs/design-system'
import { useTotalClaimedWallets } from '../../hooks/campaigns'
import { InfoMathToken } from '../../molecules/infoMathToken'
import { LotsClaimedProgress } from '../../molecules/lotsClaimedProgress'
import { StatsInfoCard, TStats } from '../../molecules/statsInfoCard'
import { TimeCounter } from '../../molecules/timeCounter'
import { TokenQuantity } from '../../molecules/tokenQuantity'
import { TokenTitle } from '../../molecules/tokenTitle'
import {
  useNavigateCampaignDetails,
  useNavigateEditPage,
} from '../../navigation/actions'
import { Campaign, StatusPhase, TimeLabel } from '../../types'

import {
  checkIsPhasePublic,
  formatMetadata,
  getPhaseTime,
  getStatusPhase,
  getTotalGeneratedFee,
  getTimeLabel,
} from '../../core/phases'

import {
  getClosestPhase,
  getTotalLots,
  getAllTokensInCampaign,
  isFullSoldOut,
} from '../../core/campaigns'

import { formatInUTC } from '../../utils/dates'
import { MembershipPhasesList } from '../membershipPhasesList'
import { useCampaignByAddressSelector } from '../../hooks/selectors/campaign'

export enum MembershipCardVariant {
  regular = 'regular',
  simple = 'simple',
  referral = 'referral',
}

interface MembershiptCardProps {
  id?: string
  variant?: MembershipCardVariant
}

export const MembershipCard: React.FC<MembershiptCardProps> = ({
  id,
  variant = MembershipCardVariant.regular,
}) => {
  const campaign = useCampaignByAddressSelector(id ?? '')
  const theme = useTheme()
  const navigateCampaignDetails = useNavigateCampaignDetails()
  const navigateEditPage = useNavigateEditPage()

  const metadata = useMemo(
    () => formatMetadata(campaign?.metadata ?? ''),
    [campaign],
  )

  const tokens: string[] = useMemo(
    () => getAllTokensInCampaign(campaign),
    [campaign],
  )

  const closestPhase = useMemo(() => getClosestPhase(campaign), [campaign])

  const closestPhaseStatus = useMemo(
    () => (closestPhase ? getStatusPhase(closestPhase) : StatusPhase.done),
    [closestPhase],
  )

  const hasComingPhase = useMemo(() => {
    if (!campaign || !campaign.phasesData) return false
    return campaign.phasesData?.some(
      (phase) => getStatusPhase(phase) === StatusPhase.locked,
    )
  }, [campaign])

  const showProgress = useMemo(() => {
    return (
      variant === MembershipCardVariant.simple &&
      closestPhaseStatus === StatusPhase.active
    )
  }, [variant, closestPhaseStatus])

  const showPhasesInfo = useMemo(() => {
    return variant !== MembershipCardVariant.simple
  }, [variant])

  const showEditButton = useMemo(() => {
    return variant === MembershipCardVariant.regular && hasComingPhase
  }, [variant, hasComingPhase])

  const isSoldOut = useMemo(() => {
    return isFullSoldOut(campaign)
  }, [campaign])

  const showTime = useMemo(
    () => closestPhaseStatus === StatusPhase.done || isSoldOut,
    [closestPhaseStatus, isSoldOut],
  )

  const time = useMemo(() => {
    return getPhaseTime(closestPhase)
  }, [closestPhase])

  const timeLabel = useMemo(() => {
    if (isSoldOut) return TimeLabel.soldOut
    return getTimeLabel(closestPhase)
  }, [closestPhase, isSoldOut])

  if (!campaign) return null

  const paymentToken = campaign.phasesData?.[0]?.paymentAsset.token

  return (
    <div className="border flex flex-col gap-8 rounded-3xl w-full p-10">
      <div className="flex justify-between">
        <div className="flex flex-col gap-2 max-w-lg">
          <TokenTitle tokens={tokens} />
          <Body color={theme.text.primary}>{metadata?.description ?? ''}</Body>
        </div>
        <MembershipCardStats campaign={campaign} />
      </div>
      {showPhasesInfo && (
        <div className="flex justify-between">
          <div className="flex flex-col gap-8">
            <TokenQuantity campaignId={campaign.campaignId} />
            <MembershipPhasesList campaignId={campaign.campaignId} />
          </div>
          <InfoMathToken
            title="Total Fees Generated:"
            amount={getTotalGeneratedFee(campaign.phasesData ?? [])}
            token={paymentToken ?? ''}
            description="In fees"
          />
        </div>
      )}
      <div className="flex justify-between items-center">
        <div className="flex gap-12">
          <TimeCounter time={time} label={timeLabel} showTime={!showTime} />
          {showProgress && (
            <LotsClaimedProgress campaignId={campaign.campaignId} />
          )}
        </div>
        <div className="flex gap-4">
          {showEditButton && (
            <Button
              variant="secondary"
              isHovering
              onPress={() => navigateEditPage(campaign.campaignId)}
            >
              <Body color={theme.text.primary}>Edit</Body>
            </Button>
          )}
          <Button
            variant="secondary"
            isHovering
            onPress={() => navigateCampaignDetails(campaign.campaignId)}
          >
            <Body color={theme.text.primary}>View Membership</Body>
          </Button>
        </div>
      </div>
    </div>
  )
}

const MembershipCardStats: React.FC<{ campaign: Campaign }> = ({
  campaign,
}) => {
  const totalLots: number = useMemo(() => getTotalLots(campaign), [campaign])
  const { totalClaimedWallets } = useTotalClaimedWallets(campaign.phases)

  const closestPhase = useMemo(() => getClosestPhase(campaign), [campaign])

  const closestPhaseStatus = useMemo(
    () => (closestPhase ? getStatusPhase(closestPhase) : null),
    [closestPhase],
  )

  const dateDescription = useMemo(() => {
    if (!closestPhase || !closestPhaseStatus) return 'Membership Ended'
    let endDate = (closestPhase.start + closestPhase.duration) * 1000
    const name = checkIsPhasePublic(closestPhase.merkleRoot)
      ? 'Public'
      : 'Allowlist'
    let prefix = 'ends'
    if (closestPhaseStatus === StatusPhase.locked) {
      prefix = 'starts'
      endDate = closestPhase.start * 1000
    }
    const formatDate = formatInUTC(endDate, 'MMMM dd, yyyy, K:mmaaa')
    return `${name} Phase ${prefix} on ${formatDate} UTC`
  }, [closestPhase, closestPhaseStatus])

  const availableLots: number = useMemo(() => {
    if (!campaign?.phasesData) return 0
    return campaign.phasesData
      .map((elem) => elem.amountTotal - elem.released)
      .reduce((acc, curr) => curr + acc, 0)
  }, [campaign])

  const stats: TStats[] = useMemo(() => {
    return [
      {
        value: totalLots.toString() ?? '',
        description: 'Total lots',
      },
      {
        value: availableLots.toString() ?? '',
        description: 'Available Lots',
      },
      {
        value: totalClaimedWallets || '0',
        description: 'Wallets Claimed',
      },
    ]
  }, [totalLots, availableLots, totalClaimedWallets])

  return <StatsInfoCard description={dateDescription} stats={stats} />
}
