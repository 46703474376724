import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { Token } from '../../types'

interface TokensState extends EntityState<Token> {
  isLoadingToken: Record<string, boolean>
}

export const tokensAdapter = createEntityAdapter<Token>({
  selectId: (token) => token.address,
})

const initialState: TokensState = tokensAdapter.getInitialState({
  isLoadingToken: {},
})

export const tokensSlice = createSlice({
  name: 'tokens',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<Token>) => {
      tokensAdapter.upsertOne(state, action.payload)
    },
    setBatchTokens: (state, action: PayloadAction<Token[]>) => {
      tokensAdapter.upsertMany(state, action.payload)
    },
    updateToken: (state, action: PayloadAction<Update<Token>>) => {
      tokensAdapter.updateOne(state, action.payload)
    },
    setIsLoadingToken: (
      state,
      action: PayloadAction<{ value: boolean; id: string }>,
    ) => {
      state.isLoadingToken[action.payload.id] = action.payload.value
    },
    setIsLoadingTokens: (
      state,
      action: PayloadAction<{ value: boolean; ids: string[] }>,
    ) => {
      const { ids, value } = action.payload
      ids.forEach((id) => {
        state.isLoadingToken[id] = value
      })
    },
  },
})

export const {
  // Returns a list of all Tokens
  selectAll: selectAllTokens,
  // Returns the token that match by address
  selectById: selectTokenByAddress,
  // Returns a list of addresses
  selectIds: selectTokenAddresses,
  // Returns all tokens normalized by address
  selectEntities: selectTokenEntities,
} = tokensAdapter.getSelectors((state: RootState) => state.tokens)

export const selectSomeTokensByAddress = createSelector(
  [selectTokenEntities, (_, addresses: string[]) => addresses],
  (tokens, addresses) =>
    addresses.reduce(
      (acc, curr) => (tokens[curr] ? [...acc, tokens[curr] as Token] : acc),
      [] as Token[],
    ),
)

export const selectMissingTokensByAddress = createSelector(
  [selectTokenEntities, (_, addresses: string[]) => addresses],
  (tokens, addresses) => addresses.filter((address) => !tokens[address]),
)

export const {
  setToken,
  setBatchTokens,
  updateToken,
  setIsLoadingToken,
  setIsLoadingTokens,
} = tokensSlice.actions

export default tokensSlice.reducer
