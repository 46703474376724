import React from 'react'
import { CircleImg, Header, useTheme } from '@tryrolljs/design-system'
import { useFetchTokens } from '../../hooks/tokens'
import { TokenTitleLoader } from '../loaders'

interface TokenTitleProps {
  tokens: string[]
}

export const TokenTitle: React.FC<TokenTitleProps> = ({ tokens }) => {
  const { tokens: tokensData, isLoading } = useFetchTokens(tokens)
  const theme = useTheme()
  const imgSize = 32
  const innerGap = 4
  return (
    <div className="flex gap-3 items-center">
      {isLoading && <TokenTitleLoader />}
      {!!tokensData?.length && (
        <>
          <div
            className="relative flex"
            style={{
              width: (imgSize - innerGap) * tokensData.length,
              height: 32,
            }}
          >
            {tokensData.map((token, i) => (
              <div
                key={token.address}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: `${(imgSize - innerGap) * i}px`,
                }}
              >
                <CircleImg uri={token.logoURI} size={imgSize} />
              </div>
            ))}
          </div>
          <Header weight="bold" color={theme.text.primary}>
            {tokensData.map((elem) => elem.name).join(', ')}
          </Header>
        </>
      )}
    </div>
  )
}
