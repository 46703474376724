import React from 'react'

/* Roll-ui utils */
import {
  CHAIN_ID_MAIN_NET,
  CHAIN_ID_POLYGON,
  Footer,
  InvalidNetworkBanner,
} from '@tryrolljs/design-system'

/* Molecules */
import { PageHeader } from '../../molecules/pageHeader'

/* Templates */
import { Background } from '../pageBackground'
import { PageContent } from '../pageContent'

interface PageTemplateProps {
  backTab?: boolean
  isFullWidth?: boolean
  withFooter?: boolean
}

export const PageTemplate: React.FC<PageTemplateProps> = ({
  children,
  isFullWidth = false,
  withFooter = true,
}) => {
  return (
    <Background elements={withFooter ? 3 : 2}>
      <div className="h-fit">
        <PageHeader />
        <InvalidNetworkBanner
          title="Unsupported chain. Please switch to Etheruem Mainnet or Polygon in your wallet"
          supportedChainIDs={[CHAIN_ID_MAIN_NET, CHAIN_ID_POLYGON]}
        />
      </div>
      <PageContent isFullWidth={isFullWidth}>{children}</PageContent>
      {withFooter && (
        <div className="flex flex-1 items-end">
          <Footer />
        </div>
      )}
    </Background>
  )
}
