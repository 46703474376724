import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../..";
import { selectAllTokens, selectMissingTokensByAddress, selectSomeTokensByAddress, selectTokenByAddress, setBatchTokens, setIsLoadingToken, setIsLoadingTokens, setToken, updateToken } from "../../../state/tokens/reducer";
import { Token } from "../../../types";

export const useTokenByAddressSelector = (id: string) => {
  return useAppSelector(state => selectTokenByAddress(state, id));
};

export const useTokensByAddressSelector = (ids: string[]) => {
  return useAppSelector(state => selectSomeTokensByAddress(state, ids));
};

export const useMissingTokensSelector = (ids: string[]) => {
  return useAppSelector(state => selectMissingTokensByAddress(state, ids));
};

export const useIsLoadingTokenSelector = (id: string) => {
  return useAppSelector(state => !!state.tokens.isLoadingToken[id]);
};

export const useIsLoadingBatchTokenSelector = (ids: string[]) => {
  return useAppSelector(state => ids.filter((id) => !!state.tokens.isLoadingToken[id]));
};

// Actions
export const useSetIsTokenLoading = () => {
  const dispatch = useAppDispatch();
  return useCallback((id: string, value: boolean) => {
    dispatch(setIsLoadingToken({ id, value }));
  }, [dispatch]);
};

export const useSetIsLoadingTokens = () => {
  const dispatch = useAppDispatch();
  return useCallback((ids: string[], value: boolean) => {
    dispatch(setIsLoadingTokens({ ids, value }));
  }, [dispatch]);
};

export const useSetToken = () => {
  const dispatch = useAppDispatch();
  return useCallback((token: Token) => {
    dispatch(setToken(token));
  }, [dispatch]);
};

export const useSetBatchTokens = () => {
  const dispatch = useAppDispatch();
  return useCallback((tokens: Token[]) => {
    dispatch(setBatchTokens(tokens));
  }, [dispatch]);
};

export const useAllTokensSelector = () => {
  return useAppSelector(selectAllTokens);
};

export const useUpdateToken = () => {
  const dispatch = useAppDispatch();
  return useCallback((tokenId: string, changes: Partial<Token>) => {
    dispatch(updateToken({ id: tokenId, changes }));
  }, [dispatch]);
};
