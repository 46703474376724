import { configureStore } from "@reduxjs/toolkit";
import tokensReducer from "./state/tokens/reducer";
import campaignsReducer from "./state/campaigns/reducer";
import buyReducer from "./state/buy/reducer";
import createStateReducer from "./state/create/reducer";

export const store = configureStore({
  reducer: {
    tokens: tokensReducer,
    campaigns: campaignsReducer,
    buy: buyReducer,
    create: createStateReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
