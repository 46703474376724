import React from "react";
import TrashIcon from "../../assets/svg/trash.svg";

export const RemoveButtonIcon: React.FC<{ onPress: () => void }> = ({
  onPress
}) => {
  return (
    <div
      onClick={onPress}
      className="flex items-center justify-center rounded-xl border border-[#EAEEF3] w-8 h-8 cursor-pointer"
    >
      <div className="w-5">
        <TrashIcon />
      </div>
    </div>
  );
};
