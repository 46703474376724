import clsx from 'clsx'
import { isAddress } from 'ethers/lib/utils'
import React, { useMemo } from 'react'
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { Button, useEthAddress } from '@tryrolljs/design-system'
import { BigNumber } from 'ethers'
import {
  useCompleteStep,
  useFormValuesState,
  useMinRollFeeSelector,
  useUpdateFormState,
} from '../../hooks/selectors/create'
import { CreateStepHeading } from '../../molecules/createStepHeading'
import { InfoMathToken } from '../../molecules/infoMathToken'
import { InputContainer } from '../../molecules/inputContainer'
import { CreateFormInputState } from '../../types'
import { CreateStepsId } from '../../types/create'
import PercentageIcon from '../../assets/svg/percentage-icon.svg'
import { useGetGeneratedFee } from '../../hooks/create'
import { getDecimalLength } from '../../utils'
import { FEE_SCALE, FEE_SCALE_MAX_DECIMALS } from '../../contracts/constants'

const dataInfo = {
  phases: {
    title: 'Fees',
    description:
      'Direct your membership fees to your wallet address or split with another user.',
  },
  fees: {
    title: 'Total Fees Generated',
    description:
      'The estimated total fees generated through Roll Memberships, calculated automatically.',
  },
}

interface CreateFeesStepProps {}

export const CreateFeesStep: React.FC<CreateFeesStepProps> = () => {
  const defaultValues = useFormValuesState()
  const { updateFees } = useUpdateFormState()
  const minRollFee = useMinRollFeeSelector()
  const completeStep = useCompleteStep()

  const methods = useForm<CreateFormInputState>({
    defaultValues: {
      ...defaultValues,
      fees: {
        ...defaultValues.fees,
        rollFee: minRollFee,
      },
    },
    mode: 'onChange',
  })

  const onSubmit = () => {
    methods.handleSubmit((data) => {
      updateFees(data.fees)
      completeStep(CreateStepsId.fees)
    })()
  }

  const { schedules, paymentAsset } = defaultValues

  const rollFee = useWatch({
    control: methods.control,
    name: 'fees.rollFee',
  })

  const referralFee = useWatch({
    control: methods.control,
    name: 'fees.referralFee',
  })

  const subTotal = useGetGeneratedFee({
    paymentAddress: paymentAsset,
    schedules,
  })

  const totalGeneratedFees = useMemo(() => {
    if (isNaN(rollFee) || isNaN(referralFee)) return subTotal
    const fullRollFee = Math.round((rollFee / 100) * FEE_SCALE)
    const fullReferralFee = Math.round((referralFee / 100) * FEE_SCALE)
    const givenPercentage = BigNumber.from(fullRollFee + fullReferralFee)
    if (givenPercentage.gte(FEE_SCALE)) return subTotal
    return subTotal.sub(subTotal.mul(givenPercentage).div(FEE_SCALE))
  }, [rollFee, referralFee, subTotal])

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-8">
        <CreateStepHeading
          title={dataInfo.phases.title}
          description={dataInfo.phases.description}
          tooltip="These are the on-chain fees that you will generate from Roll Memberships. Decide which wallet address they will go to and how the fees will be split."
        />
        <FeesElem />
        <div className="flex flex-col gap-6">
          <CreateStepHeading
            title={dataInfo.fees.title}
            description={dataInfo.fees.description}
          />
          <div className="w-fit">
            <InfoMathToken
              amount={totalGeneratedFees}
              title="Total fees:"
              token={paymentAsset}
              description="In fees"
            />
          </div>
        </div>
        <div className="w-fit">
          <Button variant="primary" title="Continue" onPress={onSubmit} />
        </div>
      </div>
    </FormProvider>
  )
}

const FeesElem: React.FC = () => {
  const {
    register,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<CreateFormInputState>()
  const userAddress = useEthAddress()
  const minRollFee = useMinRollFeeSelector()
  return (
    <div className="flex flex-col gap-6 px-6 py-4 border border-[#EAEEF3] rounded-2xl">
      <div className="flex flex-col gap-6">
        {/* <div className="flex gap-6 w-full">
          <InputContainer label="Creator Wallet Address" className={{ container: "w-full" }}>
            <input type="text" />
          </InputContainer>
          <InputContainer label="Creator %" className={{ container: "w-full" }}>
            <input type="number" />
          </InputContainer>
        </div> */}
        <div className="flex gap-6 w-full">
          <InputContainer
            label="Referral Wallet Address"
            className={{ container: 'w-full' }}
            error={errors.fees?.referral?.message}
            tooltip="If you are working with a partner on Roll Memberships, insert their ETH wallet address here to split fees or enter the wallet address of the charity you support"
          >
            <Controller
              control={control}
              name="fees.referral"
              rules={{
                validate: {
                  isAddress: (v) =>
                    v === '' || isAddress(v) || 'Value must be an address',
                  isNotCreator: (v) =>
                    v !== userAddress ||
                    "Value has to be different from creator's wallet",
                },
              }}
              render={({ field: { value, onChange } }) => (
                <input
                  type="text"
                  value={value}
                  className="w-full"
                  placeholder="0x1d...Cae2"
                  onChange={(e) => {
                    if (!e.target.value) {
                      setValue('fees.referralFee', 0)
                    }
                    onChange(e.target.value)
                  }}
                />
              )}
            />
          </InputContainer>
          <InputContainer
            label="Referral %"
            tooltip="The percentage of fees that will go to the referral wallet address."
            isDisabled={!watch('fees.referral')}
            rightIcon={<PercentageIcon />}
            className={{
              container: 'w-full',
            }}
            error={errors.fees?.referralFee?.message}
          >
            <input
              type="number"
              max={100}
              className={clsx(
                'w-full',
                !watch('fees.referral') && 'cursor-not-allowed',
              )}
              disabled={!watch('fees.referral')}
              {...register('fees.referralFee', {
                valueAsNumber: true,
                validate: {
                  lengthDecimals: (value) =>
                    getDecimalLength(value.toString()) <=
                      FEE_SCALE_MAX_DECIMALS ||
                    `Value can have a max of ${FEE_SCALE_MAX_DECIMALS} decimal units`,
                },
                max: {
                  value: 100,
                  message: 'Value most be equal or lower than 100',
                },
                min: {
                  value: 0,
                  message: 'Value most be equal or higher than 0',
                },
              })}
            />
          </InputContainer>
        </div>
        <div className="flex gap-6 w-full">
          <InputContainer
            label="Roll Protocol Fees %"
            className={{
              container: 'w-full',
              inputContainer: 'bg-[#EAEEF3] cursor-not-allowed',
            }}
            tooltip="The percentage of fees that will go to the Roll protocol."
            error={errors.fees?.rollFee?.message}
            rightIcon={<PercentageIcon />}
          >
            <input
              type="number"
              max={100}
              min={1}
              className="w-full cursor-not-allowed"
              disabled
              {...register('fees.rollFee', {
                valueAsNumber: true,
                max: {
                  value: 100,
                  message: 'Value most be equal or lower than 100',
                },
                min: {
                  value: minRollFee,
                  message: `Value most be equal or higher than ${minRollFee}`,
                },
              })}
            />
          </InputContainer>
          <div className="w-full" />
        </div>
      </div>
    </div>
  )
}
