import React, { useMemo } from 'react'
import { Body, SubHeader, useTheme } from '@tryrolljs/design-system'
import { AmountToken } from '../../molecules/amountToken'
import {
  getAllTokenLotSize,
  getAllTokensInCampaign,
  getTotalLots,
} from '../../core/campaigns'
import { useCampaignByAddressSelector } from '../../hooks/selectors/campaign'
import { formatNumber } from '../../utils'

interface Row {
  title: string
  value: React.ReactElement
}

interface CampaignDetailsTableProps {
  campaignId: string
}

export const CampaignDetailsTable: React.FC<CampaignDetailsTableProps> = ({
  campaignId,
}) => {
  const campaign = useCampaignByAddressSelector(campaignId)
  const theme = useTheme()

  const allTokensList = useMemo(
    () => getAllTokensInCampaign(campaign),
    [campaign],
  )
  const totalLots = useMemo(() => getTotalLots(campaign), [campaign])
  const tokensLostSize = useMemo(() => getAllTokenLotSize(campaign), [campaign])

  const rows = useMemo<Row[]>(
    () => [
      {
        title: 'Total tokens in contract',
        value: (
          <ul className="flex flex-col gap-1.5 items-start">
            {allTokensList.map((addr) => (
              <AmountToken
                key={addr}
                address={addr}
                autoValue="contractBalance"
              />
            ))}
          </ul>
        ),
      },
      {
        title: 'Total lots',
        value: (
          <SubHeader weight="semiBold" color={theme.text.primary}>
            {formatNumber(totalLots)}
          </SubHeader>
        ),
      },
      {
        title: 'Amount of tokens in each lot',
        value: (
          <ul className="flex flex-col gap-1.5 items-start">
            {tokensLostSize.map((token) => (
              <AmountToken
                key={token.addr}
                address={token.addr}
                bigTokenAmount={token.amount}
              />
            ))}
          </ul>
        ),
      },
      {
        title: 'Token release time',
        value: (
          <SubHeader weight="semiBold" color={theme.text.primary}>
            No Lockup
          </SubHeader>
        ),
      },
      {
        title: 'Fees',
        value: (
          <AmountToken
            address={campaign?.phasesData?.[0].paymentAsset.token ?? ''}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <div>
      {rows.map((r, i) => {
        return (
          <div
            key={i}
            className="flex"
            style={{
              maxWidth: 600,
              backgroundColor: i % 2 !== 0 ? 'rgb(229 231 235)' : undefined,
            }}
          >
            <div className="flex flex-1 px-4 py-2 items-start">
              <Body color={theme.text.secondary}>{r.title}</Body>
            </div>
            <div className="flex flex-1 px-2 py-1 items-center">{r.value}</div>
          </div>
        )
      })}
    </div>
  )
}
