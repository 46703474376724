import { BigNumber } from 'ethers'
import { Memberships } from '@roll-network/contract-bindings'
import { TransactionCBs } from '../types'
import { formatPhase } from '../core/phases'
import { isNativeToken } from '../core/tokens'
import { ErrorCode, getContractError } from './errors'

export const getPhasesById = async ({
  ids,
  contract,
}: {
  ids: string[]
  contract: Memberships.MembershipsView
}) => {
  const phases = await Promise.all(ids.map((id) => contract.getSchedule(id)))
  return phases
    .map((phase, i) => formatPhase(phase, ids[i]))
    .sort((a, b) => a.start - b.start)
}

interface GetClaimedPhaseProps {
  contract: Memberships.MembershipsView
  value: number
  phaseId: string
}
export const getClaimedPhase = async ({
  phaseId,
  contract,
  value,
}: GetClaimedPhaseProps) => {
  const response = await contract.getClaimed(phaseId, value)
  if (!response) return BigNumber.from(0)
  return response
}

interface GetBuyPerWalletProps {
  contract: Memberships.MembershipsView
  phaseId: string
  userAddress: string
}
export const getBuyPerWallet = async ({
  contract,
  phaseId,
  userAddress,
}: GetBuyPerWalletProps) => {
  const response = await contract.getBuyPerWallet(phaseId, userAddress)
  if (!response) return BigNumber.from(0)
  return response
}

export const getReferral = async (
  phaseId: string,
  contract: Memberships.MembershipsView,
) => {
  const response = await contract.getReferral(phaseId)
  return response
}

export const claimOwnerFees = async ({
  phaseId,
  contract,
  ...cb
}: { phaseId: string; contract: Memberships.Memberships } & TransactionCBs) => {
  try {
    const claimtx = await contract.claim(phaseId)
    cb.onSubmit(claimtx.hash)
    await claimtx.wait()
    cb.onSuccess()
  } catch (error) {
    cb.onError(getContractError(error).message)
  }
}

export const claimReferralFees = async ({
  phaseId,
  contract,
  ...cb
}: { phaseId: string; contract: Memberships.Memberships } & TransactionCBs) => {
  try {
    const claimtx = await contract.claimReferral(phaseId)
    cb.onSubmit(claimtx.hash)
    await claimtx.wait()
    cb.onSuccess()
  } catch (error) {
    cb.onError(getContractError(error).message)
  }
}

export const claimUnsoldTokens = async ({
  phaseId,
  contract,
  ...cb
}: { phaseId: string; contract: Memberships.Memberships } & TransactionCBs) => {
  try {
    const claimtx = await contract.claimUnsoldTokens(phaseId)
    cb.onSubmit(claimtx.hash)
    await claimtx.wait()
    cb.onSuccess()
  } catch (error) {
    cb.onError(getContractError(error).message)
  }
}

export interface BuyPhaseProps {
  phaseId: string
  paymentAddr: string
  tokenName: string
  lots: number
  totalToPay: BigNumber
  proof?: string[]
  isVerify?: boolean
}

export const handleBuyPhase = async ({
  phaseId,
  lots,
  paymentAddr,
  contract,
  totalToPay,
  proof,
  isVerify = false,
  ...cb
}: BuyPhaseProps & { contract: Memberships.Memberships } & TransactionCBs) => {
  try {
    const config: { value?: BigNumber } = {}

    if (isNativeToken(paymentAddr)) {
      config.value = totalToPay
    }

    if (proof && !isVerify) {
      throw new Error(ErrorCode.ErrorME10ActionAllowlisted)
    }
    // Run Buy method from Membership contract
    const buyTx = proof
      ? await contract.buyWithAllowlist(phaseId, lots, proof, config)
      : await contract.buy(phaseId, lots, config)

    cb.onSubmit(buyTx.hash)

    await buyTx.wait()
    cb.onSuccess()
  } catch (error) {
    cb.onError(getContractError(error).message)
  }
}

interface UpdateMerkleRootProps {
  contract: Memberships.Memberships
  phaseId: string
  root: string
}

export const updateMerkleRoot = async ({
  contract,
  phaseId,
  root,
  ...cb
}: UpdateMerkleRootProps & TransactionCBs) => {
  try {
    const tx = await contract.setAllowlist(phaseId, root)
    cb.onSubmit(tx.hash)
    await tx.wait()
    cb.onSuccess()
    return { success: true }
  } catch (error) {
    const contractError = getContractError(error)
    cb.onError(contractError.message)
    return {
      success: false,
      errorCode: contractError.code || contractError.message,
    }
  }
}
