import { ethers } from 'ethers'
import React from 'react'
import {
  Caption,
  SubHeader,
  Header,
  useTheme,
  Body,
  CopyLink,
} from '@tryrolljs/design-system'
import {
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  EmailShareButton,
} from 'react-share'
import TwitterIcon from '../../assets/svg/twitter.svg'
import FacebookIcon from '../../assets/svg/facebook-f.svg'
import LinkedinIcon from '../../assets/svg/linkedin.svg'
import TelegramIcon from '../../assets/svg/telegram.svg'
import EmailIcon from '../../assets/svg/email.svg'
import { useFetchToken } from '../../hooks/tokens'
import { PhaseStatusIcon } from '../../molecules/phaseStatusIcon'
import { Phase } from '../../types'
import { checkIsPhasePublic, getStatusPhase } from '../../core/phases'
import { DEFAULT_DATE_FORMAT, formatInUTC } from '../../utils/dates'
import { useCampaignByAddressSelector } from '../../hooks/selectors/campaign'
import { formatNumber } from '../../utils'

interface ShareCampaignProps {
  campaignId: string
}

export const ShareCampaign: React.FC<ShareCampaignProps> = ({ campaignId }) => {
  const campaign = useCampaignByAddressSelector(campaignId)
  const theme = useTheme()
  const website = window.location.href
  if (!campaign) return null
  return (
    <div>
      <Header weight="bold" color={theme.text.primary}>
        Share
      </Header>
      <div className="flex mt-6 mb-3">
        <TwitterShareButton url={website}>
          <SocialIcon icon={<TwitterIcon />} />
        </TwitterShareButton>
        <FacebookShareButton url={website}>
          <SocialIcon icon={<FacebookIcon />} />
        </FacebookShareButton>
        <LinkedinShareButton url={website}>
          <SocialIcon icon={<LinkedinIcon />} />
        </LinkedinShareButton>
        <TelegramShareButton url={website}>
          <SocialIcon icon={<TelegramIcon />} />
        </TelegramShareButton>
        <EmailShareButton url={website}>
          <SocialIcon icon={<EmailIcon />} />
        </EmailShareButton>
      </div>
      {website && <CopyLink url={website} />}
      <Phases phases={campaign.phasesData ?? []} />
    </div>
  )
}

interface PhasesProps {
  phases: Phase[]
}

const Phases: React.FC<PhasesProps> = ({ phases }) => {
  const theme = useTheme()
  return (
    <div className="mt-4">
      <SubHeader weight="bold" color={theme.text.primary}>
        Phases
      </SubHeader>
      <div className="mt-4">
        {phases.map((phase, i) => (
          <PhaseElem
            key={`${phase.start}-${i}`}
            last={i + 1 === phases.length}
            index={i}
            phase={phase}
          />
        ))}
      </div>
    </div>
  )
}

interface PhaseElemProps {
  phase: Phase
  last?: boolean
  index: number
}

const PhaseElem: React.FC<PhaseElemProps> = ({ last, index, phase }) => {
  const theme = useTheme()
  const { token } = useFetchToken(phase.paymentAsset.token)
  const price = ethers.utils.formatUnits(phase.pricePerLot, token?.decimals)
  return (
    <div className="flex">
      <div className="flex flex-col items-center mr-4">
        <div className="w-5">
          <PhaseStatusIcon status={getStatusPhase(phase)} />
        </div>
        {!last && <div className="border flex flex-1 w-0 my-1" />}
      </div>
      <div className="flex flex-col mb-6">
        <Body color={theme.text.primary} weight="semiBold">
          {checkIsPhasePublic(phase.merkleRoot) ? 'Public' : `WL ${index + 1}`}
        </Body>
        <Caption color={theme.text.secondary} weight="semiBold">
          {formatInUTC(phase.start * 1000, DEFAULT_DATE_FORMAT)} | @ {price}{' '}
          {token?.symbol} | {formatNumber(phase.amountTotal)} Lots
        </Caption>
      </div>
    </div>
  )
}

const SocialIcon: React.FC<{ icon: React.ReactElement }> = ({ icon }) => {
  const theme = useTheme()

  return (
    <div
      className="mr-2 flex items-center justify-center rounded-xl"
      style={{
        backgroundColor: theme.background.highlight,
        height: 36,
        width: 36,
      }}
    >
      {icon}
    </div>
  )
}
