export enum CreateStepStatus {
  todo = "TODO",
  active = "ACTIVE",
  done = "DONE"
}

export enum CreateStepsId {
  lots = "LOTS",
  tiers = "TIERS",
  phases = "PHASES",
  fees = "FEES",
  launch = "LAUNCH"
}

export type CreateStepData = {
  id: CreateStepsId,
  title: string,
  status: CreateStepStatus
};
