import { isPast } from 'date-fns'
import { BigNumber, ethers } from 'ethers'
import { formatBytes32String } from 'ethers/lib/utils'
import {
  AssetStruct,
  ContractAssetStruct,
  ContractPhase,
  MerkleTrees,
  Metadadata,
  Phase,
  StatusPhase,
  TimeLabel,
} from '../../types'
import { getFee } from '../../utils'
import { createMerkleTree, getProof } from '../../utils/merkletree'
import { getPinFromPinata } from '../../utils/pinata'

/**
 * Get generated fee of a phase.
 * Based on the price per lot and the number of released lots.
 */
export const getGeneratedFee = (
  earned: BigNumber,
  rollFee: BigNumber,
  referralFee: BigNumber,
): BigNumber => {
  const rollEarned = getFee(earned, rollFee)
  const referralEarned = getFee(earned, referralFee)
  return earned.sub(rollEarned.add(referralEarned))
}

/**
 * Given a list of phases return the sum of all generated fees
 */
export const getTotalGeneratedFee = (phases: Phase[]): BigNumber => {
  const generatedFees = phases.map((phase) => {
    const earned = phase.pricePerLot.mul(phase.released)
    return getGeneratedFee(earned, phase.rollFee, BigNumber.from(0))
  })
  return generatedFees.reduce(
    (total, curr) => total.add(curr),
    BigNumber.from(0),
  )
}

/**
 * Use to get the statusp of a Phase
 */
export const getStatusPhase = (phase: Phase): StatusPhase => {
  // 1. Check if phase is sold out
  if (phase.amountTotal === phase.released) return StatusPhase.soldOut
  // 2. Check if start date is past
  if (isPast(phase.start * 1000)) {
    // 2.1 Check if start date + duration is past
    if (isPast((phase.start + phase.duration) * 1000)) {
      // If true it means the phase is already done.
      return StatusPhase.done
    }
    // if not it means is still active
    return StatusPhase.active
  }
  // 3. If start date is not done it means is still locked.
  return StatusPhase.locked
}

/**
 * Get the default phase based on this createria:
 * - Public phase, everyone can mint, default should be public phase selected, tiers not selected, counter shows 1, then people can select tiers to mint
 * - Allowlist phase, not on the allowlist, we can show "claim now" button greyed out and a red alert "You are not on the allowlist, check back during public phase"
 * - You are both on the allowlist and public and there's overlap, show the cheaper option
 * - You are both on the allowlist and public, show the active phase
 */
export const getDefaultPhase = ({
  phases,
  leaves,
  userAddress,
}: {
  phases: Phase[]
  leaves: MerkleTrees | null
  userAddress: string
}): Phase | undefined => {
  const activePhases: Phase[] = phases.filter(
    (phase) => getStatusPhase(phase) === StatusPhase.active,
  )
  if (!activePhases.length) return
  let defaultPhase: Phase | undefined
  activePhases.forEach((phase) => {
    const isPublic = checkIsPhasePublic(phase.merkleRoot)
    let isVerify = false
    if (!isPublic && leaves) {
      const validation = allowlistValidation({
        leaves,
        root: phase.merkleRoot,
        userAddress,
      })
      isVerify = !!validation?.isVerify
    }
    if (defaultPhase && !phase.pricePerLot.lte(defaultPhase.pricePerLot)) return
    if (!isPublic && !isVerify) return
    defaultPhase = phase
  })
  return defaultPhase || activePhases[0]
}

/**
 * Given a phase returns the time label based on the status
 */
export const getTimeLabel = (phase: Phase | null): TimeLabel => {
  let timeLabel = TimeLabel.ended
  if (!phase) return timeLabel
  const status = getStatusPhase(phase)
  if (status === StatusPhase.active) {
    timeLabel = TimeLabel.ends
  } else if (status === StatusPhase.locked) {
    timeLabel = TimeLabel.starts
  } else if (status === StatusPhase.soldOut) {
    timeLabel = TimeLabel.soldOut
  }
  return timeLabel
}

/**
 * Given a phase returns the time where it will start or end based on the status.
 */
export const getPhaseTime = (phase: Phase | null): number => {
  if (!phase) return 0
  const status = getStatusPhase(phase)
  if (status === StatusPhase.active)
    return (phase.start + phase.duration) * 1000
  if (status === StatusPhase.locked) return phase.start * 1000
  return 0
}

/**
 * Format the metadata in a phase.
 */
export const formatMetadata = (metadata: string) => {
  try {
    const jsonData = JSON.parse(metadata) as Partial<Metadadata>
    return jsonData
  } catch (error) {
    return null
  }
}

/**
 * Given a merkle root return if the phase is public or not
 */
export const checkIsPhasePublic = (merkleRoot: string) => {
  if (merkleRoot === formatBytes32String('')) return true
  return false
}

export const getLeaves = async (pinataHash: string) => {
  const phasesLeaves = await getPinFromPinata(pinataHash)
  return phasesLeaves
}

export const allowlistValidation = ({
  leaves,
  root,
  userAddress,
}: {
  leaves: MerkleTrees
  root: string
  userAddress: string
}): { proof: string[]; isVerify: boolean } | undefined => {
  const phaseLeaves = leaves[root]
  if (phaseLeaves?.length) {
    const tree = createMerkleTree(phaseLeaves)
    const proof = getProof(tree, userAddress)
    const leaf = ethers.utils.keccak256(userAddress)
    const isVerify = tree.verify(proof, leaf, root)
    return {
      proof,
      isVerify,
    }
  }
}

/**
 * Format phase from contract to use in app.
 */
export const formatPhase = (val: ContractPhase, phaseId: string): Phase => {
  return {
    phaseId,
    initialized: val.initialized,
    revoked: val.revoked,
    owner: val.owner,
    start: val.start.toNumber(),
    duration: val.duration.toNumber(),
    merkleRoot: val.merkleRoot,
    amountTotal: val.amountTotal.toNumber(),
    released: val.released.toNumber(),
    lotToken: val.lotToken,
    lotSize: val.lotSize,
    paymentAsset: ((asset: ContractAssetStruct): AssetStruct => ({
      assetType: asset.assetType,
      token: asset.token,
    }))(val.paymentAsset),
    pricePerLot: val.pricePerLot,
    rollFee: val.rollFee,
    maxBuyPerWallet: val.maxBuyPerWallet.toNumber(),
  }
}
