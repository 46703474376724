import { ToastProps } from '@tryrolljs/design-system'
import { useCallback } from 'react'
import { toastError, toastInfo, toastSuccess } from '../../molecules/toasts'
import { EtherscanType, useEtherscanLink } from '../utils'

export const useTransactionNotifications = ({
  successToast,
  submitToast = {
    title: 'Transaction in progress',
    description: 'Check on-chain details',
  },
  errorToast,
}: {
  successToast: ToastProps
  submitToast?: ToastProps
  errorToast?: ToastProps
}) => {
  const getEtherscanLink = useEtherscanLink()

  const onSubmit = useCallback(
    (hash: string) => {
      toastInfo({
        ...submitToast,
        action: {
          title: 'View on etherscan',
          onPress: () =>
            window.open(getEtherscanLink(hash, EtherscanType.tx), '_blank'),
        },
      })
    },
    [submitToast, getEtherscanLink],
  )

  const onSuccess = useCallback(
    (message?: string) => {
      toastSuccess({
        ...successToast,
        description: message || successToast.description,
      })
    },
    [successToast],
  )

  const onError = useCallback(
    (error: string) => {
      toastError({
        ...errorToast,
        description: error,
      })
    },
    [errorToast],
  )

  return { onSubmit, onSuccess, onError }
}

export const useBuyNotifications = () => {
  return useTransactionNotifications({
    successToast: {
      description: "Congratulations you've successfully claimed",
    },
  })
}

export const useClaimNotifications = () => {
  return useTransactionNotifications({
    successToast: {
      description: "Congratulations you've successfully claimed",
    },
  })
}

export const useClaimUnsoldTokensNotifications = () => {
  return useTransactionNotifications({
    successToast: {
      description: "Congratulations you've successfully claimed unsold tokens",
    },
  })
}

export const useApproveTokenNotifications = () => {
  return useTransactionNotifications({
    successToast: {
      description: 'Successfully approved',
    },
  })
}

export const useCreateNotifications = () => {
  return useTransactionNotifications({
    successToast: {
      description: "Congratulations! You've launched your membership",
    },
  })
}

export const useUpdateMetadataNotifications = () => {
  return useTransactionNotifications({
    successToast: {
      description: 'Now you can save changes!',
    },
  })
}

export const useUpdateMerkleRootNotifications = () => {
  return useTransactionNotifications({
    successToast: {
      description: 'Allowlist was updated!',
    },
  })
}
