import React, { useEffect, useMemo } from 'react'
import { Caption, useTheme } from '@tryrolljs/design-system'
import useCountDown from 'react-countdown-hook'
import { differenceInMilliseconds, getTime, intervalToDuration } from 'date-fns'
import { millisecondsToDays } from '../../utils/dates'

export const TimeBox: React.FC<{ time: string; suffix: string }> = ({
  time,
  suffix,
}) => {
  const theme = useTheme()
  return (
    <div
      className="flex justify-center items-center p-1 rounded-lg"
      style={{ backgroundColor: theme.background.tertiary }}
    >
      <Caption weight="bold">
        {time} {suffix}
      </Caption>
    </div>
  )
}

interface TimeCounterProps {
  time: number
  label: string
  showTime?: boolean
}

export const TimeCounter: React.FC<TimeCounterProps> = ({
  label,
  showTime = true,
  time,
}) => {
  const theme = useTheme()
  const [timeLeft, { start }] = useCountDown(
    differenceInMilliseconds(time, getTime(new Date())),
    1000,
  )

  useEffect(() => {
    if (showTime) {
      start()
    }
  }, [start, showTime])

  const { seconds, minutes, days, hours } = useMemo(() => {
    if (!timeLeft) {
      return {
        seconds: 0,
        minutes: 0,
        days: 0,
        hours: 0,
      }
    }
    const interval = intervalToDuration({
      start: new Date().valueOf(),
      end: time,
    })
    const intervalDays = millisecondsToDays(timeLeft)
    return { ...interval, days: intervalDays }
  }, [timeLeft, time])

  return (
    <div className="flex items-center gap-3">
      <Caption weight="bold" color={theme.text.highlight}>
        {label}
      </Caption>
      {showTime && (
        <div className="flex items-center gap-2">
          <TimeBox time={String(days ?? 0).padStart(2, '0')} suffix="D" />
          <TimeBox time={String(hours ?? 0).padStart(2, '0')} suffix="H" />
          <TimeBox time={String(minutes ?? 0).padStart(2, '0')} suffix="M" />
          <TimeBox time={String(seconds ?? 0).padStart(2, '0')} suffix="S" />
        </div>
      )}
    </div>
  )
}
