import { Tooltip } from '@tryrolljs/design-system'
import React from 'react'
import MoreInfoIcon from '../../assets/svg/more-info-icon.svg'

interface InfoTooltipProps {
  title: string
}

export const InfoTooltip: React.FC<InfoTooltipProps> = ({ title }) => {
  return (
    <Tooltip variant="dark" title={title}>
      <div className="w-4 cursor-pointer">
        <MoreInfoIcon />
      </div>
    </Tooltip>
  )
}
