import React, { useMemo } from 'react'
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { Body, Button, useModal, useTheme } from '@tryrolljs/design-system'
import clsx from 'clsx'
import {
  useCompleteStep,
  useFormValuesState,
  useUpdateFormState,
} from '../../hooks/selectors/create'
import {
  CreateStepHeading,
  CreateStepHeadingProps,
} from '../../molecules/createStepHeading'
import { InputContainer } from '../../molecules/inputContainer'
import { RemoveButtonIcon } from '../../molecules/removeButtonIcon'
import { TokenSelect } from '../../molecules/tokenSelect'
import { CreateFormInputState } from '../../types'
import { CreateStepsId } from '../../types/create'
import { InfoTooltip } from '../../molecules/infoTooltip'
import AddIcon from '../../assets/svg/add.svg'
import { getDecimalLength } from '../../utils'
import { useFetchToken } from '../../hooks/tokens'
import { ConfirmationDeleteModal } from '../../molecules/confirmationDeleteModal'

const dataInfo: CreateStepHeadingProps = {
  title: 'Lots',
  description:
    'Lots group your tokens into equal amounts that users can claim.\nChoose the amount of tokens you want to use in each lot below.',
}

export const CreateLotsStep: React.FC = () => {
  const completeStep = useCompleteStep()
  const defaultValues = useFormValuesState()
  const { updateLots } = useUpdateFormState()
  const theme = useTheme()
  const methods = useForm<CreateFormInputState>({
    defaultValues,
    mode: 'onChange',
  })

  const { fields, append, remove } = useFieldArray({
    name: 'lotInfo',
    control: methods.control,
  })

  const onSubmit = () => {
    methods.handleSubmit((data) => {
      updateLots(data.lotInfo)
      completeStep(CreateStepsId.lots)
    })()
  }

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col">
        <div className="mb-8">
          <CreateStepHeading
            title={dataInfo.title}
            description={dataInfo.description}
            tooltip="Think of lots like membership tiers for Web3. Membership tiers can be represented by the number of lots a user needs to claim to reach that level."
          />
        </div>
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <Body weight="bold" color={theme.text.primary}>
                Amount of tokens in each lot
              </Body>
              <InfoTooltip title="When users claim lots, they will be claiming the amount of tokens you choose here" />
            </div>
            <div className="flex flex-col max-w-xs gap-4">
              {fields.map((field, i) => (
                <SelectTokenInput
                  key={field.id}
                  i={i}
                  remove={() => remove(i)}
                />
              ))}
            </div>
          </div>
          <div className="w-fit">
            <Button
              variant="secondary"
              onPress={() =>
                append({
                  lotSize: '0',
                  lotToken: '',
                })
              }
            >
              <div className="flex items-center gap-2">
                <AddIcon />
                <Body weight="regular" color={theme.text.primary}>
                  Add a token
                </Body>
              </div>
            </Button>
          </div>
        </div>
        <div className="w-fit mt-8">
          <Button
            variant="primary"
            disabled={!fields.length}
            title="Continue"
            onPress={onSubmit}
          />
        </div>
      </div>
    </FormProvider>
  )
}

export const SelectTokenInput: React.FC<{
  i: number
  remove: () => void
}> = ({ i, remove }) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<CreateFormInputState>()
  const { isOpen, close, open } = useModal()
  const lotErrors = errors.lotInfo?.[i]
  const tokensValue = useWatch({
    name: 'lotInfo',
    control,
  })
  const { token: tokenData } = useFetchToken(tokensValue[i].lotToken)
  const disabledTokens = useMemo(() => {
    return tokensValue.map((token) => token.lotToken)
  }, [tokensValue])
  return (
    <>
      <div className="flex gap-3 items-start">
        <InputContainer
          className={{ inputContainer: 'flex justify-between' }}
          error={lotErrors?.lotSize?.message || lotErrors?.lotToken?.message}
        >
          <input
            type="number"
            className={clsx(
              'appearance-none w-1/2',
              !tokenData && 'cursor-not-allowed bg-white',
            )}
            disabled={!tokenData}
            {...register(`lotInfo.${i}.lotSize`, {
              validate: {
                positive: (value) =>
                  (value && Number(value) > 0) ||
                  'Value most be greater than 0',
                lengthDecimals: (value) =>
                  getDecimalLength(value) <= (tokenData?.decimals || 0) ||
                  `Value can have a max of ${
                    tokenData?.decimals || 0
                  } decimal units`,
              },
              required: {
                value: true,
                message: 'This field is required',
              },
            })}
          />
          <Controller
            name={`lotInfo.${i}.lotToken`}
            control={control}
            rules={{
              required: {
                value: true,
                message: 'A token has to be selected',
              },
            }}
            render={({ field: { value, onChange } }) => (
              <TokenSelect
                value={value}
                onSelect={(token) => onChange(token.address)}
                disabledTokens={disabledTokens}
              />
            )}
          />
        </InputContainer>
        <div className="mt-4">
          <RemoveButtonIcon onPress={open} />
        </div>
      </div>
      <ConfirmationDeleteModal
        title="Remove token"
        description="Are you sure you want to remove this token?"
        onConfirm={remove}
        closeModal={close}
        isOpen={isOpen}
      />
    </>
  )
}
