import { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Caption } from '@tryrolljs/design-system'
import { checkFormInputState } from '../../core/create'
import {
  useActiveCreateStepSelector,
  useCreateSteps,
  useGoToReview,
  useResetCreateState,
  useStepsLength,
} from '../../hooks/selectors/create'
import { toastError, toastSuccess } from '../../molecules/toasts'
import { CreateFeesStep } from '../../organisms/createFeesStep'
import { CreateLaunchStep } from '../../organisms/createLaunchStep'
import { CreateLotsStep } from '../../organisms/createLotsStep'
import { CreateNavSidebar } from '../../organisms/createNavSidebar'
import { CreatePhaseStep } from '../../organisms/createPhaseStep'
import { CreateTierStep } from '../../organisms/createTierStep'
import { WithWalletContainer } from '../../organisms/withWalletContainer'
import { PageTemplate } from '../../templates/page'
import { CreateStepsId } from '../../types/create'
import { useFetchMinRollFee } from '../../hooks/campaigns'

export const CreatePage = () => {
  const { activeIndex, activeStep } = useActiveCreateStepSelector()
  const [searchParams] = useSearchParams()
  const reviewParams = useMemo(() => searchParams.get('review'), [searchParams])
  const stepsLength = useStepsLength()
  const steps = useCreateSteps()
  const goToReview = useGoToReview()
  const fetchMinRollFee = useFetchMinRollFee()
  const resetCreateState = useResetCreateState()

  useEffect(() => {
    return () => {
      resetCreateState()
    }
  }, [resetCreateState])

  useEffect(() => {
    if (reviewParams) {
      try {
        const value = JSON.parse(reviewParams)
        if (checkFormInputState(value)) {
          goToReview(value)
          toastSuccess({
            description: 'Getting values for review',
          })
        } else {
          toastError({
            description: 'Error parsing review values',
          })
        }
      } catch (error) {
        toastError({
          description: 'Error getting values for review',
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewParams])

  useEffect(() => {
    fetchMinRollFee()
  }, [fetchMinRollFee])

  return (
    <PageTemplate isFullWidth withFooter={false}>
      <WithWalletContainer>
        <div
          className="h-full grid"
          // eslint-disable-next-line react-native/no-inline-styles
          style={{ gridTemplateColumns: '360px 1fr' }}
        >
          <CreateNavSidebar title="Create Membership" steps={steps} />
          <div className="py-10 px-48">
            <Caption
              weight="bold"
              color="#CCC" // TODO: Add this color to roll-ui theme
            >
              Step {activeIndex + 1} of {stepsLength}
            </Caption>
            {activeStep.id === CreateStepsId.lots && <CreateLotsStep />}
            {activeStep.id === CreateStepsId.tiers && <CreateTierStep />}
            {activeStep.id === CreateStepsId.phases && <CreatePhaseStep />}
            {activeStep.id === CreateStepsId.fees && <CreateFeesStep />}
            {activeStep.id === CreateStepsId.launch && <CreateLaunchStep />}
          </div>
        </div>
      </WithWalletContainer>
    </PageTemplate>
  )
}
