import {
  CHAIN_ID_GOERLI,
  CHAIN_ID_MAIN_NET,
  CHAIN_ID_MUMBAI,
  CHAIN_ID_POLYGON,
} from '@tryrolljs/design-system'
import { CHAIN_ID_FORM_TEST_NET } from '../contracts/constants'

const ETHERSCAN_DATA: Record<number, { domain: string; prefix?: string }> = {
  [CHAIN_ID_MAIN_NET]: {
    domain: 'etherscan.io',
  },
  [CHAIN_ID_GOERLI]: {
    domain: 'etherscan.io',
    prefix: 'goerli.',
  },
  [CHAIN_ID_POLYGON]: {
    domain: 'polygonscan.com',
  },
  [CHAIN_ID_MUMBAI]: {
    domain: 'polygonscan.com',
    prefix: 'mumbai.',
  },
  [CHAIN_ID_FORM_TEST_NET]: {
    domain: 'form.network',
    prefix: 'testnet-explorer.',
  },
}

export const getEtherscanLink = ({
  chainId,
  address,
  type,
}: {
  chainId: number
  address: string
  type: 'address' | 'token' | 'tx'
}) => {
  const { prefix = '', domain } =
    ETHERSCAN_DATA[chainId] || ETHERSCAN_DATA[CHAIN_ID_MAIN_NET]
  return `https://${prefix}${domain}/${type}/${address}`
}
