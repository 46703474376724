import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { Campaign } from "../../types";

interface CampaignState extends EntityState<Campaign> {
  // The total length of campaigns saved in the contract.
  campaignLength: number | null,
}

export const campaignAdapter = createEntityAdapter<Campaign>({
  selectId: (campaign) => campaign.campaignId
});

const initialState: CampaignState = campaignAdapter.getInitialState({
  campaignLength: null
});

const saveCampaign = (state: CampaignState, action: PayloadAction<Campaign>) => {
  campaignAdapter.upsertOne(state, action.payload);
};

const saveCampaigns = (state: CampaignState, action: PayloadAction<Campaign[]>) => {
  campaignAdapter.upsertMany(state, action.payload);
};

export const campaignSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    updateCampaingLength: (state, action: PayloadAction<number>) => {
      state.campaignLength = action.payload;
    },
    actionSaveCampaign: saveCampaign,
    actionSaveCampaigns: saveCampaigns
  }
});

export const {
  selectAll: selectAllCampaigns,
  selectById: selectCampaignByAddress,
  selectEntities: selectCampaigns
} = campaignAdapter.getSelectors((state: RootState) => state.campaigns);

export const {
  actionSaveCampaign,
  actionSaveCampaigns,
  updateCampaingLength
} = campaignSlice.actions;

export default campaignSlice.reducer;
