import { BigNumber, ethers } from 'ethers'
import { useMemo } from 'react'
import { CreateFormInputLots, CreateFormInputSchdules } from '../../types'
import { getDecimalLength } from '../../utils'
import {
  useAllTokensHasBalance,
  useFetchToken,
  useFetchTokens,
} from '../tokens'

export const useGetGeneratedFee = ({
  paymentAddress,
  schedules,
}: {
  paymentAddress: string
  schedules: CreateFormInputSchdules[]
}) => {
  const { token: paymentToken } = useFetchToken(paymentAddress)
  return useMemo(() => {
    if (!paymentToken) return BigNumber.from(0)
    return schedules.reduce((accum, curr) => {
      const amountTotal =
        curr.amountTotal && curr.amountTotal % 1 === 0 ? curr.amountTotal : 0
      const priceDecimalsLength = getDecimalLength(curr.pricePerLot)
      const wei =
        priceDecimalsLength <= paymentToken.decimals && curr.pricePerLot
          ? ethers.utils.parseUnits(curr.pricePerLot, paymentToken.decimals)
          : BigNumber.from(0)
      return accum.add(wei.mul(amountTotal))
    }, BigNumber.from(0))
  }, [schedules, paymentToken])
}

export const useGetTotalRewardPerToken = ({
  schedules,
  lotTokens,
}: {
  schedules: CreateFormInputSchdules[]
  lotTokens: CreateFormInputLots[]
}) => {
  const { tokens } = useFetchTokens(lotTokens.map((lot) => lot.lotToken))

  const amountTotal = schedules.reduce((accum, curr) => {
    const total =
      !curr.amountTotal || curr.amountTotal % 1 !== 0 ? 0 : curr.amountTotal
    return accum + total
  }, 0)

  return lotTokens.map((lot) => {
    if (amountTotal % 1 !== 0)
      return { address: lot.lotToken, total: BigNumber.from(0) }
    const lotToken = tokens.find((token) => token.address === lot.lotToken)
    const total = lotToken
      ? ethers.utils.parseUnits(lot.lotSize, lotToken.decimals).mul(amountTotal)
      : BigNumber.from(0)
    return { address: lot.lotToken, total: total }
  })
}

export const useAllTokensHasBalanceFromForm = ({
  schedules,
  lotTokens,
}: {
  schedules: CreateFormInputSchdules[]
  lotTokens: CreateFormInputLots[]
}) => {
  const rewardPerToken = useGetTotalRewardPerToken({ lotTokens, schedules })
  const allTokensHasBalance = useAllTokensHasBalance({
    tokensIds: rewardPerToken.map((reward) => reward.address),
    balancesRequire: rewardPerToken.reduce(
      (acc, curr) => ({ ...acc, [curr.address]: curr.total }),
      {} as Record<string, BigNumber>,
    ),
  })
  return allTokensHasBalance
}
