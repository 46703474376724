import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  CreateFormInputFees,
  CreateFormInputLots,
  CreateFormInputMetadata,
  CreateFormInputSchdules,
  CreateFormInputState,
} from '../../types'
import {
  CreateStepData,
  CreateStepsId,
  CreateStepStatus,
} from '../../types/create'

interface CreateState {
  steps: CreateStepData[]
  reviewSteps: CreateStepData[]
  formState: CreateFormInputState
  minRollFee: number
}

const initialSteps: CreateStepData[] = [
  {
    id: CreateStepsId.lots,
    title: 'Lots of Tokens',
    status: CreateStepStatus.active,
  },
  {
    id: CreateStepsId.tiers,
    title: 'Descriptions and Tiers',
    status: CreateStepStatus.todo,
  },
  {
    id: CreateStepsId.phases,
    title: 'Phases',
    status: CreateStepStatus.todo,
  },
  {
    id: CreateStepsId.fees,
    title: 'Fees',
    status: CreateStepStatus.todo,
  },
  {
    id: CreateStepsId.launch,
    title: 'Launch',
    status: CreateStepStatus.todo,
  },
]

const initialReviewSteps: CreateStepData[] = [
  {
    id: CreateStepsId.lots,
    title: 'Lots and Tokens',
    status: CreateStepStatus.active,
  },
  {
    id: CreateStepsId.tiers,
    title: 'Description and Tiers',
    status: CreateStepStatus.todo,
  },
  {
    id: CreateStepsId.phases,
    title: 'Phases and Fees',
    status: CreateStepStatus.todo,
  },
  {
    id: CreateStepsId.launch,
    title: 'Launch Membership',
    status: CreateStepStatus.todo,
  },
]

const initialState: CreateState = {
  steps: initialSteps,
  reviewSteps: initialReviewSteps,
  minRollFee: 0,
  formState: {
    metadata: {
      website: '',
      description: '',
      tiers: [],
    },
    lotInfo: [
      {
        lotSize: '0',
        lotToken: '',
      },
    ],
    schedules: [
      {
        amountTotal: 0,
        pricePerLot: '0',
        hasMaxBuyPerWallet: false,
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
      },
    ],
    paymentAsset: '',
    fees: {
      referral: '',
      referralFee: 0,
      rollFee: 0,
    },
  },
}

export const createStateSlice = createSlice({
  name: 'create',
  initialState,
  reducers: {
    updateActivePreview: (state, action: PayloadAction<CreateStepsId>) => {
      const currentIndex = state.reviewSteps.findIndex(
        (step) => step.id === action.payload,
      )
      if (currentIndex >= 0 && currentIndex < state.reviewSteps.length) {
        state.reviewSteps[currentIndex].status = CreateStepStatus.done
        state.reviewSteps[currentIndex + 1].status = CreateStepStatus.active
      }
    },
    backToStep: (state, action: PayloadAction<{ stepId: CreateStepsId }>) => {
      const { stepId } = action.payload
      const stepIndex = state.reviewSteps.findIndex(
        (step) => step.id === stepId,
      )
      state.reviewSteps.forEach((step, i) => {
        if (step.id === stepId) {
          step.status = CreateStepStatus.active
        }
        if (i > stepIndex) {
          step.status = CreateStepStatus.todo
        }
      })
    },
    completeStep: (state, action: PayloadAction<CreateStepsId>) => {
      const currentStepIndex = state.steps.findIndex(
        (step) => step.id === action.payload,
      )
      if (currentStepIndex > -1 && currentStepIndex < state.steps.length - 1) {
        state.steps[currentStepIndex].status = CreateStepStatus.done
        state.steps[currentStepIndex + 1].status = CreateStepStatus.active
      }
    },
    goToStep: (
      state,
      action: PayloadAction<{ go: CreateStepsId; current: CreateStepsId }>,
    ) => {
      const newStep = state.steps.find((step) => step.id === action.payload.go)
      const currentStep = state.steps.find(
        (step) => step.id === action.payload.current,
      )
      if (newStep) {
        newStep.status = CreateStepStatus.active
      }
      if (currentStep) {
        currentStep.status = CreateStepStatus.todo
      }
    },
    goToReview: (
      state,
      action: PayloadAction<{ form: CreateFormInputState }>,
    ) => {
      state.formState = action.payload.form
      state.steps.forEach((step) => {
        if (step.id === CreateStepsId.launch) {
          step.status = CreateStepStatus.active
        } else {
          step.status = CreateStepStatus.done
        }
      })
    },
    updateFormMetadata: (
      state,
      action: PayloadAction<CreateFormInputMetadata>,
    ) => {
      state.formState.metadata = action.payload
    },
    updateFormLots: (state, action: PayloadAction<CreateFormInputLots[]>) => {
      state.formState.lotInfo = action.payload
    },
    updateFormSchedules: (
      state,
      action: PayloadAction<CreateFormInputSchdules[]>,
    ) => {
      state.formState.schedules = action.payload
    },
    updateScheduleMerkle: (
      state,
      action: PayloadAction<{ root: string; list: string }>,
    ) => {
      const { list, root } = action.payload
      const schedules = state.formState.schedules.filter(
        (e) => e.allowlist?.list === list,
      )
      schedules.forEach((schedule) => {
        schedule.merkleRoot = root
      })
    },
    updateFormPaymentAsset: (state, action: PayloadAction<string>) => {
      state.formState.paymentAsset = action.payload
    },
    updateFormFees: (state, action: PayloadAction<CreateFormInputFees>) => {
      state.formState.fees = action.payload
    },
    setMinRollFee: (state, action: PayloadAction<number>) => {
      state.minRollFee = action.payload
    },
    resetCreateState: () => initialState,
    resetReviewSteps: (state) => {
      state.reviewSteps = initialState.reviewSteps
    },
  },
})

export const selectActiveStep = createSelector(
  (state: CreateState) => state.steps,
  (steps) => {
    let i = steps.findIndex((step) => step.status === CreateStepStatus.active)
    if (i < 0) {
      i = 0
    }
    return {
      activeStep: steps[i],
      activeIndex: i,
    }
  },
)

export const selectActiveReviewStep = createSelector(
  (state: CreateState) => state.reviewSteps,
  (steps) => {
    let i = steps.findIndex((step) => step.status === CreateStepStatus.active)
    if (i < 0) {
      i = 0
    }
    return {
      activeStep: steps[i],
      activeIndex: i,
    }
  },
)

export const selectStepsLength = (state: CreateState) => state.steps.length

export const {
  completeStep,
  goToStep,
  updateActivePreview,
  updateFormFees,
  updateFormLots,
  updateFormMetadata,
  updateFormSchedules,
  updateFormPaymentAsset,
  resetCreateState,
  resetReviewSteps,
  setMinRollFee,
  goToReview,
  backToStep,
  updateScheduleMerkle,
} = createStateSlice.actions

export default createStateSlice.reducer
