import { PINATA_URL } from '../constants'
import { getErrorMessage } from '../contracts/errors'
import { toastError } from '../molecules/toasts'
import { MerkleTrees } from '../types'

interface IPFSJSON {
  merkleTrees: MerkleTrees
}

export const pinJSONToIPFS = async (JSONBody: IPFSJSON) => {
  try {
    const url = 'https://api.pinata.cloud/pinning/pinJSONToIPFS'

    const responseStream = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(JSONBody),
      cache: 'no-cache',
      headers: {
        'Max-Content-Length': 'Infinity',
        'Content-Type': 'application/json',
        pinata_api_key: '8774f3ba9e082ae0734e',
        pinata_secret_api_key:
          'b4687675f38f86b8ad0b9f945710f234498d1e2a21ec8b97a45d2c13ca5d0227',
      },
    })

    const json = await responseStream.json()
    const ipfsHash = json.IpfsHash
    return String(ipfsHash)
  } catch (error) {
    toastError({ description: getErrorMessage(error) })
  }
}

export const getPinataURL = (hash: string) => `${PINATA_URL}/${hash}`

export const getPinFromPinata = async (hash: string) => {
  const response = await fetch(getPinataURL(hash))
  const data = (await response.json()) as
    | { merkleTrees: MerkleTrees }
    | undefined
  return data?.merkleTrees
}
