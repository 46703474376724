import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Caption,
  CircleImg,
  SubHeader,
  useTheme,
  Body,
  Modal,
  padding,
} from '@tryrolljs/design-system'
import { FixedSizeList as List } from 'react-window'
import clsx from 'clsx'
import { ethers } from 'ethers'
import { useFetchToken, useListTokens } from '../../hooks/tokens'
import CloseXIcon from '../../assets/svg/close-x.svg'
import { Token } from '../../types'
import { InputContainer } from '../../molecules/inputContainer'
import { useDebounce } from '../../hooks/utils'

export interface SelectTokenModalProps {
  cb: (token: Token) => void
  isPaymentToken?: boolean
  disabledTokens?: string[]
  onCloseModal: () => void
  isOpen: boolean
}

const MAX_SUGGESTED_TOKEN_COUNT = 4

export const SelectTokenModal: React.FC<SelectTokenModalProps> = ({
  cb,
  isPaymentToken = false,
  disabledTokens = [],
  onCloseModal,
  isOpen,
}) => {
  const theme = useTheme()
  const { tokens, isLoading } = useListTokens(isPaymentToken)
  const { isLoading: isLoadingToken, managedExec: fetchToken } = useFetchToken(
    '',
    true,
  )
  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce(searchValue, 1500)

  const filteredList = useMemo(() => {
    return tokens.filter(
      (token) =>
        token.symbol
          .toLowerCase()
          .includes(debouncedSearchValue.toLocaleLowerCase()) ||
        token.name.toLowerCase().includes(debouncedSearchValue.toLowerCase()) ||
        token.address
          .toLowerCase()
          .includes(debouncedSearchValue.toLowerCase()),
    )
  }, [tokens, debouncedSearchValue])

  const shouldShowSuggestedTokens =
    !isLoading && !filteredList.length && !!tokens.length

  const shouldShowFilteredTokens = !isLoading && !!filteredList.length

  const handleTokenClick = useCallback(
    (token: Token) => {
      if (!disabledTokens.includes(token.address)) {
        cb(token)
        onCloseModal()
      }
    },
    [onCloseModal, cb, disabledTokens],
  )

  useEffect(() => {
    if (ethers.utils.isAddress(debouncedSearchValue) && !filteredList.length) {
      fetchToken(debouncedSearchValue)
    }
  }, [filteredList, debouncedSearchValue, fetchToken])

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal}>
      <Modal.Content style={[padding.p24]}>
        <div>
          <div className="flex justify-between items-center w-full mb-4">
            <SubHeader weight="bold" color={theme.text.primary}>
              Select a token
            </SubHeader>
            <div
              className="w-6 text-black cursor-pointer"
              onClick={onCloseModal}
            >
              <CloseXIcon />
            </div>
          </div>
          {!isPaymentToken && (
            <>
              <InputContainer>
                <input
                  type="text"
                  className="appearance-none w-full"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder="Search name or paste address"
                />
              </InputContainer>
              <hr className="w-auto mt-6 mb-4 -mx-6 border-[#EAEEF3]" />
            </>
          )}
        </div>
        {!filteredList.length && (
          <div className="w-full flex justify-center pt-2 pb-4 items-center">
            <Body color={theme.text.secondary}>
              {isLoading && 'Loading tokens...'}
              {isLoadingToken && !isLoading
                ? 'Loading token...'
                : 'No results found.'}
            </Body>
          </div>
        )}
        {/* Show filtered tokens. */}
        {shouldShowFilteredTokens && (
          <List
            itemData={filteredList}
            itemKey={(index, data) => data[index].address}
            itemCount={filteredList.length}
            itemSize={44}
            width={332}
            height={400}
          >
            {({ index, style }) => {
              const token = filteredList[index]
              return (
                <div
                  style={style}
                  onClick={() => handleTokenClick(token)}
                  className={clsx(
                    'my-[6px]',
                    disabledTokens.includes(token.address)
                      ? 'cursor-not-allowed'
                      : 'cursor-pointer',
                  )}
                >
                  <TokenElem
                    name={token.name}
                    image={token.logoURI}
                    symbol={token.symbol}
                  />
                </div>
              )
            }}
          </List>
        )}
        {shouldShowSuggestedTokens && (
          <div className="flex flex-col w-full gap-2 mb-6">
            <Body color={theme.text.primary}>Suggested</Body>
            <div>
              {tokens.slice(0, MAX_SUGGESTED_TOKEN_COUNT).map((token) => (
                <div
                  onClick={() => handleTokenClick(token)}
                  className={clsx(
                    'my-[6px]',
                    disabledTokens.includes(token.address)
                      ? 'cursor-not-allowed'
                      : 'cursor-pointer',
                  )}
                >
                  <TokenElem
                    name={token.name}
                    image={token.logoURI}
                    symbol={token.symbol}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </Modal.Content>
    </Modal>
  )
}

interface TokenElemProps {
  name: string
  image?: string
  symbol: string
  style?: React.CSSProperties
}

export const TokenElem: React.FC<TokenElemProps> = ({
  name,
  image,
  symbol,
  style,
}) => {
  const theme = useTheme()
  return (
    <li className="flex justify-between items-center" style={style}>
      <div className="flex items-center gap-4">
        <CircleImg size={32} uri={image} />
        <SubHeader weight="bold" color={theme.text.primary}>
          {symbol}
        </SubHeader>
      </div>
      <Caption weight="regular" color={theme.text.secondary}>
        {name}
      </Caption>
    </li>
  )
}
