import { useAppSelector } from "../..";
import { selectAllCampaigns, selectCampaignByAddress } from "../../../state/campaigns/reducer";

export const useCampaignByAddressSelector = (address: string) => {
  return useAppSelector(state => selectCampaignByAddress(state, address));
};

export const useCampaignsTotalLengthSelector = () => {
  return useAppSelector(state => state.campaigns.campaignLength);
};

export const useAllCampaignsSelector = () => {
  return useAppSelector(selectAllCampaigns);
};
