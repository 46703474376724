import { BigNumber, BigNumberish, BytesLike, Signer } from 'ethers'
import { Provider as EthersProviderType } from '@ethersproject/providers'

export type Provider = EthersProviderType | Signer

export enum NetworkStatus {
  'idle' = 'idle',
  'loading' = 'loading',
  'succeeded' = 'succeeded',
  'failed' = 'failed',
}

export enum StatusPhase {
  'active' = 'active',
  'locked' = 'locked',
  'soldOut' = 'souldOut',
  'done' = 'done',
}

export enum TimeLabel {
  'starts' = 'STARTS IN',
  'ends' = 'ENDS IN',
  'ended' = 'ENDED',
  'soldOut' = 'SOLD OUT',
}

export enum AllowanceSpenders {
  'memberships' = 'memberships',
  'membershipsImpl' = 'membershipsImpl',
}

export type TransactionCBs = {
  onSuccess: (message?: string) => void
  onError: (error: string) => void
  onSubmit: (hash: string) => void
}

export type Token = {
  address: string
  symbol: string
  name: string
  decimals: number
  logoURI: string
  color?: Array<string>
  totalSupply?: BigNumber
  userBalance?: BigNumber
  membershipImplAllowance?: BigNumber
  membershipAllowance?: BigNumber
  membershipsContractBalance?: BigNumber
}

export type ContractAssetStruct = [string, number] & {
  token: string
  assetType: number
}
export type AssetStruct = { token: string; assetType: number }

export type ContractPhase = [
  boolean,
  boolean,
  string,
  BigNumber,
  BigNumber,
  string,
  BigNumber,
  BigNumber,
  string[],
  BigNumber[],
  AssetStruct,
  BigNumber,
  BigNumber,
  BigNumber,
] & {
  initialized: boolean
  revoked: boolean
  owner: string
  start: BigNumber
  duration: BigNumber
  merkleRoot: string
  amountTotal: BigNumber
  released: BigNumber
  lotToken: string[]
  lotSize: BigNumber[]
  paymentAsset: ContractAssetStruct
  pricePerLot: BigNumber
  rollFee: BigNumber
  maxBuyPerWallet: BigNumber
}

export type contractCampaign = [string, string[], string] & {
  campaignId: string
  metadata: string
  phases: string[]
}

export interface Phase {
  phaseId: string
  initialized: boolean
  revoked: boolean
  owner: string
  start: number
  duration: number
  merkleRoot: string
  amountTotal: number
  released: number
  lotToken: string[]
  lotSize: BigNumber[]
  paymentAsset: AssetStruct
  pricePerLot: BigNumber
  rollFee: BigNumber
  maxBuyPerWallet: number
}

export type MerkleTrees = Record<string, string[]>
export interface Campaign {
  campaignId: string
  metadata: string
  phases: string[]
  phasesData?: Phase[]
}

export interface Tier {
  lots: number
  name: string
  description: string
}

export interface Metadadata {
  description: string
  website: string
  tiers: Partial<Tier>[]
  merkleTreesHash?: string
}

export interface BalanceEntity {
  id: string
  balance: number
}

export interface CreateMintingScheduleParamsStruct {
  start: BigNumberish
  duration: BigNumberish
  merkleRoot: BytesLike
  amountTotal: BigNumberish
  lotToken: string[]
  lotSize: BigNumberish[]
  pricePerLot: BigNumberish
  paymentAsset: {
    token: string
    assetType: BigNumberish
  }
  rollFee: BigNumberish
  referral: string
  referralFee: BigNumberish
  maxBuyPerWallet: BigNumberish
}

export interface CreateFormInputMetadataTier {
  name: string
  lots: number
  description: string
}

export interface CreateFormInputMetadata {
  website: string
  description: string
  tiers: CreateFormInputMetadataTier[]
  merkleTreesHash?: string
}

export interface CreateFormInputLots {
  lotToken: string
  lotSize: string
}

export interface AllowlistInput {
  isActive?: boolean
  list?: string
}
export interface CreateFormInputSchdules {
  startDate: string
  startTime: string
  endDate: string
  endTime: string
  pricePerLot: string
  hasMaxBuyPerWallet: boolean
  maxBuyPerWallet?: number
  amountTotal: number
  allowlist?: AllowlistInput
  merkleRoot?: string
}

export interface CreateFormInputFees {
  referral: string
  referralFee: number
  rollFee: number
}
export interface CreateFormInputState {
  metadata: CreateFormInputMetadata
  lotInfo: CreateFormInputLots[]
  schedules: CreateFormInputSchdules[]
  fees: CreateFormInputFees
  paymentAsset: string
}

export interface ETHError {
  code: string
  error: {
    code: number
  }
  reason: string
}
