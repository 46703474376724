import { BigNumber, ethers } from 'ethers'
import React, { useMemo } from 'react'
import {
  CircleImg,
  FONT_SIZE_SUB_HEADER,
  TypographyBase,
  useTheme,
} from '@tryrolljs/design-system'
import { useFetchToken } from '../../hooks/tokens'
import { AmountTokenLoader } from '../loaders'
import { formatNumber } from '../../utils'
import { BalanceLabel, BalanceLabelVariant } from '../balanceLabel'

interface AmountTokenProps {
  amount?: string
  autoValue?: 'totalSupply' | 'contractBalance'
  bigTokenAmount?: BigNumber
  address: string
  fontWeight?: 'bold' | 'semiBold' | 'regular'
  fontSize?: number
  circleSize?: number
  color?: string
  balanceVariant?: BalanceLabelVariant
}

export const AmountToken: React.FC<AmountTokenProps> = ({
  amount,
  autoValue,
  address,
  color,
  bigTokenAmount,
  balanceVariant,
  fontWeight = 'semiBold',
  circleSize = 20,
  fontSize = FONT_SIZE_SUB_HEADER,
}) => {
  const theme = useTheme()
  const { token, isLoading } = useFetchToken(address)
  const formatAmount = useMemo(() => {
    if (autoValue === 'contractBalance' && token?.membershipsContractBalance) {
      return ethers.utils.formatUnits(
        token.membershipsContractBalance,
        token.decimals,
      )
    }
    if (autoValue === 'totalSupply' && token?.totalSupply) {
      return ethers.utils.formatUnits(token.totalSupply, token.decimals)
    }
    if (bigTokenAmount && token) {
      return ethers.utils.formatUnits(bigTokenAmount, token.decimals)
    }
    return amount
  }, [token, amount, autoValue, bigTokenAmount])

  return (
    <>
      {isLoading && <AmountTokenLoader />}
      {!isLoading && token && (
        <div className="flex gap-1 items-center">
          {!!formatAmount && (
            <div className="mr-1">
              <TypographyBase
                fontSize={fontSize}
                weight={fontWeight}
                color={color || theme.text.primary}
              >
                {formatNumber(formatAmount)}
              </TypographyBase>
            </div>
          )}
          <CircleImg size={circleSize} uri={token.logoURI} />
          <TypographyBase
            fontSize={fontSize}
            color={color || theme.text.primary}
            weight="regular"
          >
            {token.symbol}
          </TypographyBase>
          {balanceVariant && (
            <BalanceLabel
              wei={bigTokenAmount}
              token={token}
              variants={balanceVariant}
            />
          )}
        </div>
      )}
    </>
  )
}
