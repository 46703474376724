import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Body,
  useTheme,
  useEthAddress,
  useModal,
} from '@tryrolljs/design-system'
import {
  checkIsPhasePublic,
  formatMetadata,
  getStatusPhase,
} from '../../core/phases'
import { useWithAnyAsync } from '../../hooks'
import { useCampaignById } from '../../hooks/campaigns'
import { CreateStepHeading } from '../../molecules/createStepHeading'
import { TokenSelect } from '../../molecules/tokenSelect'
import { CreateNavSidebar } from '../../organisms/createNavSidebar'
import { WithWalletContainer } from '../../organisms/withWalletContainer'
import { PageTemplate } from '../../templates/page'
import { MerkleTrees, Phase, StatusPhase } from '../../types'
import { CreateStepsId, CreateStepStatus } from '../../types/create'
import { fullStartEndDatePhase } from '../../utils/dates'
import { getPinFromPinata } from '../../utils/pinata'
import { CollapsePhaseView } from '../../molecules/collapsePhaseView'
import { AllowlistModal } from '../../organisms/allowlistModal'

const dataInfo = {
  phases: {
    title: 'Phases',
    description:
      'Phases are the time periods that people can claim your lots and become members.\nChoose the amount of lots, the lot price and time period of each phase below.\nSelect Allowlist to curate who can become a member.',
  },
  fees: {
    title: 'Fees Generated',
    description:
      'The total amount of tokens you will use and the estimated total fees generated through Roll Memberships, calculated automatically.',
  },
}

export const EditPage: React.FC = () => {
  const params = useParams()
  const userAddress = useEthAddress()
  const theme = useTheme()
  const address = params.address as string
  const [merkletree, setMerkletree] = useState<MerkleTrees>()
  const { execute, isLoading: isLoadingMerkleTree } = useWithAnyAsync()
  const { campaign, isLoading: isLoadingCampaign } = useCampaignById({
    id: address,
  })
  const metadata = useMemo(
    () => formatMetadata(campaign?.metadata ?? ''),
    [campaign],
  )
  const isOwner = useMemo(() => {
    if (!campaign) return false
    if (!campaign.phasesData?.length) return false
    if (
      campaign.phasesData[0].owner.toLowerCase() === userAddress?.toLowerCase()
    )
      return true
  }, [campaign, userAddress])

  const getMerkletree = useCallback(async () => {
    if (!metadata?.merkleTreesHash) return
    const response = await execute(() =>
      getPinFromPinata(metadata.merkleTreesHash ?? ''),
    )
    if (!response) return
    setMerkletree(response)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadata, setMerkletree])

  useEffect(() => {
    getMerkletree()
  }, [metadata, getMerkletree])

  const isLoading = useMemo(() => {
    return isLoadingMerkleTree || isLoadingCampaign
  }, [isLoadingMerkleTree, isLoadingCampaign])

  return (
    <PageTemplate isFullWidth withFooter={false}>
      <WithWalletContainer>
        <div
          className="h-full grid"
          style={{ gridTemplateColumns: '360px 1fr' }}
        >
          <CreateNavSidebar
            title="Edit Membership"
            steps={[
              {
                id: CreateStepsId.phases,
                status: CreateStepStatus.active,
                title: 'Phases',
              },
            ]}
          />
          {isLoading && <p>Loading...</p>}
          {!isLoading && !campaign && (
            <p>{"There's no campaign with this address"}</p>
          )}
          {!isLoading && !isOwner && (
            <p>{'You are not the owner of this campaign'}</p>
          )}
          {campaign && isOwner && !isLoading && (
            <div className="py-10 px-48">
              <div className="flex flex-col gap-8">
                <CreateStepHeading
                  title={dataInfo.phases.title}
                  description={dataInfo.phases.description}
                />
                <div className="w-full relative flex flex-col gap-2">
                  <Body weight="bold" color={theme.text.primary}>
                    Token to use for fees
                  </Body>
                  <div className="w-fit">
                    <TokenSelect
                      value={campaign.phasesData?.[0].paymentAsset.token ?? ''}
                      isPaymentToken
                      isDisabled
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-4">
                  {campaign.phasesData?.map((phase, index) => (
                    <WizardCollapsePhaseView
                      key={phase.phaseId}
                      phase={phase}
                      index={index}
                      merkleList={merkletree?.[phase.merkleRoot]}
                      campaignId={campaign.campaignId}
                      allMerkletrees={merkletree}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </WithWalletContainer>
    </PageTemplate>
  )
}

interface WizardCoallapsePhaseViewProps {
  phase: Phase
  index: number
  merkleList: string[] | undefined
  campaignId: string
  allMerkletrees?: MerkleTrees
}

export const WizardCollapsePhaseView: React.FC<
  WizardCoallapsePhaseViewProps
> = ({ phase, index, merkleList, campaignId, allMerkletrees = {} }) => {
  const { isOpen, open, close } = useModal()

  const date = useMemo(() => {
    return fullStartEndDatePhase(phase.start, phase.duration)
  }, [phase])

  const statusPhase = useMemo(() => getStatusPhase(phase), [phase])

  const isEditActive = statusPhase === StatusPhase.locked

  const handleOpenModal = useCallback(() => {
    if (!isEditActive) return
    open()
  }, [open, isEditActive])

  const hasAllowlist =
    !!merkleList?.length && !checkIsPhasePublic(phase.merkleRoot)

  return (
    <>
      <CollapsePhaseView
        amountTotal={phase.amountTotal}
        date={date}
        tokenAddress={phase.paymentAsset.token}
        pricePerLot={phase.pricePerLot}
        hasAllowlist={hasAllowlist}
        index={index}
        isEditActive={isEditActive}
        onClickAllowList={handleOpenModal}
      />
      <AllowlistModal
        title={`Allowlist ${index + 1}`}
        isEditMode
        isOpen={isOpen}
        merkleList={merkleList}
        defaultValues={{
          isActive: !!merkleList?.length,
          list: merkleList?.join('\n'),
        }}
        editData={{
          phaseId: phase.phaseId,
          campaignId,
          allMerkletrees,
        }}
        onSave={() => null}
        closeModal={close}
      />
    </>
  )
}
