import {
  Header,
  LargeTitle,
  useTheme,
  Button,
  SubHeader,
  Body,
  useSigner,
} from '@tryrolljs/design-system'
import heroImage from '../../assets/hero.png'
import { MEMBERSHIPS_MARKETING_SITE } from '../../constants'
import { useConnectWalletModal } from '../../hooks/web3'
import { useNavigateDashboard } from '../../navigation/actions'

export const Hero = () => {
  const theme = useTheme()
  const signer = useSigner()
  const navigateDashboard = useNavigateDashboard()
  const connectWallet = useConnectWalletModal()
  return (
    <div
      style={{ backgroundColor: '#FCFCFC' }}
      className="flex justify-center w-full pt-20 pb-36 px-5"
    >
      <div className="flex justify-between max-w-7xl w-full items-center">
        <div className="flex flex-col">
          <div className="mb-6 max-w-xl">
            <LargeTitle weight="bold" color={theme.text.primary}>
              Roll Memberships
            </LargeTitle>
          </div>
          <div className="max-w-lg mb-10">
            <Header color={theme.text.secondary}>
              {
                'A fungible version of the Web2 membership model, now offered as a Web3 public good for creators, DAOs and communities across the internet.\n\nRoll Memberships is a fungible on-chain membership model that works with any ERC20 token and separates each tier into lots, or groups of each token.\n\nLet your most loyal followers support you with Roll Memberships now.'
              }
            </Header>
          </div>
          <div className="flex gap-6">
            <a
              href={MEMBERSHIPS_MARKETING_SITE}
              target="_blank"
              rel="noreferrer"
            >
              <Button variant="primary">
                <SubHeader weight="bold" color={theme.background.primary}>
                  Learn More
                </SubHeader>
              </Button>
            </a>
            <Button
              variant="secondary"
              onPress={() => (!signer ? connectWallet() : navigateDashboard())}
            >
              <Body weight="bold">
                {signer ? 'Create Membership' : 'Connect Wallet'}
              </Body>
            </Button>
          </div>
        </div>
        <div className="w-full max-w-lg">
          <img src={heroImage} alt="hero image" />
        </div>
      </div>
    </div>
  )
}
