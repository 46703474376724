import { useMemo } from 'react'
import { LargeHeader, useTheme } from '@tryrolljs/design-system'
import { getClosestPhase } from '../../core/campaigns'
import { useAllCampaigns } from '../../hooks/campaigns'
import { CardLoader } from '../../molecules/loaders'
import { MailChimpBanner } from '../../molecules/mailchimpBanner'
import FAQ from '../../organisms/faq'
import { Hero } from '../../organisms/hero'
import {
  MembershipCard,
  MembershipCardVariant,
} from '../../organisms/membershipCard'
import { WithWalletContainer } from '../../organisms/withWalletContainer'
import { PageTemplate } from '../../templates/page'
import { PageContent } from '../../templates/pageContent'
import { Campaign } from '../../types'
import { range } from '../../utils'
import { CAMPAIGN_LIST_LIMIT } from '../../constants'

export const ExplorePage = () => {
  const theme = useTheme()
  const { campaigns, isLoading } = useAllCampaigns(CAMPAIGN_LIST_LIMIT)

  const { featureMemberships, pastMemberships } = useMemo(() => {
    const value: {
      featureMemberships: Campaign[]
      pastMemberships: Campaign[]
    } = { featureMemberships: [], pastMemberships: [] }
    campaigns.forEach((campaign) => {
      const isActive = !!getClosestPhase(campaign)
      if (isActive) {
        value.featureMemberships.push(campaign)
      } else {
        value.pastMemberships.push(campaign)
      }
    })
    return value
  }, [campaigns])

  return (
    <PageTemplate isFullWidth>
      <div className="mb-20">
        <Hero />
      </div>
      <div className="flex flex-col gap-20">
        <WithWalletContainer>
          {isLoading && (
            <div className="flex justify-center w-full">
              <div className="flex flex-col gap-6 max-w-7xl">
                {range(1, 4).map((i) => (
                  <CardLoader key={i} />
                ))}
              </div>
            </div>
          )}
          <div className="flex flex-col gap-16">
            {!isLoading && !!featureMemberships.length && (
              <PageContent>
                <div className="flex flex-col gap-6">
                  <LargeHeader weight="bold" color={theme.text.primary}>
                    Featured Memberships
                  </LargeHeader>
                  {featureMemberships.map((elem) => (
                    <MembershipCard
                      key={elem.campaignId}
                      id={elem.campaignId}
                      variant={MembershipCardVariant.simple}
                    />
                  ))}
                </div>
              </PageContent>
            )}
            <MailChimpBanner />
            {!isLoading && !!pastMemberships.length && (
              <PageContent>
                <div className="flex flex-col gap-6">
                  <LargeHeader weight="bold" color={theme.text.primary}>
                    Past Memberships
                  </LargeHeader>
                  {pastMemberships.map((elem) => (
                    <MembershipCard
                      key={elem.campaignId}
                      id={elem.campaignId}
                      variant={MembershipCardVariant.simple}
                    />
                  ))}
                </div>
              </PageContent>
            )}
          </div>
        </WithWalletContainer>
        <PageContent>
          <LargeHeader weight="bold" color={theme.text.primary}>
            Frequently Asked Questions
          </LargeHeader>
          <FAQ />
        </PageContent>
      </div>
    </PageTemplate>
  )
}
