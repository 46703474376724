import React, { useMemo } from 'react'
import { useCampaignByAddressSelector } from '../../hooks/selectors/campaign'
import { formatNumber } from '../../utils'
import { ProgressLine } from '../progressLine'

interface LotsClaimedProgressProps {
  campaignId: string
}

export const LotsClaimedProgress: React.FC<LotsClaimedProgressProps> = ({
  campaignId,
}) => {
  const campaign = useCampaignByAddressSelector(campaignId)

  const totalLots: number = useMemo(() => {
    if (!campaign?.phasesData) return 0
    return campaign.phasesData
      .map((elem) => elem.amountTotal)
      .reduce((acc, curr) => curr + acc, 0)
  }, [campaign])

  const claimedLots: number = useMemo(() => {
    if (!campaign?.phasesData) return 0
    return campaign.phasesData
      .map((elem) => elem.released)
      .reduce((acc, curr) => curr + acc, 0)
  }, [campaign])

  const progress: number = useMemo(() => {
    if (!totalLots || !claimedLots) return 0
    return (claimedLots / totalLots) * 100
  }, [totalLots, claimedLots])

  return (
    <ProgressLine
      progress={progress}
      percentageLabel={`Lots claimed ${progress.toFixed(1)}%`}
      countLabel={`(${formatNumber(claimedLots)}/${formatNumber(totalLots)})`}
    />
  )
}
