import { MerkleTree } from "merkletreejs";
import { ethers, utils } from "ethers";

export const createMerkleTree = (addresses: string[]) => {
  const leaves = addresses.map((x) => utils.keccak256(x));
  return new MerkleTree(leaves, utils.keccak256, { sortPairs: true });
};

export const getProof = (tree: MerkleTree, address: string) => {
  const leaf = utils.keccak256(address);
  return tree.getProof(leaf).map((m) => m.data);
};

export const getRoot = (tree: MerkleTree) => {
  return "0x" + tree.getRoot().toString("hex");
};

export const createMerkleTreeFromForm = (formList?: string) => {
  if (!formList) return;
  const list = formList
    .split("\n")
    .filter(address => ethers.utils.isAddress(address));
  const tree = createMerkleTree(list);
  const root = getRoot(tree);
  return { list, root };
};
