import React from 'react'
import LockedStatus from '../../assets/svg/phase-progress-lock.svg'
import ActiveStatus from '../../assets/svg/phase-progress-active.svg'
import DoneStatus from '../../assets/svg/phase-progress-done.svg'
import { StatusPhase } from '../../types'

interface PhaseStatusIconProps {
  status: StatusPhase
}

export const PhaseStatusIcon: React.FC<PhaseStatusIconProps> = ({ status }) => {
  if (status === StatusPhase.active) return <ActiveStatus />
  if (status === StatusPhase.locked) return <LockedStatus />
  if (status === StatusPhase.done || status === StatusPhase.soldOut)
    return <DoneStatus />
  return null
}
