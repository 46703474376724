import React from 'react'
import { useTheme } from '@tryrolljs/design-system'
import { range } from '../../utils'

export const Background: React.FC<{ elements?: number }> = ({
  children,
  elements = 3,
}) => {
  const theme = useTheme()
  const rows = range(1, elements)
    .map((_e, i) => (i === 1 ? '1fr' : 'auto'))
    .join(' ')
  return (
    <div
      className="grid min-h-screen"
      style={{
        gridTemplateRows: rows,
        backgroundColor: theme.background.primary,
      }}
    >
      {children}
    </div>
  )
}
