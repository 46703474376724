import {
  Body,
  Button,
  Caption,
  LargeHeader,
  useTheme,
} from '@tryrolljs/design-system'
import { BigNumber } from 'ethers'
import { AmountToken } from '../amountToken'
import ClipboardTextIcon from '../../assets/svg/clipboard-text.svg'
import EditIcon from '../../assets/svg/edit-icon.svg'
import ActiveStatus from '../../assets/svg/phase-progress-active.svg'

interface CollapsePhaseViewProps {
  index: number
  pricePerLot: BigNumber
  tokenAddress: string
  date: string
  amountTotal: number
  hasAllowlist: boolean
  isEditActive: boolean
  onClickAllowList: () => void
  onClickEdit?: () => void
}

export const CollapsePhaseView: React.FC<CollapsePhaseViewProps> = ({
  index,
  pricePerLot,
  tokenAddress,
  date,
  amountTotal,
  hasAllowlist,
  onClickAllowList,
  onClickEdit,
  isEditActive,
}) => {
  const theme = useTheme()
  return (
    <div className="flex px-6 py-4 border border-[#EAEEF3] rounded-2xl items-center justify-between">
      <div className="flex items-center">
        <div className="mr-4">
          <LargeHeader weight="bold" color={theme.text.highlight}>
            #{index + 1}
          </LargeHeader>
        </div>
        <div className="flex flex-col gap-1 mr-10">
          <div className="flex gap-1 items-center">
            <AmountToken
              bigTokenAmount={pricePerLot}
              address={tokenAddress}
              color={theme.text.secondary}
              fontSize={12}
              circleSize={16}
            />
            <Caption color={theme.text.secondary} weight="semiBold">
              per lot
            </Caption>
          </div>
          <Caption weight="semiBold" color={theme.text.secondary}>
            {date}
          </Caption>
        </div>
        <Caption weight="semiBold" color={theme.text.secondary}>
          {amountTotal.toString()} Lots
        </Caption>
      </div>
      <div className="flex gap-4">
        <div className="relative">
          <Button
            variant="secondary"
            onPress={onClickAllowList}
            disabled={!isEditActive}
          >
            <div className="flex items-center gap-2">
              <ClipboardTextIcon />
              <Body color={theme.text.primary}>Allowlist</Body>
            </div>
          </Button>
          {hasAllowlist && (
            <div className="absolute right-0 -top-2">
              <ActiveStatus />
            </div>
          )}
        </div>
        {onClickEdit && (
          <Button variant="secondary" onPress={onClickEdit}>
            <div className="flex items-center gap-2">
              <EditIcon />
              <Body color={theme.text.primary}>Edit</Body>
            </div>
          </Button>
        )}
      </div>
    </div>
  )
}
