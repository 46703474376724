import { format, utcToZonedTime } from 'date-fns-tz'

export const formatInUTC = (date: number, fmt: string) =>
  format(utcToZonedTime(date, 'UTC'), fmt, { timeZone: 'UTC' })

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd'

export const DATE_FORMAT_WITH_TIME = 'MM-dd-yyyy K:mmaaa'

export const fullStartEndDatePhase = (start: number, duration: number) => {
  return `${formatInUTC(start * 1000, DATE_FORMAT_WITH_TIME)} | ${formatInUTC(
    (start + duration) * 1000,
    DATE_FORMAT_WITH_TIME,
  )} UTC`
}

export const MILLISECONDS_SECOND = 1000
export const SCONDS_MINUTES = 60
export const MINUTES_HOURS = 60
export const HOURS_DAYS = 24

export const dateToSeconds = (value: Date) => {
  return Math.round(value.getTime() / MILLISECONDS_SECOND)
}

export const millisecondsToDays = (value: number) => {
  return Math.floor(
    value / (MILLISECONDS_SECOND * SCONDS_MINUTES * MINUTES_HOURS * HOURS_DAYS),
  )
}
