import { useChainID } from '@tryrolljs/design-system'
import { useCallback, useEffect, useState } from 'react'
import { getEtherscanLink } from '../../utils/web3'

// TODO: Move this to roll-ui (update both memberships and staking)
/**
 * Debounce hook
 * @param value value to debounce
 * @param delay time in milliseconds
 * @returns value
 */
export function useDebounce<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

export enum EtherscanType {
  'address' = 'address',
  'token' = 'token',
  'tx' = 'tx',
}

export const useEtherscanLink = () => {
  const chainId = useChainID()

  const getEtherscanLink_ = useCallback(
    (address: string, type: EtherscanType) => {
      return getEtherscanLink({ address, type, chainId: chainId || 0 })
    },
    [chainId],
  )

  return getEtherscanLink_
}
