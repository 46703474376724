import React from 'react'
import { Caption, useTheme } from '@tryrolljs/design-system'

interface ProgressLineProps {
  progress: number
  percentageLabel?: string
  countLabel?: string
}

export const ProgressLine: React.FC<ProgressLineProps> = ({
  progress,
  percentageLabel,
  countLabel,
}) => {
  const theme = useTheme()
  return (
    <div className="flex flex-col gap-2">
      <div>
        <div className="flex gap-1">
          <Caption weight="semiBold" color={theme.text.primary}>
            {percentageLabel}
          </Caption>
          <Caption weight="semiBold" color={theme.text.secondary}>
            {countLabel}
          </Caption>
        </div>
        <div
          style={{ backgroundColor: theme.background.secondary }}
          className="w-full h-0.5 relative"
        >
          <div
            style={{
              width: `${progress}%`,
              backgroundColor: theme.text.highlight,
            }}
            className="absolute left-0 bottom-0 h-0.5"
          />
        </div>
      </div>
    </div>
  )
}
