/* Node modules */
import { useEffect } from 'react'
import { Routes, Route, BrowserRouter, useLocation } from 'react-router-dom'

/* Components/Pages */
import CampaignPage from '../pages/campaign'
import { CreatePage } from '../pages/create'
import DashboardPage from '../pages/dashboard'
import { EditPage } from '../pages/edit'
import { ExplorePage } from '../pages/explore'
import {
  routeCampaignDetails,
  routeHome,
  routeDashboard,
  routeCreateCampaign,
  routeEditCampaign,
} from './routes'

export const RootNavigator = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <RoutesDef />
    </BrowserRouter>
  )
}

const RoutesDef = () => {
  return (
    <Routes>
      <Route path={routeHome()} element={<ExplorePage />} />
      <Route path={routeDashboard()} element={<DashboardPage />} />
      <Route
        path={routeCampaignDetails(':address')}
        element={<CampaignPage />}
      />
      <Route path={routeCreateCampaign()} element={<CreatePage />} />
      <Route path={routeEditCampaign(':address')} element={<EditPage />} />
    </Routes>
  )
}

export const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}
