import { BigNumber } from 'ethers'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useEthAddress } from '@tryrolljs/design-system'
import { useWithAnyAsync } from '..'
import {
  BuyPhaseProps,
  claimOwnerFees,
  claimReferralFees,
  claimUnsoldTokens,
  getBuyPerWallet,
  getClaimedPhase,
  getReferral,
  handleBuyPhase,
  updateMerkleRoot,
} from '../../contracts/phases'
import {
  allowlistValidation,
  checkIsPhasePublic,
  formatMetadata,
  getDefaultPhase,
  getLeaves,
} from '../../core/phases'
import { isNativeToken } from '../../core/tokens'
import { toastInfo, toastSuccess } from '../../molecules/toasts'
import { AllowanceSpenders, MerkleTrees } from '../../types'
import { CreateStepsId } from '../../types/create'
import { createMerkleTreeFromForm } from '../../utils/merkletree'
import { pinJSONToIPFS } from '../../utils/pinata'
import { useUpdateCampaign } from '../campaigns'
import {
  useBuyNotifications,
  useClaimNotifications,
  useUpdateMerkleRootNotifications,
  useClaimUnsoldTokensNotifications,
} from '../notifications'
import {
  useMerkleTreeLeavesSelector,
  useResetAfterBuy,
  useSetIsLoadingLeaves,
  useSetMaxLots,
  useSetMerkleTreeLeaves,
  useSetNumberOfLots,
  useSetPhase,
} from '../selectors/buy'
import {
  useFormValuesState,
  useUpdateActivePreview,
  useUpdateFormState,
  useUpdateScheduleMerkle,
} from '../selectors/create'
import { useUpdateAllowance, useUpdateBalance } from '../tokens'
import { useContractPool } from '../../providers/contracts'
import { useCampaignByAddressSelector } from '../selectors/campaign'

export const useBuyPhase = () => {
  const { membershipsFactory } = useContractPool()
  const userAddress = useEthAddress()
  const { isLoading, execute } = useWithAnyAsync()
  const updateCampaign = useUpdateCampaign()
  const leaves = useMerkleTreeLeavesSelector()
  const updateAllowance = useUpdateAllowance()
  const notifications = useBuyNotifications()
  const resetAfterBuy = useResetAfterBuy()
  const { updateBalance } = useUpdateBalance()

  const buyPhase = useCallback(
    async ({ root, ...props }: BuyPhaseProps & { root: string }) => {
      if (!membershipsFactory || !userAddress) return null
      const params = { ...props }
      await execute(async () => {
        if (!checkIsPhasePublic(root) && !!leaves) {
          const validation = allowlistValidation({
            leaves,
            root,
            userAddress,
          })
          if (validation) {
            params.proof = validation.proof
            params.isVerify = validation.isVerify
          }
        }
        await handleBuyPhase({
          ...params,
          ...notifications,
          onSuccess: (message) => {
            notifications.onSuccess(message)
            resetAfterBuy()
          },
          contract: membershipsFactory,
        })
      })
      updateCampaign(props.phaseId)
      if (!isNativeToken(props.paymentAddr)) {
        updateAllowance(props.paymentAddr, AllowanceSpenders.membershipsImpl)
      }
      updateBalance(props.paymentAddr)
    },
    [
      membershipsFactory,
      userAddress,
      leaves,
      execute,
      updateCampaign,
      resetAfterBuy,
      updateAllowance,
      updateBalance,
      notifications,
    ],
  )

  return { isLoading, buyPhase }
}

export const useClaimPhaseOwner = (cb: () => void) => {
  const { membershipsFactory } = useContractPool()
  const { isLoading, execute } = useWithAnyAsync()
  const updateCampaign = useUpdateCampaign()
  const notifications = useClaimNotifications()

  const claimFees = useCallback(
    async (phaseId: string) => {
      if (!membershipsFactory) return null
      await execute(() =>
        claimOwnerFees({
          phaseId,
          contract: membershipsFactory,
          ...notifications,
        }),
      )
      updateCampaign(phaseId)
      cb()
    },
    [membershipsFactory, execute, updateCampaign, notifications, cb],
  )

  return { isLoading, claimFees }
}

export enum UserType {
  OWNER = 'OWNER',
  REFERRAL = 'REFERRAL',
  UNSOLD = 'UNSOLD',
}

export const useClaimedFees = (phaseId: string, userType: UserType) => {
  const { membershipsViewFactory } = useContractPool()
  const { execute, isLoading } = useWithAnyAsync()
  const [claimed, setClaimed] = useState<BigNumber>()

  const value = useMemo(() => {
    if (userType === UserType.OWNER) return 0
    if (userType === UserType.REFERRAL) return 2
    if (userType === UserType.UNSOLD) return 3
    return 0
  }, [userType])

  const getClaimed = useCallback(async () => {
    if (!membershipsViewFactory) return
    const response = await execute(() =>
      getClaimedPhase({
        phaseId,
        contract: membershipsViewFactory,
        value,
      }),
    )
    return setClaimed(response || BigNumber.from(0))
  }, [setClaimed, execute, phaseId, membershipsViewFactory, value])

  useEffect(() => {
    getClaimed()
  }, [getClaimed])

  return {
    claimed,
    isLoading,
    getClaimed,
  }
}

export const useCreateMerkleTrees = () => {
  const formValues = useFormValuesState()
  const { execute, isLoading: isCreatingMerkleTrees } = useWithAnyAsync()
  const updateScheduleMerkle = useUpdateScheduleMerkle()
  const { updateMetadata } = useUpdateFormState()
  const updateActivePreview = useUpdateActivePreview()

  const createMerkleTrees = useCallback(async () => {
    const merkles: MerkleTrees = {}
    formValues.schedules.forEach((schedule) => {
      if (!schedule.allowlist?.list) return
      const responseTree = createMerkleTreeFromForm(schedule.allowlist.list)
      if (!responseTree) return
      const { list, root } = responseTree
      updateScheduleMerkle(root, schedule.allowlist.list)
      merkles[root] = list
    })
    if (!Object.keys(merkles).length) {
      return updateActivePreview(CreateStepsId.phases)
    }
    toastInfo({ description: 'Saving Allowlist' })
    const pinataHash = await execute(() =>
      pinJSONToIPFS({ merkleTrees: merkles }),
    )
    if (!pinataHash) return
    toastSuccess({ description: `Saved Allowlist.` })
    updateMetadata({
      ...formValues.metadata,
      merkleTreesHash: pinataHash,
    })
    updateActivePreview(CreateStepsId.phases)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMetadata, updateScheduleMerkle, execute, formValues])

  return { createMerkleTrees, isCreatingMerkleTrees }
}

export const useUpdateMerkleRoot = () => {
  const { membershipsFactory } = useContractPool()
  const { isLoading, execute } = useWithAnyAsync()
  const notifications = useUpdateMerkleRootNotifications()

  const updateMerkleRoot_ = useCallback(
    async (phaseId: string, root: string) => {
      if (!membershipsFactory) return
      const response = await execute(() =>
        updateMerkleRoot({
          contract: membershipsFactory,
          phaseId,
          root,
          ...notifications,
        }),
      )
      return response
    },
    [membershipsFactory, notifications, execute],
  )

  return { isLoading, updateMerkleRoot: updateMerkleRoot_ }
}

export const useClaimUnsoldTokens = (cb: () => void) => {
  const { membershipsFactory } = useContractPool()
  const { isLoading, execute } = useWithAnyAsync()
  const notifications = useClaimUnsoldTokensNotifications()

  const claimUnsoldTokens_ = useCallback(
    async (phaseId: string) => {
      if (!membershipsFactory) return null
      await execute(() =>
        claimUnsoldTokens({
          phaseId,
          contract: membershipsFactory,
          ...notifications,
        }),
      )
      cb()
    },
    [membershipsFactory, execute, notifications, cb],
  )

  return { isLoading, claimUnsoldTokens: claimUnsoldTokens_ }
}

export const useClaimReferralFee = (cb: () => void) => {
  const { membershipsFactory } = useContractPool()
  const { isLoading, execute } = useWithAnyAsync()
  const notifications = useClaimUnsoldTokensNotifications()

  const claimReferralFees_ = useCallback(
    async (phaseId: string) => {
      if (!membershipsFactory) return null
      await execute(() =>
        claimReferralFees({
          phaseId,
          contract: membershipsFactory,
          ...notifications,
        }),
      )
      cb()
    },
    [membershipsFactory, execute, notifications, cb],
  )

  return { isLoading, claimReferralFees: claimReferralFees_ }
}

export const useReferralFee = (phaseId: string) => {
  const { membershipsViewFactory } = useContractPool()
  const { execute, isLoading } = useWithAnyAsync()
  const [referral, setReferral] = useState<string>('')
  const [referralFee, setReferralFee] = useState<BigNumber>(BigNumber.from(0))

  const getReferralFee = useCallback(async () => {
    if (!membershipsViewFactory) return
    const response = await execute(() =>
      getReferral(phaseId, membershipsViewFactory),
    )
    if (!response) return
    setReferralFee(response.referralFee)
    setReferral(response.referral)
  }, [membershipsViewFactory, execute, phaseId])

  useEffect(() => {
    getReferralFee()
  }, [getReferralFee])

  return { referralFee, isLoading, referral }
}

export const useBuyPerWallet = (phaseId: string) => {
  const { membershipsViewFactory } = useContractPool()
  const userAddress = useEthAddress()
  const { isLoading, execute } = useWithAnyAsync()
  const [buyPerWallet, setBuyPerWallet] = useState(0)

  const getBuyPerWallet_ = useCallback(async () => {
    if (!membershipsViewFactory || !userAddress) return
    const response = await execute(() =>
      getBuyPerWallet({
        contract: membershipsViewFactory,
        phaseId,
        userAddress,
      }),
    )
    if (!response) return
    setBuyPerWallet(response.toNumber())
  }, [phaseId, membershipsViewFactory, setBuyPerWallet, execute, userAddress])

  useEffect(() => {
    getBuyPerWallet_()
  }, [getBuyPerWallet_])

  return { isLoading, buyPerWallet }
}

export const useSetBuyLeaves = ({ campaignId }: { campaignId: string }) => {
  const { execute } = useWithAnyAsync()
  const campaign = useCampaignByAddressSelector(campaignId)
  const setMerkleTreeLeaves = useSetMerkleTreeLeaves()
  const setIsLoadingLeaves = useSetIsLoadingLeaves()
  const merkleTreeHash = useMemo(
    () => formatMetadata(campaign?.metadata ?? '')?.merkleTreesHash,
    [campaign],
  )
  const getLeaves_ = useCallback(async () => {
    if (!merkleTreeHash) return
    setIsLoadingLeaves(true)
    const response = await execute(() => getLeaves(merkleTreeHash))
    if (!response) return
    setMerkleTreeLeaves(response)
    setIsLoadingLeaves(false)
  }, [execute, setMerkleTreeLeaves, setIsLoadingLeaves, merkleTreeHash])

  useEffect(() => {
    getLeaves_()
  }, [getLeaves_])
}

export const useMountBuy = ({ campaignId }: { campaignId: string }) => {
  const campaign = useCampaignByAddressSelector(campaignId)
  const userAddress = useEthAddress()
  const leaves = useMerkleTreeLeavesSelector()
  const setMaxLots = useSetMaxLots()
  const setNumberOfLots = useSetNumberOfLots()
  const setPhase = useSetPhase()

  const setInitialBuyInformation = useCallback(() => {
    if (!campaign || !campaign.phasesData || !userAddress) return
    const defaultPhase = getDefaultPhase({
      phases: campaign.phasesData,
      leaves,
      userAddress,
    })
    if (!defaultPhase) return
    setMaxLots(defaultPhase.maxBuyPerWallet)
    setNumberOfLots(1)
    setPhase({ id: defaultPhase.phaseId, root: defaultPhase.merkleRoot })
  }, [campaign, setMaxLots, setNumberOfLots, setPhase, leaves, userAddress])

  useEffect(() => {
    setInitialBuyInformation()
  }, [setInitialBuyInformation])
}
