import { ethers } from 'ethers'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  useTheme,
  SubHeader,
  Body,
  Caption,
  Button,
  Modal,
  padding,
} from '@tryrolljs/design-system'
import clsx from 'clsx'
import CloseXIcon from '../../assets/svg/close-x.svg'
import {
  useUpdateCampaign,
  useUpdateMerkletreeHash,
} from '../../hooks/campaigns'
import { useUpdateMerkleRoot } from '../../hooks/phases'
import { InputContainer } from '../../molecules/inputContainer'
import { Toggle } from '../../molecules/toggle'
import { AllowlistInput, MerkleTrees } from '../../types'
import { createMerkleTreeFromForm } from '../../utils/merkletree'
import { toastSuccess } from '../../molecules/toasts'

export interface AllowlistModalProps {
  title: string
  defaultValues: AllowlistInput
  merkleList?: string[]
  onSave: (value: AllowlistInput) => void
  isEditMode?: boolean
  closeModal: () => void
  isOpen: boolean
  editData?: {
    campaignId: string
    phaseId: string
    allMerkletrees: MerkleTrees
  }
}

enum AllowlistModalSteps {
  'first' = 'first',
  'second' = 'second',
}

export const AllowlistModal: React.FC<AllowlistModalProps> = ({
  title,
  defaultValues,
  merkleList,
  onSave,
  isEditMode = false,
  editData,
  closeModal,
  isOpen,
}) => {
  const theme = useTheme()
  const [step, setStep] = useState<AllowlistModalSteps>(
    AllowlistModalSteps.first,
  )
  const { isLoading: isUpdatingMetadata, updateMetadata } =
    useUpdateMerkletreeHash(editData?.campaignId ?? '')
  const { isLoading: isUpdatingMerkleRoot, updateMerkleRoot } =
    useUpdateMerkleRoot()
  const updateCampaign = useUpdateCampaign()
  const [root, setRoot] = useState('')

  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    trigger,
  } = useForm<AllowlistInput>({
    mode: 'onBlur',
    defaultValues,
  })

  const buttonTitle = useMemo(() => {
    if (isEditMode && step === AllowlistModalSteps.first) return 'Update'
    return 'Save'
  }, [isEditMode, step])

  const isButtonDisabled = useMemo(() => {
    if (isEditMode) {
      return isUpdatingMetadata || isUpdatingMerkleRoot
    }
    return false
  }, [isEditMode, isUpdatingMetadata, isUpdatingMerkleRoot])

  const isFieldDisabled = useMemo(() => {
    if (!isEditMode) return false
    if (step === AllowlistModalSteps.second) return true
    if (isUpdatingMetadata || isUpdatingMerkleRoot) return true
    return false
  }, [step, isEditMode, isUpdatingMetadata, isUpdatingMerkleRoot])

  const isToggleDisabled = useMemo(() => {
    if (!isEditMode) return false
    return isFieldDisabled || defaultValues.isActive
  }, [isEditMode, isFieldDisabled, defaultValues])

  const handleEditModeSubmit = async (data: AllowlistInput) => {
    if (!editData) return
    if (step === AllowlistModalSteps.first) {
      const response = createMerkleTreeFromForm(data.list)
      if (!response) return
      const { list, root: responseRoot } = response
      const responseUpdate = await updateMetadata(
        responseRoot,
        list,
        editData.allMerkletrees,
      )
      if (!responseUpdate?.success) return
      setStep(AllowlistModalSteps.second)
      setRoot(responseRoot)
    }
    if (step === AllowlistModalSteps.second) {
      const response = await updateMerkleRoot(editData.phaseId, root)
      if (!response?.success) return
      updateCampaign(editData.campaignId)
      return closeModal()
    }
  }

  const handleClear = async () => {
    if (!isFieldDisabled) {
      setValue('list', '')
      await trigger('list')
    }
  }

  const onSubmit = () => {
    handleSubmit((data) => {
      if (!isEditMode) {
        onSave(data)
        return closeModal()
      }
      handleEditModeSubmit(data)
    })()
  }

  const handleFixFormat = async () => {
    const rawList = watch('list')
    const list = rawList?.split('\n')
    const fixedList = list?.filter(ethers.utils.isAddress) ?? []
    setValue('list', fixedList.join('\n'))
    await trigger('list')
    toastSuccess({
      description: 'Invalid wallet address removed',
    })
  }

  useEffect(() => {
    setValue('isActive', !!merkleList?.length)
    setValue('list', merkleList?.join('\n') ?? '')
  }, [merkleList, setValue])

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Modal.Content style={[padding.p24]}>
        <div className="flex flex-col w-full gap-6">
          <div className="flex justify-between items-center w-full">
            <SubHeader weight="bold" color={theme.text.primary}>
              {title}
            </SubHeader>
            <div className="w-6 text-black cursor-pointer" onClick={closeModal}>
              <CloseXIcon />
            </div>
          </div>
          <div className="flex justify-between items-start">
            <div className="flex flex-col gap-1">
              <Body color={theme.text.primary} weight="bold">
                Exclusive
              </Body>
              <Caption color={theme.text.secondary}>
                Only listed wallet addresses can claim
              </Caption>
            </div>
            <Controller
              control={control}
              name="isActive"
              render={({ field: { value, onChange } }) => (
                <Toggle
                  isActive={!!value}
                  isDisabled={isToggleDisabled}
                  onClick={() => {
                    onChange(!value)
                    setValue('list', '')
                  }}
                />
              )}
            />
          </div>
          {watch('isActive') && (
            <div className="flex flex-col gap-2">
              <div className="flex justify-between items-center">
                <Body weight="bold" color={theme.text.primary}>
                  Public Addresses
                </Body>
                <div
                  onClick={handleClear}
                  className={clsx(
                    isFieldDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
                  )}
                >
                  <Caption color={theme.text.highlight}>Clear</Caption>
                </div>
              </div>
              <div>
                <InputContainer
                  error={errors.list?.message}
                  action={{
                    title: 'Remove invalid wallet address',
                    isVisible: errors.list?.message === 'Wrong format',
                    onPress: handleFixFormat,
                  }}
                >
                  <textarea
                    style={{ color: theme.text.secondary }}
                    disabled={isFieldDisabled}
                    className={clsx(
                      'w-full h-44 text-sm',
                      isFieldDisabled ? 'cursor-not-allowed' : 'cursor-text',
                    )}
                    {...register('list', {
                      required: {
                        value: !!watch('isActive'),
                        message: 'This field is required',
                      },
                      validate: (v) =>
                        v
                          ?.split('\n')
                          .every((e) => !!ethers.utils.isAddress(e)) ||
                        'Wrong format',
                    })}
                  />
                </InputContainer>
              </div>
            </div>
          )}
          <div className="flex justify-end gap-4">
            <div className="w-fit">
              <Button variant="secondary">
                <SubHeader color={theme.text.primary} onPress={closeModal}>
                  Cancel
                </SubHeader>
              </Button>
            </div>
            <div className="w-fit">
              <Button
                variant="primary"
                title={buttonTitle}
                onPress={onSubmit}
                disabled={isButtonDisabled}
              />
            </div>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
}
