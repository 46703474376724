import React, { useState } from 'react'
import {
  Anchor,
  SubHeader,
  useTheme,
  CollapsableView,
} from '@tryrolljs/design-system'
import { GetStartedBanner } from '../../molecules/getStartedBanner'

const faqList = [
  {
    title: 'What is Roll Memberships?',
    content: (
      <>
        <SubHeader>
          A fungible version of the Web2 membership model, now offered as a Web3
          public good for creators, DAOs and communities across the internet.
          Roll Memberships is a fungible on-chain membership model that works
          with any ERC20 token and separates each tier into lots, or groups of
          each token. Lots are the Web3 equivalent of a membership level. They
          are the number of tokens each user receives once they pay a membership
          fee. Deposit your ERC20 tokens into the Roll Memberships smart
          contract and determine the amount of ERC20 tokens per lot, the lot
          price in ETH, the total number of lots and if there is an allowlist
          where users can become a member before a public launch.
        </SubHeader>
      </>
    ),
    isExpanded: false,
  },
  {
    title: 'How do I become a member?',
    content: (
      <SubHeader>
        Connect a Web3 wallet like Metamask to the Roll Memberships page and
        choose the creator community that you’d like to join. You’ll see the
        perks that you will get as a member and membership fees in a native
        token like ETH. You can become a member by choosing a tier that makes
        sense for you and paying the membership fee in the native token.
      </SubHeader>
    ),
    isExpanded: false,
  },
  {
    title: 'Can I become a member from my Roll Wallet?',
    content: (
      <>
        <SubHeader>
          Unfortunately no, not yet. The Roll protocol is a suite of smart
          contracts that will govern and power parts of the creator economy. The
          Roll web app is a custodial wallet that makes social tokens easier to
          use across the internet. You will need a non-custodial wallet to
          interact with products like Roll Memberships or Roll Staking. You can
          always send your tokens from your Roll wallet to a non-custodial
          wallet like Metamask.
        </SubHeader>
      </>
    ),
    isExpanded: false,
  },
  {
    title: 'What is a “lot” of tokens?',
    content: (
      <>
        <SubHeader>
          Lots are groups of tokens that represent the Web3 equivalent of a
          membership level. They are the number of tokens each user receives
          once they pay a membership fee. If one lot is 10 tokens and my
          membership tier is 5 lots, users will become a member at this level by
          receiving 50 tokens.
        </SubHeader>
      </>
    ),
    isExpanded: false,
  },
  {
    title: 'How do I create a Membership?',
    content: (
      <>
        <SubHeader>
          Click Create Memberships and deposit your ERC20 tokens into the Roll
          Memberships smart contract to determine the amount of ERC20 tokens per
          lot, the lot price in ETH, the time length of the contract, the total
          number of lots, your membership perks and if there is an allowlist
          where users can become a member before a public launch.
        </SubHeader>
      </>
    ),
    isExpanded: false,
  },
]

const FAQ: React.FC = () => {
  const [faqs, setFaqs] = useState(faqList)
  const theme = useTheme()
  const onToggle = (index: number) => {
    setFaqs(
      faqs.map((el, i) =>
        i === index ? { ...el, isExpanded: !el.isExpanded } : el,
      ),
    )
  }
  return (
    <div className="my-4 pb-6">
      {faqs.map((faq, index) => {
        return (
          <div
            key={faq.title}
            className="my-4"
            style={{ backgroundColor: theme.background.primary }}
          >
            <CollapsableView
              title={faq.title}
              content={faq.content}
              isExpanded={faq.isExpanded}
              toggle={() => onToggle(index)}
            />
          </div>
        )
      })}

      <div className="mt-6">
        <SubHeader color={theme.text.primary}>
          Got more questions on how to use Roll Memberships? Check our{' '}
          <Anchor
            href="https://support.tryroll.com/hc/en-us"
            fontSize={undefined}
          >
            FAQ
          </Anchor>{' '}
          or email{' '}
          <Anchor href="mailto:support@tryroll.com" fontSize={undefined}>
            support@tryroll.com
          </Anchor>
        </SubHeader>
      </div>

      <div className="mt-16">
        <GetStartedBanner />
      </div>
    </div>
  )
}

export default FAQ
