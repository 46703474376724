import React from 'react'
import {
  Button,
  SubHeader,
  useTheme,
  Body,
  useSigner,
  container,
} from '@tryrolljs/design-system'
import { useConnectWalletModal } from '../../hooks/web3'
import connectWalletImage from '../../assets/connect-wallet.png'

interface WithWalletContainerProps {
  description?: string
  marginTop?: number
}

export const WithWalletContainer: React.FC<WithWalletContainerProps> = ({
  children,
  marginTop,
  description = 'Connect your wallet to view memberships or create a new membership',
}) => {
  const signer = useSigner()
  const theme = useTheme()
  const connectWallet = useConnectWalletModal()
  if (!signer) {
    return (
      <div
        style={{ marginTop }}
        className="w-full flex flex-col justify-center items-center"
      >
        <div className="max-w-xs h-auto mb-12">
          <img src={connectWalletImage} alt="Connect wallet image" />
        </div>
        <div className="max-w-md">
          <SubHeader style={[container.alignCenter]}>{description}</SubHeader>
        </div>
        <div className="mt-6">
          <Button variant="primary" onPress={connectWallet}>
            <Body weight="bold" color={theme.background.primary}>
              Connect Wallet
            </Body>
          </Button>
        </div>
      </div>
    )
  }
  return <>{children}</>
}
