import { ConnectWeb3Options, useModal } from '@tryrolljs/design-system'
import { createContext, useEffect } from 'react'

interface WalletConnectModalContextProps {
  connectWallet: () => void
}

export const WalletConnectModalContext =
  createContext<WalletConnectModalContextProps>({
    connectWallet: () => null,
  })

export const ConnectWalletModalProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { isOpen, close, open } = useModal()
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])
  return (
    <WalletConnectModalContext.Provider value={{ connectWallet: open }}>
      {isOpen && (
        <div className="fixed inset-0 min-h-screen min-w-full z-50">
          <div className="z-10 absolute min-h-screen min-w-full bg-black opacity-50" />
          <div className="z-20 relative flex justify-center w-full min-h-screen items-center">
            <ConnectWeb3Options onClose={close} onSelect={close} />
          </div>
        </div>
      )}
      {children}
    </WalletConnectModalContext.Provider>
  )
}
