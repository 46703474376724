/* Node Modules */
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  CHAIN_ID_MAIN_NET,
  CHAIN_ID_POLYGON,
  Footer,
  InvalidNetworkBanner,
  LargeHeader,
  useTheme,
} from '@tryrolljs/design-system'
import clsx, { ClassValue } from 'clsx'
import { useCampaignById } from '../../hooks/campaigns'
import { PageHeader } from '../../molecules/pageHeader'
import { CampaignDetails } from '../../organisms/campaignDetails'

/* Organisms */
import FAQ from '../../organisms/faq'
import { Mint } from '../../organisms/mint'
import { ShareCampaign } from '../../organisms/shareCampaign'
import { WithWalletContainer } from '../../organisms/withWalletContainer'

/* Templates */
import { Background } from '../../templates/pageBackground'
import { PageContent } from '../../templates/pageContent'
import { DetailsLoader } from '../../molecules/loaders'
import { useResetBuyState } from '../../hooks/selectors/buy'

import metaImage from '../../assets/detail-page.png'
import { Buy } from '../../organisms/buy'

const RatioContainer = ({
  LeftComponent,
  RightComponent,
  className,
  hasEqualWidth = false,
  contentWidth,
  maxWidth,
}: {
  LeftComponent: React.ReactNode
  RightComponent: React.ReactNode
  hasEqualWidth?: boolean
  className?: ClassValue
  contentWidth?: string | number
  maxWidth?: number
}) => {
  return (
    <div className={clsx('w-full flex justify-center mb-8', className)}>
      <div
        style={{ width: contentWidth || '80%', maxWidth }}
        className="flex justify-center gap-[72px]"
      >
        <div className={clsx(hasEqualWidth ? 'w-1/2' : 'w-3/5')}>
          {LeftComponent}
        </div>
        <div className={clsx(hasEqualWidth ? 'w-1/2' : 'w-2/5')}>
          {RightComponent}
        </div>
      </div>
    </div>
  )
}

const CampaignPage: React.FC = () => {
  const params = useParams()
  const theme = useTheme()
  const address = params.address as string
  const { campaign, isLoading } = useCampaignById({ id: address })
  const resetBuyState = useResetBuyState()

  useEffect(() => {
    return () => {
      resetBuyState()
    }
  }, [resetBuyState])

  return (
    <Background>
      <Helmet>
        <title>Claim Your Memberships | Roll</title>
        <meta
          name="description"
          content="Claim your Membership today to get exclusive access and perks"
        />
        <meta property="og:image" content={metaImage} />
      </Helmet>
      <PageHeader />
      <div className="h-fit">
        <InvalidNetworkBanner
          title="Unsupported chain. Please switch to Etheruem Mainnet or Polygon in your wallet"
          supportedChainIDs={[CHAIN_ID_MAIN_NET, CHAIN_ID_POLYGON]}
        />
      </div>
      <WithWalletContainer>
        {isLoading && (
          <div className="flex w-full justify-center mt-20">
            <DetailsLoader />
          </div>
        )}
        {campaign && (
          <>
            <RatioContainer
              contentWidth="100%"
              maxWidth={1012}
              hasEqualWidth
              className="border-b-2 py-20 px-5"
              LeftComponent={<Mint campaignId={campaign.campaignId} />}
              RightComponent={<Buy campaignId={campaign.campaignId} />}
            />
            <PageContent>
              <RatioContainer
                contentWidth={'100%'}
                className="px-5"
                LeftComponent={
                  <CampaignDetails campaignId={campaign.campaignId} />
                }
                RightComponent={
                  <ShareCampaign campaignId={campaign.campaignId} />
                }
              />
              <div className="mt-4">
                <LargeHeader weight="bold" color={theme.text.primary}>
                  Frequently Asked Questions
                </LargeHeader>
                <FAQ />
              </div>
            </PageContent>
          </>
        )}
      </WithWalletContainer>
      <Footer />
    </Background>
  )
}

export default CampaignPage
