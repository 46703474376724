import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../..'
import {
  completeStep,
  goToReview,
  goToStep,
  resetCreateState,
  selectActiveReviewStep,
  selectActiveStep,
  selectStepsLength,
  updateActivePreview,
  updateFormFees,
  updateFormLots,
  updateFormMetadata,
  updateFormPaymentAsset,
  updateFormSchedules,
  backToStep,
  resetReviewSteps,
  updateScheduleMerkle,
  setMinRollFee,
} from '../../../state/create/reducer'
import {
  CreateFormInputFees,
  CreateFormInputLots,
  CreateFormInputMetadata,
  CreateFormInputSchdules,
  CreateFormInputState,
} from '../../../types'
import { CreateStepsId } from '../../../types/create'

export const useActiveCreateStepSelector = () => {
  return useAppSelector((state) => selectActiveStep(state.create))
}

export const useStepsLength = () => {
  return useAppSelector((state) => selectStepsLength(state.create))
}

export const useCreateSteps = () => {
  return useAppSelector((state) => state.create.steps)
}

export const useCreateReviewSteps = () => {
  return useAppSelector((state) => state.create.reviewSteps)
}

export const useActiveCreateReviewStepsSelector = () => {
  return useAppSelector((state) => selectActiveReviewStep(state.create))
}

export const useFormValuesState = () => {
  return useAppSelector((state) => state.create.formState)
}

export const useMinRollFeeSelector = () => {
  return useAppSelector((state) => state.create.minRollFee)
}

// Actions
export const useCompleteStep = () => {
  const dispatch = useAppDispatch()
  return useCallback(
    (id: CreateStepsId) => {
      dispatch(completeStep(id))
    },
    [dispatch],
  )
}

export const useSetMinRollFee = () => {
  const dispatch = useAppDispatch()
  return useCallback(
    (val: number) => {
      dispatch(setMinRollFee(val))
    },
    [dispatch],
  )
}

export const useGoToStep = () => {
  const dispatch = useAppDispatch()
  return useCallback(
    ({ go, current }: { go: CreateStepsId; current: CreateStepsId }) => {
      dispatch(goToStep({ go, current }))
    },
    [dispatch],
  )
}

export const useUpdateActivePreview = () => {
  const dispatch = useAppDispatch()
  return useCallback(
    (id: CreateStepsId) => {
      dispatch(updateActivePreview(id))
    },
    [dispatch],
  )
}

export const useUpdateFormState = () => {
  const dispatch = useAppDispatch()

  const updateLots = useCallback(
    (input: CreateFormInputLots[]) => {
      dispatch(updateFormLots(input))
    },
    [dispatch],
  )

  const updateMetadata = useCallback(
    (input: CreateFormInputMetadata) => {
      dispatch(updateFormMetadata(input))
    },
    [dispatch],
  )

  const updateSchedules = useCallback(
    (input: CreateFormInputSchdules[]) => {
      dispatch(updateFormSchedules(input))
    },
    [dispatch],
  )

  const updatePaymentAsset = useCallback(
    (input: string) => {
      dispatch(updateFormPaymentAsset(input))
    },
    [dispatch],
  )

  const updateFees = useCallback(
    (input: CreateFormInputFees) => {
      dispatch(updateFormFees(input))
    },
    [dispatch],
  )

  return {
    updateLots,
    updateMetadata,
    updateSchedules,
    updatePaymentAsset,
    updateFees,
  }
}

export const useResetCreateState = () => {
  const dispatch = useAppDispatch()
  return useCallback(() => {
    dispatch(resetCreateState())
  }, [dispatch])
}

export const useResetReviewStep = () => {
  const dispatch = useAppDispatch()
  return useCallback(() => {
    dispatch(resetReviewSteps())
  }, [dispatch])
}

export const useGoToReview = () => {
  const dispatch = useAppDispatch()
  return useCallback(
    (form: CreateFormInputState) => {
      dispatch(goToReview({ form }))
    },
    [dispatch],
  )
}

export const useBackToStep = () => {
  const dispatch = useAppDispatch()
  return useCallback(
    (stepId: CreateStepsId) => {
      dispatch(backToStep({ stepId }))
    },
    [dispatch],
  )
}

export const useUpdateScheduleMerkle = () => {
  const dispatch = useAppDispatch()
  return useCallback(
    (root: string, list: string) => {
      dispatch(updateScheduleMerkle({ root, list }))
    },
    [dispatch],
  )
}
