export interface NetworkConfig {
  MEMBERSHIPS_ADDRESS: string
  MEMBERSHIPS_VIEW_ADDRESS: string
  MEMBERSHIPS_IMPL_ADDRESS: string
  ETERNAL_STORAGE_ADDRESS: string
}

interface Config {
  NETWORK_ROPSTEN: NetworkConfig
  NETWORK_GOERLI: NetworkConfig
  NETWORK_MAINNET: NetworkConfig
  NETWORK_LOCAL: NetworkConfig
  NETWORK_MUMBAI: NetworkConfig
  NETWORK_POLYGON: NetworkConfig
  NETWORK_FORM_TESTNET: NetworkConfig
  FRONT_END_URL: string
  FORTMATIC_API_KEY: string
  PORTIS_DAPP_ID: string
}

const networkLocal: NetworkConfig = {
  MEMBERSHIPS_ADDRESS: process.env.REACT_APP_MEMBERSHIPS_ADDRESS || '',
  MEMBERSHIPS_IMPL_ADDRESS:
    process.env.REACT_APP_MEMBERSHIPS_IMPL_ADDRESS || '',
  MEMBERSHIPS_VIEW_ADDRESS:
    process.env.REACT_APP_MEMBERSHIPS_VIEW_ADDRESS || '',
  ETERNAL_STORAGE_ADDRESS: process.env.REACT_APP_ETERNAL_STORAGE_ADDRESS || '',
}

const networkRopsten: NetworkConfig = {
  MEMBERSHIPS_ADDRESS: '0xCCB77fC0351D82B37bd764EEFAF9AD9562aA5Bc7',
  MEMBERSHIPS_IMPL_ADDRESS: '0x0c7aD45DAC0eC2173e9AaaEDb1575310A29a7CDa',
  MEMBERSHIPS_VIEW_ADDRESS: '0xeE1Cb9739854dDCc01166ECc11A43662cF1266Bb',
  ETERNAL_STORAGE_ADDRESS: '0x1D1523028C5627E1f909ad32144627Ae00609DDf',
}

const networkMumbai: NetworkConfig = {
  MEMBERSHIPS_ADDRESS: '0x8001367d10c039A04a5ebe1b5B499265234A4f0c',
  MEMBERSHIPS_IMPL_ADDRESS: '0x8001E2a8CC974F1419400e798B56E5C5e30BaF0a',
  MEMBERSHIPS_VIEW_ADDRESS: '0x8001c5EA4b7A80CC535C4019458457c79C7492f9',
  ETERNAL_STORAGE_ADDRESS: '0x800169B2C0317992BEaAdd430de48C6bA29EC411',
}

const networkGoerli: NetworkConfig = {
  MEMBERSHIPS_ADDRESS: '0x69a6a525d6d91Bb7228277ABb98e8Ba236844D87',
  MEMBERSHIPS_IMPL_ADDRESS: '0xF7c8079264296782C818488B317a91AC484f0c26',
  MEMBERSHIPS_VIEW_ADDRESS: '0x594f9fE8ba0A82cAe3c463D0787B5b857025063F',
  ETERNAL_STORAGE_ADDRESS: '0x8913dD23097f42846D562743532f94FB93062484',
}

const networkPolygon: NetworkConfig = {
  MEMBERSHIPS_ADDRESS: '0x8001754EFe34686a89dA85adD87ADfD28d47d9c8',
  MEMBERSHIPS_IMPL_ADDRESS: '0x8001E2a8CC974F1419400e798B56E5C5e30BaF0a',
  MEMBERSHIPS_VIEW_ADDRESS: '0x8001c5EA4b7A80CC535C4019458457c79C7492f9',
  ETERNAL_STORAGE_ADDRESS: '0x800169B2C0317992BEaAdd430de48C6bA29EC411',
}

const networkMainnet: NetworkConfig = {
  MEMBERSHIPS_ADDRESS: '0x4741B186F69C3D75E79207C322a42ee27Bb996fe',
  MEMBERSHIPS_IMPL_ADDRESS: '0xD17e6C409d162EeEaFfD9862A25D5e6CFb40bE4E',
  MEMBERSHIPS_VIEW_ADDRESS: '0x68DBd89334Ce186732626a7c89d58Cb804eDAA14',
  ETERNAL_STORAGE_ADDRESS: '0xE576bc26895369205cF6470033648Ad76965651c',
}

const networkFormTestnet: NetworkConfig = {
  MEMBERSHIPS_ADDRESS: '0x80010fAb863978B96327Da1A5d2503Af67F5c646',
  MEMBERSHIPS_IMPL_ADDRESS: '0x8001D19293423f10a466d5AFf16A9bd852477CC4',
  MEMBERSHIPS_VIEW_ADDRESS: '0x80017c356bc881292c1A2FCCf36c6AFAa9b28e88',
  ETERNAL_STORAGE_ADDRESS: '0x8001D5D89eE99c4Fc1EC61318E0079C685F64b1f',
}

export const config: Config = {
  FRONT_END_URL: window.location.origin,
  FORTMATIC_API_KEY: process.env.REACT_APP_FORTMATIC_API_KEY || '',
  PORTIS_DAPP_ID: process.env.REACT_APP_PORTIS_DAPP_ID || '',
  NETWORK_ROPSTEN: networkRopsten,
  NETWORK_MAINNET: networkMainnet,
  NETWORK_LOCAL: networkLocal,
  NETWORK_GOERLI: networkGoerli,
  NETWORK_MUMBAI: networkMumbai,
  NETWORK_POLYGON: networkPolygon,
  NETWORK_FORM_TESTNET: networkFormTestnet,
}
