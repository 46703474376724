import { BigNumber, ethers } from 'ethers'
import React, { useEffect, useMemo, useState } from 'react'
import {
  Body,
  SubHeader,
  useTheme,
  Caption,
  useEthAddress,
} from '@tryrolljs/design-system'
import clsx from 'clsx'
import { useAppDispatch } from '../../hooks'
import {
  useMaxLotsSelector,
  useMerkleTreeLeavesSelector,
  useNumberOfLotsSelector,
  usePhaseIdSelector,
} from '../../hooks/selectors/buy'
import { useCampaignByAddressSelector } from '../../hooks/selectors/campaign'
import { AmountToken } from '../../molecules/amountToken'
import { BuyBalance } from '../../molecules/buyBalance'
import { decreaseLots, increaseLots } from '../../state/buy/reducer'
import { InfoTooltip } from '../../molecules/infoTooltip'
import { useBuyPerWallet } from '../../hooks/phases'
import { allowlistValidation, checkIsPhasePublic } from '../../core/phases'

interface BuySectionProps {
  campaignId: string
}

export const BuySection: React.FC<BuySectionProps> = ({ campaignId }) => {
  const theme = useTheme()
  const userAddress = useEthAddress()
  const leaves = useMerkleTreeLeavesSelector()
  const campaign = useCampaignByAddressSelector(campaignId)
  const numberOfLots = useNumberOfLotsSelector()
  const maxLots = useMaxLotsSelector()
  const phaseId = usePhaseIdSelector()
  const { buyPerWallet } = useBuyPerWallet(phaseId)
  const dispatch = useAppDispatch()
  const [isPhaseDone, setPhaseDone] = useState(false)

  const phase = useMemo(() => {
    if (!phaseId) return null
    return campaign?.phasesData?.find((phase_) => phase_.phaseId === phaseId)
  }, [campaign, phaseId])

  useEffect(() => {
    if (!phase) return
    const now = Date.now()
    const endTime = (phase.start + phase.duration) * 1000
    const duration = endTime - now
    if (duration > 0) {
      const timeout = setTimeout(() => {
        setPhaseDone(true)
      }, duration)
      return () => clearTimeout(timeout)
    } else {
      setPhaseDone(true)
    }
  }, [phase])

  const tokenList = useMemo(() => {
    if (!phase) return []
    return phase.lotToken.map((addr, i) => ({
      addr,
      amount: phase.lotSize[i].mul(BigNumber.from(numberOfLots)),
    }))
  }, [phase, numberOfLots])

  const hasMaxBuyPerWallet = phase?.amountTotal !== phase?.maxBuyPerWallet
  const availableToBuy = (phase?.maxBuyPerWallet || 0) - buyPerWallet
  const availableLots = (phase?.amountTotal || 0) - (phase?.released || 0)
  const canAddMore =
    numberOfLots < maxLots &&
    numberOfLots < availableLots &&
    numberOfLots < availableToBuy
  const canDecreaseMore = numberOfLots > 1

  const totalToPay = phase
    ? phase.pricePerLot.mul(BigNumber.from(numberOfLots))
    : BigNumber.from(0)

  const pickColor = (condition: boolean) => {
    if (condition) return theme.text.highlight
    return theme.text.secondary
  }

  const errorMessage = useMemo(() => {
    if (!phase || !userAddress || !leaves) return
    if (checkIsPhasePublic(phase.merkleRoot)) return
    const validation = allowlistValidation({
      root: phase.merkleRoot,
      userAddress,
      leaves,
    })
    if (!validation?.isVerify)
      return 'You are not on the allowlist, check back during public phase'
  }, [phase, userAddress, leaves])

  const canBuyNumberOfLots = useMemo(() => {
    return (
      numberOfLots <= maxLots &&
      numberOfLots <= availableLots &&
      numberOfLots <= availableToBuy &&
      numberOfLots > 0
    )
  }, [numberOfLots, maxLots, availableLots, availableToBuy])

  const isDisabled =
    !availableLots || !!errorMessage || !canBuyNumberOfLots || isPhaseDone

  return (
    <div className="flex flex-col w-full mb-4 gap-4">
      <div className="flex flex-col gap-4">
        <div className="flex justify-between">
          <SubHeader weight="semiBold" color={theme.text.primary}>
            You get
          </SubHeader>
          <ul
            className={clsx(
              'flex items-end gap-1',
              tokenList.length > 1 && 'flex-col',
            )}
          >
            {tokenList.map((elem) => (
              <AmountToken
                key={elem.addr}
                bigTokenAmount={elem.amount}
                address={elem.addr}
              />
            ))}
            <SubHeader color={theme.text.primary}>
              + {tokenList.length > 1 ? 'Perks' : 'perks'}
            </SubHeader>
          </ul>
        </div>
        <div className="flex justify-between">
          <SubHeader weight="semiBold" color={theme.text.primary}>
            Total membership fee
          </SubHeader>
          <AmountToken
            bigTokenAmount={totalToPay}
            address={phase?.paymentAsset.token ?? ''}
          />
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2">
          <Body weight="semiBold" color={theme.text.secondary}>
            Total lots to claim
          </Body>
          <InfoTooltip title="The total amount of lots that you will claim." />
        </div>
        <div className="flex justify-between items-start">
          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              <div
                style={{
                  borderColor: pickColor(canDecreaseMore),
                  cursor: canDecreaseMore ? 'pointer' : 'not-allowed',
                }}
                className="flex justify-center items-center w-12 h-12 border rounded-2xl select-none"
                onClick={() => dispatch(decreaseLots())}
              >
                <SubHeader weight="bold" color={pickColor(canDecreaseMore)}>
                  {'-'}
                </SubHeader>
              </div>
              <div
                style={{
                  borderColor: theme.text.highlight,
                }}
                className="flex justify-center items-center w-20 h-12 border rounded-2xl"
              >
                <SubHeader weight="bold" color={theme.text.primary}>
                  {numberOfLots}
                </SubHeader>
              </div>
              <div
                style={{
                  borderColor: pickColor(canAddMore),
                  cursor: canAddMore ? 'pointer' : 'not-allowed',
                }}
                className="flex justify-center items-center w-12 h-12 border rounded-2xl select-none"
                onClick={() => canAddMore && dispatch(increaseLots())}
              >
                <SubHeader weight="bold" color={pickColor(canAddMore)}>
                  {'+'}
                </SubHeader>
              </div>
            </div>
            {hasMaxBuyPerWallet && (
              <Caption color={theme.text.secondary}>
                {availableToBuy} lots available to claim
              </Caption>
            )}
          </div>
          <BuyBalance
            isDisabled={isDisabled}
            tokenAddr={phase?.paymentAsset.token ?? ''}
            totalToPay={ethers.BigNumber.from(totalToPay)}
          />
        </div>
      </div>
      {!!errorMessage && (
        <div className="flex justify-center mt-4">
          <Caption color="#EB5757">{errorMessage}</Caption>
        </div>
      )}
    </div>
  )
}
