import React, { useMemo } from 'react'
import {
  Body,
  LargeHeader,
  margin,
  useTheme,
  CopyLink,
  Anchor,
} from '@tryrolljs/design-system'
import { CampaignDetailsTable } from '../campaignDetailsTable'
import { getAllTokensInCampaign } from '../../core/campaigns'
import { AmountToken } from '../../molecules/amountToken'
import { useCampaignByAddressSelector } from '../../hooks/selectors/campaign'
import { EtherscanType, useEtherscanLink } from '../../hooks/utils'
import { AddToWallet } from '../addToWallet'
import { useNetworkConfig } from '../../hooks/web3'

interface CampaignDetailsProps {
  campaignId: string
}

export const CampaignDetails: React.FC<CampaignDetailsProps> = ({
  campaignId,
}) => {
  const campaign = useCampaignByAddressSelector(campaignId)
  const networkConfig = useNetworkConfig()
  const getEtherscanLink = useEtherscanLink()
  const theme = useTheme()
  const allTokensList = useMemo(
    () => getAllTokensInCampaign(campaign),
    [campaign],
  )
  if (!campaign) return null
  return (
    <div>
      <div className="mb-6">
        <LargeHeader weight="bold" color={theme.text.primary}>
          Membership Details
        </LargeHeader>
      </div>
      <CampaignDetailsTable campaignId={campaignId} />
      <div className="flex flex-col gap-6 mt-10">
        <InfoItem
          title="Membership contract address"
          value={
            <div className="flex flex-col">
              <CopyLink url={networkConfig.MEMBERSHIPS_ADDRESS} />
              <Body style={margin.mt8} color={theme.text.highlight}>
                <Anchor
                  href={getEtherscanLink(
                    networkConfig.MEMBERSHIPS_ADDRESS,
                    EtherscanType.address,
                  )}
                  target="_blank"
                  fontSize={undefined}
                >
                  See on Etherscan
                </Anchor>
              </Body>
            </div>
          }
        />
        <div>
          <Body style={margin.mb4} color={theme.text.secondary}>
            Token address
          </Body>
          <div className="flex flex-col gap-3">
            {allTokensList.map((tokenAddr) => (
              <InfoItem
                key={tokenAddr}
                title=""
                value={
                  <div className="flex flex-col">
                    <CopyLink url={tokenAddr} />
                    <Body style={margin.mt8} color={theme.text.highlight}>
                      <Anchor
                        href={getEtherscanLink(tokenAddr, EtherscanType.token)}
                        target="_blank"
                        fontSize={undefined}
                      >
                        See on Etherscan
                      </Anchor>
                    </Body>
                  </div>
                }
              />
            ))}
          </div>
        </div>
        <InfoItem
          title="Token Symbol"
          value={
            <div className="flex flex-col gap-3">
              {allTokensList.map((tokenAddr) => (
                <div key={tokenAddr} className="flex items-center">
                  <AmountToken address={tokenAddr} />
                  <AddToWallet address={tokenAddr}>
                    <Body style={margin.ml16} color={theme.text.highlight}>
                      Add to Wallet
                    </Body>
                  </AddToWallet>
                </div>
              ))}
            </div>
          }
        />
        <InfoItem title="Token Type" value={<Body>ERC20</Body>} />
        <InfoItem
          title="Max Token Supply"
          value={
            <ul className="flex flex-col gap-3">
              {allTokensList.map((addr) => (
                <AmountToken
                  key={addr}
                  autoValue="totalSupply"
                  address={addr}
                />
              ))}
            </ul>
          }
        />
      </div>
    </div>
  )
}

const InfoItem = ({
  title,
  value,
}: {
  title?: string
  value: React.ReactNode
}) => {
  const theme = useTheme()

  return (
    <div className="flex flex-col">
      {title && (
        <Body style={margin.mb8} color={theme.text.secondary}>
          {title}
        </Body>
      )}
      {value}
    </div>
  )
}
