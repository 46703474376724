import ContentLoader from 'react-content-loader'

export const CardLoader = () => (
  <div className="border border-[#E6E6E6] rounded-3xl">
    <ContentLoader
      speed={2}
      width={1262}
      height={272}
      viewBox="0 0 1262 272"
      backgroundColor="#e6e6e6"
      foregroundColor="#d2d2d2"
    >
      <rect x="41" y="41" rx="17" ry="17" width="33" height="33" />
      <rect x="86" y="44" rx="9" ry="9" width="87" height="18" />
      <rect x="42" y="81" rx="6" ry="6" width="580" height="12" />
      <rect x="43" y="110" rx="6" ry="6" width="580" height="12" />
      <rect x="859" y="82" rx="9" ry="9" width="63" height="18" />
      <rect x="859" y="113" rx="5" ry="5" width="55" height="11" />
      <rect x="967" y="77" rx="0" ry="0" width="1" height="56" />
      <rect x="986" y="82" rx="9" ry="9" width="46" height="18" />
      <rect x="986" y="113" rx="5" ry="5" width="77" height="11" />
      <rect x="1094" y="77" rx="0" ry="0" width="1" height="56" />
      <rect x="1113" y="82" rx="9" ry="9" width="72" height="18" />
      <rect x="1113" y="113" rx="5" ry="5" width="60" height="11" />
      <rect x="107" y="211" rx="5" ry="5" width="14" height="11" />
      <rect x="137" y="211" rx="5" ry="5" width="14" height="11" />
      <rect x="167" y="211" rx="5" ry="5" width="14" height="11" />
      <rect x="197" y="211" rx="5" ry="5" width="14" height="11" />
      <rect x="42" y="211" rx="5" ry="5" width="49" height="11" />
      <rect x="1066" y="197" rx="7" ry="7" width="139" height="14" />
    </ContentLoader>
  </div>
)

export const DashboardCardLoader = () => (
  <div className="border border-[#E6E6E6] rounded-3xl">
    <ContentLoader
      speed={2}
      width={1274}
      height={382}
      viewBox="0 0 1274 382"
      backgroundColor="#e6e6e6"
      foregroundColor="#d2d2d2"
    >
      <rect x="42" y="42" rx="16" ry="16" width="31" height="31" />
      <rect x="85" y="44" rx="9" ry="9" width="54" height="18" />
      <rect x="41" y="81" rx="6" ry="6" width="580" height="12" />
      <rect x="41" y="99" rx="6" ry="6" width="580" height="12" />
      <rect x="871" y="82" rx="9" ry="9" width="63" height="18" />
      <rect x="871" y="113" rx="5" ry="5" width="55" height="11" />
      <rect x="979" y="77" rx="0" ry="0" width="1" height="56" />
      <rect x="998" y="82" rx="9" ry="9" width="46" height="18" />
      <rect x="998" y="113" rx="5" ry="5" width="77" height="11" />
      <rect x="1106" y="77" rx="0" ry="0" width="1" height="56" />
      <rect x="1125" y="82" rx="9" ry="9" width="35" height="18" />
      <rect x="1125" y="113" rx="5" ry="5" width="90" height="11" />
      <rect x="41" y="201" rx="5" ry="5" width="45" height="11" />
      <rect x="41" y="177" rx="5" ry="5" width="136" height="11" />
      <rect x="233" y="201" rx="5" ry="5" width="32" height="11" />
      <rect x="233" y="177" rx="5" ry="5" width="168" height="11" />
      <rect x="41" y="250" rx="7" ry="7" width="59" height="14" />
      <rect x="116" y="253" rx="5" ry="5" width="133" height="11" />
      <rect x="901" y="204" rx="14" ry="14" width="332" height="68" />
      <circle cx="1171" cy="231" r="8" />
      <path d="M 1170.98 226.199 l -0.07 0.216 v 6.273 l 0.07 0.063 l 2.91 -1.721 l -2.91 -4.831 z" />
      <path d="M 1170.98 226.199 l -2.91 4.831 l 2.91 1.721 v -6.552 z M 1170.98 233.302 l -0.04 0.044 v 2.234 l 0.04 0.105 l 2.91 -4.103 l -2.91 1.72 z" />
      <path d="M 1170.98 235.685 v -2.383 l -2.91 -1.72 l 2.91 4.103 z M 1170.98 232.751 l 2.91 -1.721 l -2.91 -1.323 v 3.044 z M 1168.07 231.03 l 2.91 1.721 v -3.044 l -2.91 1.323 z" />
      <rect x="1187" y="220" rx="7" ry="7" width="30" height="14" />
      <rect x="1186" y="242" rx="4" ry="4" width="31" height="9" />
      <rect x="917" y="222" rx="11" ry="11" width="28" height="21" />
      <rect x="901" y="177" rx="6" ry="6" width="148" height="12" />
      <rect x="106" y="321" rx="5" ry="5" width="14" height="11" />
      <rect x="136" y="321" rx="5" ry="5" width="14" height="11" />
      <rect x="166" y="321" rx="5" ry="5" width="14" height="11" />
      <rect x="196" y="321" rx="5" ry="5" width="14" height="11" />
      <rect x="41" y="321" rx="5" ry="5" width="49" height="11" />
      <rect x="1038" y="314" rx="6" ry="6" width="25" height="12" />
      <rect x="1103" y="313" rx="6" ry="6" width="118" height="12" />
    </ContentLoader>
  </div>
)

export const DetailsLoader = () => (
  <ContentLoader
    speed={2}
    width={846}
    height={546}
    viewBox="0 0 846 546"
    backgroundColor="#e6e6e6"
    foregroundColor="#d2d2d2"
  >
    <rect x="1" y="2" rx="12" ry="12" width="23" height="23" />
    <rect x="40" y="0" rx="9" ry="9" width="64" height="18" />
    <rect x="0" y="100" rx="5" ry="5" width="47" height="11" />
    <rect x="0" y="35" rx="6" ry="6" width="470" height="12" />
    <rect x="0" y="53" rx="6" ry="6" width="470" height="12" />
    <rect x="0" y="70" rx="6" ry="6" width="470" height="12" />
    <rect x="0" y="148" rx="7" ry="7" width="140" height="14" />
    <rect x="420" y="148" rx="7" ry="7" width="50" height="14" />
    <rect x="0" y="178" rx="5" ry="5" width="154" height="11" />
    <rect x="20" y="231" rx="4" ry="4" width="50" height="9" />
    <rect x="350" y="230" rx="5" ry="5" width="14" height="11" />
    <rect x="380" y="230" rx="5" ry="5" width="14" height="11" />
    <rect x="410" y="230" rx="5" ry="5" width="14" height="11" />
    <rect x="440" y="230" rx="5" ry="5" width="14" height="11" />
    <rect x="273" y="230" rx="5" ry="5" width="61" height="11" />
    <rect x="12" y="266" rx="5" ry="5" width="262" height="11" />
    <rect x="1" y="215" rx="8" ry="8" width="469" height="79" />
    <rect x="20" y="323" rx="4" ry="4" width="50" height="9" />
    <rect x="350" y="322" rx="5" ry="5" width="14" height="11" />
    <rect x="380" y="322" rx="5" ry="5" width="14" height="11" />
    <rect x="410" y="322" rx="5" ry="5" width="14" height="11" />
    <rect x="440" y="322" rx="5" ry="5" width="14" height="11" />
    <rect x="273" y="322" rx="5" ry="5" width="61" height="11" />
    <rect x="12" y="358" rx="5" ry="5" width="262" height="11" />
    <rect x="1" y="307" rx="8" ry="8" width="469" height="79" />
    <rect x="20" y="415" rx="4" ry="4" width="50" height="9" />
    <rect x="350" y="414" rx="5" ry="5" width="14" height="11" />
    <rect x="380" y="414" rx="5" ry="5" width="14" height="11" />
    <rect x="410" y="414" rx="5" ry="5" width="14" height="11" />
    <rect x="440" y="414" rx="5" ry="5" width="14" height="11" />
    <rect x="273" y="414" rx="5" ry="5" width="61" height="11" />
    <rect x="12" y="450" rx="5" ry="5" width="262" height="11" />
    <rect x="1" y="399" rx="8" ry="8" width="469" height="79" />
    <rect x="0" y="510" rx="5" ry="5" width="212" height="11" />
    <rect x="566" y="0" rx="9" ry="9" width="49" height="18" />
    <rect x="578" y="51" rx="7" ry="7" width="55" height="14" />
    <rect x="578" y="77" rx="6" ry="6" width="92" height="12" />
    <rect x="578" y="108" rx="6" ry="6" width="256" height="12" />
    <rect x="578" y="126" rx="6" ry="6" width="256" height="12" />
    <rect x="578" y="143" rx="6" ry="6" width="256" height="12" />
    <rect x="567" y="36" rx="8" ry="8" width="279" height="145" />
    <rect x="578" y="205" rx="7" ry="7" width="55" height="14" />
    <rect x="578" y="231" rx="6" ry="6" width="92" height="12" />
    <rect x="578" y="262" rx="6" ry="6" width="256" height="12" />
    <rect x="578" y="280" rx="6" ry="6" width="256" height="12" />
    <rect x="578" y="297" rx="6" ry="6" width="256" height="12" />
    <rect x="567" y="190" rx="8" ry="8" width="279" height="145" />
    <rect x="578" y="359" rx="7" ry="7" width="55" height="14" />
    <rect x="578" y="385" rx="6" ry="6" width="92" height="12" />
    <rect x="578" y="416" rx="6" ry="6" width="256" height="12" />
    <rect x="578" y="434" rx="6" ry="6" width="256" height="12" />
    <rect x="578" y="451" rx="6" ry="6" width="256" height="12" />
    <rect x="567" y="344" rx="8" ry="8" width="279" height="145" />
  </ContentLoader>
)

export const TokenTitleLoader = () => (
  <div>
    <ContentLoader
      speed={2}
      width={133}
      height={34}
      viewBox="0 0 133 34"
      backgroundColor="#e6e6e6"
      foregroundColor="#d2d2d2"
    >
      <rect x="1" y="1" rx="17" ry="17" width="33" height="33" />
      <rect x="46" y="8" rx="9" ry="9" width="87" height="18" />
    </ContentLoader>
  </div>
)

export const AmountTokenLoader = () => (
  <ContentLoader
    speed={2}
    width={82}
    height={14}
    viewBox="0 0 82 14"
    backgroundColor="#e6e6e6"
    foregroundColor="#d2d2d2"
  >
    <rect x="0" y="0" rx="7" ry="7" width="82" height="14" />
  </ContentLoader>
)

export const LotCardLoader = () => (
  <div className="border border-[#E6E6E6] rounded-3xl">
    <ContentLoader
      speed={2}
      width={470}
      height={80}
      viewBox="0 0 470 80"
      backgroundColor="#e6e6e6"
      foregroundColor="#d2d2d2"
    >
      <rect x="20" y="17" rx="4" ry="4" width="50" height="9" />
      <rect x="350" y="16" rx="5" ry="5" width="14" height="11" />
      <rect x="380" y="16" rx="5" ry="5" width="14" height="11" />
      <rect x="410" y="16" rx="5" ry="5" width="14" height="11" />
      <rect x="440" y="16" rx="5" ry="5" width="14" height="11" />
      <rect x="273" y="16" rx="5" ry="5" width="61" height="11" />
      <rect x="12" y="52" rx="5" ry="5" width="262" height="11" />
    </ContentLoader>
  </div>
)
