import { CHAIN_ID_MAIN_NET } from '@tryrolljs/design-system'
import { testTokens } from '../../contracts/constants'
import { Token } from '../../types'
import { COINGECKO_TOKEN_LIST_URL, ROLL_TOKEN_LIST_URL } from './constants'

const getTokenList = async (url: string) => {
  const resp = await fetch(url)
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  const tokenList = await resp.json()
  const tokens = tokenList.tokens as Token[]
  return tokens
}

export const getTokens = async (chainId: number) => {
  const response: { data: Token[]; error: string } = { data: [], error: '' }
  try {
    if (chainId === CHAIN_ID_MAIN_NET) {
      const rollTokens = await getTokenList(ROLL_TOKEN_LIST_URL)
      const coinGeckoTokens = await getTokenList(COINGECKO_TOKEN_LIST_URL)
      const tokens = coinGeckoTokens.concat(rollTokens)
      response.data = tokens
      return response
    }
    response.data = testTokens(chainId)
    return response
  } catch (error) {
    response.error = (error as Error).message
    return response
  }
}
