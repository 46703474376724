import { formatBytes32String } from 'ethers/lib/utils'
import { AddressZero } from '@ethersproject/constants'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useChainID, useEthAddress } from '@tryrolljs/design-system'
import { ethers } from 'ethers'
import { useAppDispatch, useWithAnyAsync } from '..'
import {
  createCampaign,
  CreateCampaignProps,
  getCampaignById,
  getCampaignByIndex,
  getCampaignByOwner,
  getCampaignsByIndex,
  getCampaignsByReferral,
  getCampaignsLength,
  getMinRollFee,
  getNumberOfClaimedWallets,
  updateMetadata,
} from '../../contracts/campaigns'
import {
  actionSaveCampaign,
  actionSaveCampaigns,
  updateCampaingLength,
} from '../../state/campaigns/reducer'
import {
  Campaign,
  CreateMintingScheduleParamsStruct,
  MerkleTrees,
} from '../../types'
import { range } from '../../utils'
import { dateToSeconds } from '../../utils/dates'
import {
  useCreateNotifications,
  useUpdateMetadataNotifications,
} from '../notifications'
import {
  useAllCampaignsSelector,
  useCampaignByAddressSelector,
  useCampaignsTotalLengthSelector,
} from '../selectors/campaign'
import {
  useBackToStep,
  useFormValuesState,
  useSetMinRollFee,
} from '../selectors/create'
import { useFetchToken, useFetchTokens } from '../tokens'
import { useNavigateDashboard } from '../../navigation/actions'
import { blacklistMemberships, FEE_SCALE } from '../../contracts/constants'
import { ErrorCode } from '../../contracts/errors'
import { CreateStepsId } from '../../types/create'
import { formatMetadata } from '../../core/phases'
import { pinJSONToIPFS } from '../../utils/pinata'
import { useContractPool } from '../../providers/contracts'

export const useCampaignsLength = (defer: boolean = false) => {
  const { membershipsViewFactory } = useContractPool()
  const length = useCampaignsTotalLengthSelector()
  const dispatch = useAppDispatch()
  const { execute, status, error, isLoading } = useWithAnyAsync()

  const managedExec = useCallback(async () => {
    if (typeof length !== 'number' && membershipsViewFactory) {
      const val = await execute(() =>
        getCampaignsLength(membershipsViewFactory),
      )
      if (!val) return
      dispatch(updateCampaingLength(val))
      return val
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [length])

  useEffect(() => {
    if (typeof length !== 'number' && !defer) {
      managedExec()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [length])

  return { status, error, length, isLoading, managedExec }
}

export const useAllCampaigns = (size: number = 10) => {
  const { membershipsViewFactory } = useContractPool()
  const campaignsLength = useCampaignsTotalLengthSelector()
  const campaigns = useAllCampaignsSelector()
  const [hasMore, setHasMore] = useState<boolean>(false)
  const [cursor, setCursor] = useState<number>(-1)
  const dispatch = useAppDispatch()
  const isBlacklisted = useIsBlackListed()
  const { status, error, execute, isLoading } = useWithAnyAsync()

  const fetchMore = useCallback(async () => {
    if (!membershipsViewFactory) return
    let lastIndex = cursor + size
    let newHasMore = true
    const data = await execute(async () => {
      const length =
        campaignsLength ?? (await getCampaignsLength(membershipsViewFactory))
      if (typeof length === 'number' && length !== campaignsLength) {
        dispatch(updateCampaingLength(length))
      }
      if (length && cursor < length - 1) {
        if (lastIndex > length - 1) {
          lastIndex = length - 1
          newHasMore = false
        }
        const memberships = await getCampaignsByIndex({
          indexes: range(cursor + 1, lastIndex),
          contract: membershipsViewFactory,
        })
        return memberships.filter(
          (membership) => !isBlacklisted(membership.campaignId),
        )
      }
    })
    if (data) {
      setCursor(lastIndex)
      setHasMore(newHasMore)
      dispatch(actionSaveCampaigns(data))
    }
  }, [
    cursor,
    membershipsViewFactory,
    campaignsLength,
    execute,
    dispatch,
    size,
    isBlacklisted,
  ])

  useEffect(() => {
    fetchMore()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membershipsViewFactory])

  return { campaigns, status, error, length, fetchMore, hasMore, isLoading }
}

export const useAllCampaignsByOwner = (defer: boolean = false) => {
  const { membershipsViewFactory } = useContractPool()
  const userAddr = useEthAddress()
  const [value, setValue] = useState<Campaign[] | null>(null)
  const dispatch = useAppDispatch()
  const { execute, status, error, isLoading } = useWithAnyAsync()

  const managedExec = useCallback(async () => {
    if (membershipsViewFactory && userAddr) {
      const data = await execute(() =>
        getCampaignByOwner({
          ownerAddr: userAddr,
          contract: membershipsViewFactory,
        }),
      )
      if (data) {
        setValue(data)
        dispatch(actionSaveCampaigns(data))
      }
    }
  }, [membershipsViewFactory, userAddr, dispatch, execute])

  useEffect(() => {
    if (!defer) {
      managedExec()
    }
  }, [defer, membershipsViewFactory, managedExec])

  return { status, error, campaigns: value, isLoading, managedExec }
}

export const useAllCampaignsByReferral = (defer: boolean = false) => {
  const { membershipsViewFactory } = useContractPool()
  const userAddr = useEthAddress()
  const [value, setValue] = useState<Campaign[] | null>(null)
  const dispatch = useAppDispatch()
  const { execute, status, error, isLoading } = useWithAnyAsync()

  const managedExec = useCallback(async () => {
    if (membershipsViewFactory && userAddr) {
      const data = await execute(() =>
        getCampaignsByReferral({
          referralAddr: userAddr,
          contract: membershipsViewFactory,
        }),
      )
      if (data) {
        setValue(data)
        dispatch(actionSaveCampaigns(data))
      }
    }
  }, [membershipsViewFactory, userAddr, dispatch, execute])

  useEffect(() => {
    if (!defer) {
      managedExec()
    }
  }, [defer, membershipsViewFactory, managedExec])

  return { status, error, campaigns: value, isLoading, managedExec }
}

export const useCampaignByIndex = ({
  index,
  defer,
}: {
  index: number
  defer?: boolean
}) => {
  const { membershipsViewFactory } = useContractPool()
  const [value, setValue] = useState<Campaign | null>()
  const { execute, status, error, isLoading } = useWithAnyAsync()
  const dispatch = useAppDispatch()

  const managedExec = useCallback(async () => {
    if (!value && membershipsViewFactory) {
      const data = await execute(() =>
        getCampaignByIndex({ index, contract: membershipsViewFactory }),
      )
      if (data) {
        setValue(data)
        dispatch(actionSaveCampaign(data))
      }
    }
  }, [value, membershipsViewFactory, index, dispatch, setValue, execute])

  useEffect(() => {
    if (!defer) {
      managedExec()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defer, membershipsViewFactory, index])

  return { status, error, campaign: value, isLoading, managedExec }
}

export const useCampaignById = ({
  id,
  defer,
}: {
  id: string
  defer?: boolean
}) => {
  const { membershipsViewFactory } = useContractPool()
  const campaign = useCampaignByAddressSelector(id)
  const isBlacklisted = useIsBlackListed()
  const { execute, status, error, isLoading } = useWithAnyAsync()
  const dispatch = useAppDispatch()

  const managedExec = useCallback(async () => {
    if (!campaign && membershipsViewFactory) {
      const data = await execute(() =>
        getCampaignById({ phaseAddr: id, contract: membershipsViewFactory }),
      )
      if (data && !isBlacklisted(data.campaignId)) {
        dispatch(actionSaveCampaign(data))
      }
    }
  }, [campaign, membershipsViewFactory, id, dispatch, execute, isBlacklisted])

  useEffect(() => {
    if (!defer) {
      managedExec()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defer, membershipsViewFactory, id])

  return { status, error, campaign, isLoading, managedExec }
}

export const useTotalClaimedWallets = (
  phases: string[],
  defer: boolean = false,
) => {
  const { membershipsViewFactory } = useContractPool()
  const { execute, isLoading } = useWithAnyAsync()
  const [totalClaimedWallets, setTotalClaimedWallets] = useState<string>()

  const getNumberClaims = useCallback(async () => {
    if (!membershipsViewFactory) return
    const response = await execute(() =>
      getNumberOfClaimedWallets(phases, membershipsViewFactory),
    )
    if (!response) return
    setTotalClaimedWallets(response.toString())
  }, [membershipsViewFactory, setTotalClaimedWallets, execute, phases])

  useEffect(() => {
    if (!defer) {
      getNumberClaims()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membershipsViewFactory, defer])

  return useMemo(
    () => ({
      getNumberClaims,
      totalClaimedWallets,
      isLoading,
    }),
    [totalClaimedWallets, isLoading, getNumberClaims],
  )
}

export const useUpdateCampaign = () => {
  const dispatch = useAppDispatch()
  const { membershipsViewFactory } = useContractPool()

  const updateCampaign = useCallback(
    async (id: string) => {
      if (!membershipsViewFactory) return null
      const data = await getCampaignById({
        phaseAddr: id,
        contract: membershipsViewFactory,
      })
      if (!data) return null
      dispatch(actionSaveCampaign(data))
    },
    [dispatch, membershipsViewFactory],
  )

  return updateCampaign
}

export const useCreateMembership = () => {
  const { membershipsFactory } = useContractPool()
  const formValues = useFormValuesState()
  const usedTokens = formValues.lotInfo.map((lot) => lot.lotToken)
  const { tokens, isLoading: isLoadingTokens } = useFetchTokens(usedTokens)
  const { token: paymentToken, isLoading: isLoadingPaymentToken } =
    useFetchToken(formValues.paymentAsset || '')
  const { isLoading: isLoadingCreate, execute } = useWithAnyAsync()
  const navigateDashboard = useNavigateDashboard()
  const backToStep = useBackToStep()
  const createNotifications = useCreateNotifications()

  const createMembershipImpl = useCallback(
    async (value: Omit<CreateCampaignProps, 'contract'>) => {
      if (!membershipsFactory) return
      const response = await execute(() =>
        createCampaign({
          ...value,
          ...createNotifications,
          contract: membershipsFactory,
        }),
      )
      if (response?.success) {
        navigateDashboard()
      }
      if (response?.errorCode) {
        if (response.errorCode === ErrorCode.ErrorME19NotEnoughEth) {
          backToStep(CreateStepsId.lots)
        }
        backToStep(CreateStepsId.phases)
      }
    },
    [
      createNotifications,
      membershipsFactory,
      backToStep,
      execute,
      navigateDashboard,
    ],
  )

  const createMembership = useCallback(() => {
    if (!tokens.length || !paymentToken) return
    const lotSize = tokens.map((token, i) =>
      ethers.utils.parseUnits(formValues.lotInfo[i].lotSize, token.decimals),
    )
    const lotTokens = tokens.map((token) => token.address)
    const phases: CreateMintingScheduleParamsStruct[] =
      formValues.schedules.map((schedule) => {
        const startDateTime = `${schedule.startDate}T${schedule.startTime}`
        const endDateTime = `${schedule.endDate}T${schedule.endTime}`
        const start = dateToSeconds(new Date(startDateTime))
        const end = dateToSeconds(new Date(endDateTime))
        const duration = end - start
        return {
          start,
          duration,
          pricePerLot: ethers.utils.parseUnits(
            schedule.pricePerLot,
            paymentToken.decimals,
          ),
          amountTotal: schedule.amountTotal,
          maxBuyPerWallet: schedule.maxBuyPerWallet || schedule.amountTotal,
          lotToken: lotTokens,
          lotSize,
          paymentAsset: {
            assetType:
              !paymentToken || paymentToken.address === AddressZero ? 0 : 1,
            token: paymentToken.address,
          },
          merkleRoot: schedule.merkleRoot || formatBytes32String(''),
          rollFee: formValues.fees.rollFee
            ? Math.round((formValues.fees.rollFee / 100) * FEE_SCALE)
            : 0,
          referralFee: formValues.fees.referralFee
            ? Math.round((formValues.fees.referralFee / 100) * FEE_SCALE)
            : 0,
          referral: formValues.fees.referral || AddressZero,
        }
      })
    createMembershipImpl({
      phases,
      metadata: JSON.stringify(formValues.metadata),
    })
  }, [formValues, createMembershipImpl, tokens, paymentToken])

  const isLoading = isLoadingCreate || isLoadingTokens || isLoadingPaymentToken

  return { isLoading, createMembership }
}

export const useUpdateMerkletreeHash = (campaignId: string) => {
  const { membershipsFactory } = useContractPool()
  const { isLoading: isUpdatingMetadata, execute } = useWithAnyAsync()
  const { campaign, isLoading: isLoadingCampaign } = useCampaignById({
    id: campaignId,
  })
  const metadata = useMemo(
    () => formatMetadata(campaign?.metadata ?? ''),
    [campaign],
  )
  const notifications = useUpdateMetadataNotifications()

  const updateMetadata_ = useCallback(
    async (root: string, list: string[], allTrees: MerkleTrees) => {
      if (!membershipsFactory) return
      const hash = await pinJSONToIPFS({
        merkleTrees: { ...allTrees, [root]: list },
      })
      if (!hash) return
      const savedMetadata = JSON.stringify({
        ...metadata,
        merkleTreesHash: hash,
      })
      const response = await execute(() =>
        updateMetadata({
          contract: membershipsFactory,
          campaignId,
          metadata: savedMetadata,
          ...notifications,
        }),
      )
      return response
    },
    [membershipsFactory, metadata, campaignId, execute, notifications],
  )

  const isLoading = isUpdatingMetadata || isLoadingCampaign

  return { isLoading, updateMetadata: updateMetadata_ }
}

export const useFetchMinRollFee = () => {
  const { membershipsFactory } = useContractPool()
  const { execute } = useWithAnyAsync()
  const setMinRollFee = useSetMinRollFee()

  const fetchMinRollFee = useCallback(async () => {
    if (!membershipsFactory) return
    const response = await execute(() => getMinRollFee(membershipsFactory))
    if (typeof response !== 'number') return
    setMinRollFee(response)
  }, [membershipsFactory, execute, setMinRollFee])

  return fetchMinRollFee
}

export const useIsBlackListed = () => {
  const chainId = useChainID()
  const isBlackListed = useCallback(
    (id: string) => {
      if (!chainId) return false
      const blacklist = blacklistMemberships[chainId]
      if (!blacklist) return false
      if (blacklist.includes(id.toLowerCase())) return true
      return false
    },
    [chainId],
  )
  return isBlackListed
}
