import { Caption, useTheme } from '@tryrolljs/design-system'
import { BigNumber } from 'ethers'
import { useMemo } from 'react'
import { getWeiBasedOnToken } from '../../core/tokens'
import { useTokenBalanceUser } from '../../hooks/tokens'
import { Token } from '../../types'

export enum BalanceLabelVariant {
  JustBalance = 'justBalance',
  JustWarning = 'justWarning',
  BalanceAndWarning = 'balanceAndWarning',
}

interface BalanceLabelProps {
  token: Token
  wei?: BigNumber
  variants?: BalanceLabelVariant
  amount?: BigNumber | number
}

export const BalanceLabel: React.FC<BalanceLabelProps> = ({
  token,
  wei,
  amount,
  variants = BalanceLabelVariant.JustBalance,
}) => {
  const theme = useTheme()
  const { isLoadingBalance, balance, formatBalance } =
    useTokenBalanceUser(token)
  const bigNumberAmount = useMemo(() => {
    return wei || getWeiBasedOnToken(amount || 0, token.decimals)
  }, [amount, token, wei])

  const hasBalance = useMemo(
    () => balance.gte(bigNumberAmount),
    [balance, bigNumberAmount],
  )
  const balanceColor =
    hasBalance || variants === BalanceLabelVariant.JustBalance
      ? theme.text.secondary
      : theme.text.error

  const text = useMemo(() => {
    const tokenFormat = `${formatBalance} ${token.symbol}`
    if (variants === BalanceLabelVariant.JustBalance)
      return `Balance: ${tokenFormat}`
    if (variants === BalanceLabelVariant.JustWarning) {
      return hasBalance ? '' : 'Not enough balance'
    }
    return `${hasBalance ? 'Balance' : 'Not enough balance'}: ${tokenFormat}`
  }, [hasBalance, formatBalance, token, variants])

  if (isLoadingBalance) return null

  return (
    <div className="flex justify-end">
      <Caption color={balanceColor}>{text}</Caption>
    </div>
  )
}
