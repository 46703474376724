import { BigNumber } from 'ethers'
import { Memberships } from '@roll-network/contract-bindings'
import {
  contractCampaign,
  CreateMintingScheduleParamsStruct,
  TransactionCBs,
} from '../types'
import { formatCampaign } from '../core/campaigns'
import { validateCreate } from '../core/create'
import { getContractError } from './errors'
import { getPhasesById } from './phases'
import { FEE_SCALE } from './constants'

export const getCampaignByIndex = async ({
  index,
  contract,
}: {
  index: number
  contract: Memberships.MembershipsView
}) => {
  const campaign = await contract.getCampaign(index)
  const phases = await getPhasesById({ ids: campaign.phases, contract })
  return formatCampaign(campaign, phases)
}

export const getCampaignByPhase = async ({
  phaseAddr,
  contract,
}: {
  phaseAddr: string
  contract: Memberships.MembershipsView
}) => {
  const campaign = await contract.getCampaignBySchedule(phaseAddr)
  return {
    campaignId: campaign.campaignId,
    campaignIndex: campaign.campaignIndex.toNumber(),
  }
}

export const getCampaignById = async ({
  phaseAddr,
  contract,
}: {
  phaseAddr: string
  contract: Memberships.MembershipsView
}) => {
  const campaignInfo = await getCampaignByPhase({ phaseAddr, contract })
  if (!campaignInfo) return null
  const campaignData = await getCampaignByIndex({
    index: campaignInfo.campaignIndex,
    contract,
  })
  return campaignData
}

export const getCampaignByOwner = async ({
  ownerAddr,
  contract,
}: {
  ownerAddr: string
  contract: Memberships.MembershipsView
}) => {
  const [length, campaigns] = await contract.getCampaignByOwner(ownerAddr)
  const filteredCampaigns = campaigns.slice(0, length.toNumber())
  const phases = await Promise.all(
    filteredCampaigns.map((elem) =>
      getPhasesById({ ids: elem.phases, contract }),
    ),
  )
  return filteredCampaigns.map((campaign, i) =>
    formatCampaign(campaign, phases[i]),
  )
}

export const getCampaignsByReferral = async ({
  referralAddr,
  contract,
}: {
  referralAddr: string
  contract: Memberships.MembershipsView
}) => {
  const [length, campaigns] = await contract.getCampaignByReferral(referralAddr)
  const campaigns_ = campaigns.slice(0, length.toNumber())
  const campaignById: Record<string, contractCampaign> = campaigns_.reduce(
    (acc, campaign) => ({ ...acc, [campaign.campaignId]: campaign }),
    {},
  )
  const uniqueCampaigns = Object.values(campaignById)

  const phases = await Promise.all(
    uniqueCampaigns.map((campaign) =>
      getPhasesById({ ids: campaign.phases, contract }),
    ),
  )
  return uniqueCampaigns.map((campaign, i) =>
    formatCampaign(campaign, phases[i]),
  )
}

export const getCampaignsByIndex = async ({
  indexes,
  contract,
}: {
  indexes: number[]
  contract: Memberships.MembershipsView
}) => {
  const campaigns = await Promise.all(
    indexes.map((i) => contract.getCampaign(i)),
  )
  const phases = await Promise.all(
    campaigns.map((elem) => getPhasesById({ ids: elem.phases, contract })),
  )
  return campaigns.map((campaign, i) => formatCampaign(campaign, phases[i]))
}

export const getCampaignsLength = async (
  contract: Memberships.MembershipsView,
) => {
  const length = await contract.getCampaignsLength()
  return length.toNumber()
}

export const getNumberOfClaimedWallets = async (
  phasesId: string[],
  contract: Memberships.MembershipsView,
) => {
  const countResponse = await Promise.all(
    phasesId.map((id) => contract.getBuyWalletCount(id)),
  )
  const total = countResponse.reduce(
    (accum, curr) => accum.add(curr),
    BigNumber.from(0),
  )
  return total
}

export const getMinRollFee = async (contract: Memberships.Memberships) => {
  const response = await contract.getMinRollFee()
  return (response.toNumber() / FEE_SCALE) * 100
}

export interface CreateCampaignProps {
  phases: CreateMintingScheduleParamsStruct[]
  metadata: string
  contract: Memberships.Memberships
}

export const createCampaign = async ({
  phases,
  metadata,
  contract,
  ...cb
}: CreateCampaignProps & TransactionCBs) => {
  try {
    validateCreate(phases)
    const createTx = await contract.createCampaign(phases, metadata)
    cb.onSubmit(createTx.hash)
    await createTx.wait()
    cb.onSuccess()
    return { success: true }
  } catch (error) {
    const contractError = getContractError(error)
    cb.onError(contractError.message)
    return {
      success: false,
      errorCode: contractError.code || contractError.message,
    }
  }
}

interface UpdateCampaignMetadataProps {
  contract: Memberships.Memberships
  metadata: string
  campaignId: string
}

export const updateMetadata = async ({
  contract,
  metadata,
  campaignId,
  ...cb
}: UpdateCampaignMetadataProps & TransactionCBs) => {
  try {
    const tx = await contract.updateCampaignMetadata(campaignId, metadata)
    cb.onSubmit(tx.hash)
    await tx.wait()
    cb.onSuccess()
    return { success: true }
  } catch (error) {
    const contractError = getContractError(error)
    cb.onError(contractError.message)
    return {
      success: false,
      errorCode: contractError.code || contractError.message,
    }
  }
}
