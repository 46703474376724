import { endOfYear, format, startOfToday } from 'date-fns'
import React, { useCallback, useMemo, useState } from 'react'
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import {
  Body,
  Button,
  Caption,
  LargeHeader,
  useModal,
  useTheme,
} from '@tryrolljs/design-system'
import { BigNumber, ethers } from 'ethers'
import {
  useCompleteStep,
  useFormValuesState,
  useUpdateFormState,
} from '../../hooks/selectors/create'
import ActiveStatus from '../../assets/svg/phase-progress-active.svg'
import { CreateStepHeading } from '../../molecules/createStepHeading'
import { InfoMathToken } from '../../molecules/infoMathToken'
import { InputContainer } from '../../molecules/inputContainer'
import { RemoveButtonIcon } from '../../molecules/removeButtonIcon'
import { TokenSelect } from '../../molecules/tokenSelect'
import { AllowlistInput, CreateFormInputState } from '../../types'
import { CreateStepsId } from '../../types/create'
import {
  DEFAULT_DATE_FORMAT,
  fullStartEndDatePhase,
  dateToSeconds,
} from '../../utils/dates'
import AddIcon from '../../assets/svg/add.svg'
import { Toggle } from '../../molecules/toggle'
import ClipboardTextIcon from '../../assets/svg/clipboard-text.svg'
import { useFetchToken } from '../../hooks/tokens'
import { getDecimalLength } from '../../utils'
import {
  useGetGeneratedFee,
  useGetTotalRewardPerToken,
} from '../../hooks/create'
import { CollapsePhaseView } from '../../molecules/collapsePhaseView'
import { BalanceLabelVariant } from '../../molecules/balanceLabel'
import { ConfirmationDeleteModal } from '../../molecules/confirmationDeleteModal'
import { AllowlistModal } from '../allowlistModal'

const dataInfo = {
  phases: {
    title: 'Phases',
    description:
      'Phases are the time periods that people can claim your lots and become members.\nChoose the amount of lots, the lot price and time period of each phase below.\nSelect Allowlist to curate who can become a member.',
  },
  fees: {
    title: 'Fees Generated',
    description:
      'The total amount of tokens you will use and the estimated total fees generated through Roll Memberships, calculated automatically.',
  },
}

const defaultSchedule = (): CreateFormInputState['schedules'][number] => ({
  startDate: '',
  startTime: '',
  endDate: '',
  endTime: '',
  hasMaxBuyPerWallet: false,
  pricePerLot: '0',
  amountTotal: 0,
})

export const CreatePhaseStep: React.FC = () => {
  const theme = useTheme()
  const defaultValues = useFormValuesState()
  const [activePhase, setActivePhase] = useState<number>(0)
  const methods = useForm<CreateFormInputState>({
    defaultValues,
    mode: 'onChange',
  })
  const completeStep = useCompleteStep()

  const { updateSchedules, updatePaymentAsset } = useUpdateFormState()

  const onSubmit = () => {
    methods.handleSubmit((data) => {
      updateSchedules(data.schedules)
      updatePaymentAsset(data.paymentAsset)
      completeStep(CreateStepsId.phases)
    })()
  }
  const { fields, append, remove } = useFieldArray({
    name: 'schedules',
    control: methods.control,
  })

  const onRemovePhase = useCallback(
    (i: number) => {
      remove(i)
    },
    [remove],
  )

  const onAddPhase = useCallback(async () => {
    const response = await methods.trigger('schedules')
    if (response) {
      append(defaultSchedule())
      setActivePhase(fields.length)
    }
  }, [append, fields, methods])

  const onSetActivePhase = useCallback(
    async (index: number) => {
      const response = await methods.trigger(`schedules.${activePhase}`)
      if (!response) return
      setActivePhase(index)
    },
    [methods, setActivePhase, activePhase],
  )

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-8">
        <CreateStepHeading
          title={dataInfo.phases.title}
          description={dataInfo.phases.description}
        />
        <Controller
          name="paymentAsset"
          control={methods.control}
          rules={{
            required: {
              value: true,
              message: 'This field is required',
            },
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div className="w-full relative flex flex-col gap-2">
              <Body weight="bold" color={theme.text.primary}>
                Token to use for fees
              </Body>
              <div className="w-fit">
                <TokenSelect
                  value={value}
                  onSelect={(token) => onChange(token.address)}
                  isPaymentToken
                />
              </div>
              {error?.message && (
                <div className="absolute left-0 -bottom-6">
                  <Caption color={theme.text.error}>{error?.message}</Caption>
                </div>
              )}
            </div>
          )}
        />
        <div className="flex flex-col gap-4">
          {fields.map((field, i) => (
            <PhaseContainer
              isActive={i === activePhase}
              key={field.id}
              index={i}
              remove={onRemovePhase}
              setActivePhase={onSetActivePhase}
            />
          ))}
        </div>
        <div className="w-fit">
          <Button variant="secondary" onPress={onAddPhase}>
            <div className="flex items-center gap-2">
              <AddIcon />
              <Body weight="regular" color={theme.text.primary}>
                Add a phase
              </Body>
            </div>
          </Button>
        </div>
        <hr className="border-[#EAEEF3]" />
        <FeesGeneratedSection />
        <div className="w-fit">
          <Button
            variant="primary"
            disabled={!fields.length}
            title="Continue"
            onPress={onSubmit}
          />
        </div>
      </div>
    </FormProvider>
  )
}

const PhaseForm: React.FC<{
  index: number
  remove: () => void
}> = ({ index, remove }) => {
  const theme = useTheme()
  const defaultValues = useFormValuesState()
  const {
    register,
    watch,
    formState: { errors },
    control,
    setValue,
  } = useFormContext<CreateFormInputState>()
  const phaseErrors = errors.schedules?.[index]
  const {
    isOpen: isOpenDeleteModal,
    close: closeDeleteModal,
    open: openDeleteModal,
  } = useModal()
  const {
    isOpen: isOpenAllowlistModal,
    close: closeAllowlistModal,
    open: openAllowlistModal,
  } = useModal()
  const allowlistValues = useWatch({
    control,
    name: `schedules.${index}.allowlist`,
  })
  const paymentAsset = useWatch({
    control,
    name: 'paymentAsset',
  })
  const hasMaxBuy = useWatch({
    control,
    name: `schedules.${index}.hasMaxBuyPerWallet`,
  })
  const { token: paymentToken } = useFetchToken(paymentAsset)

  const minLotsRequired = useMemo(() => {
    if (!defaultValues.metadata?.tiers?.length) return 0
    const lotsRequired = defaultValues.metadata.tiers.map((tier) => tier.lots)
    return Math.max(...lotsRequired) || 0
  }, [defaultValues])

  const handleToggleClick = useCallback(() => {
    setValue(`schedules.${index}.hasMaxBuyPerWallet`, !hasMaxBuy)
    setValue(`schedules.${index}.maxBuyPerWallet`, undefined)
  }, [index, setValue, hasMaxBuy])
  const handleSaveAllowlistValue = useCallback(
    (value: AllowlistInput) => setValue(`schedules.${index}.allowlist`, value),
    [setValue, index],
  )

  return (
    <>
      <div className="flex flex-col gap-6 px-6 py-4 border border-[#EAEEF3] rounded-2xl">
        <div className="flex justify-between items-center">
          <div className="flex gap-4">
            <LargeHeader weight="bold" color={theme.text.highlight}>
              #{index + 1}
            </LargeHeader>
            <RemoveButtonIcon onPress={openDeleteModal} />
          </div>
          <div className="relative">
            <Button variant="secondary" onPress={openAllowlistModal}>
              <div className="flex items-center gap-2">
                <ClipboardTextIcon />
                <Body color={theme.text.primary}>Allowlist</Body>
              </div>
            </Button>
            {allowlistValues?.isActive && (
              <div className="absolute right-0 -top-2">
                <ActiveStatus />
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <LargeHeader weight="bold" color={theme.text.primary}>
            Details
          </LargeHeader>
          <div className="flex gap-6">
            <div className="flex flex-col gap-6 w-full">
              <InputContainer
                label="Amount of lots"
                className={{ container: 'w-full' }}
                tooltip="The amount of lots in each time period. Each lot has the amount of tokens you chose in the Lots section."
                error={phaseErrors?.amountTotal?.message}
              >
                <input
                  type="number"
                  className="w-full"
                  {...register(`schedules.${index}.amountTotal`, {
                    valueAsNumber: true,
                    validate: {
                      limit: (v) =>
                        v >= minLotsRequired ||
                        "Amount of lots can't be lower than the max tier lot required",
                      fullNumber: (v) => v % 1 === 0 || 'Please enter a number',
                    },
                    deps: [`schedules.${index}.maxBuyPerWallet`],
                    min: {
                      value: 1,
                      message: 'Value most be greater than 1',
                    },
                    required: 'This field is required',
                  })}
                />
              </InputContainer>
              <div className="relative">
                <div className="absolute right-0 top-0.5">
                  <Toggle isActive={hasMaxBuy} onClick={handleToggleClick} />
                </div>
                <InputContainer
                  label="Max Lots Per Wallet"
                  className={{ container: 'w-full' }}
                  tooltip="Limit the amount of lots a wallet can claim."
                  isDisabled={!hasMaxBuy}
                  error={phaseErrors?.maxBuyPerWallet?.message}
                >
                  <input
                    type="number"
                    className="w-full"
                    placeholder="E.g 3"
                    {...register(`schedules.${index}.maxBuyPerWallet`, {
                      valueAsNumber: true,
                      disabled: !hasMaxBuy,
                      validate: {
                        limit: (v) =>
                          (v || 0) >= minLotsRequired ||
                          "Limit can't be lower than the max tier lot required",
                        limitByAmount: (v) =>
                          (v || 0) <=
                            (watch(`schedules.${index}.amountTotal`) || 0) ||
                          "Limit can't be higher than the Amount of lots",
                        fullNumber: (v) =>
                          (v || 0) % 1 === 0 || 'Please enter a number',
                      },
                      min: {
                        value: 1,
                        message: 'This value should be greter than 1',
                      },
                      required: 'This field is required',
                    })}
                  />
                </InputContainer>
              </div>
            </div>
            <div className="w-full">
              <InputContainer
                label="Lot Price"
                tooltip="The price users will pay for each lot. You can choose a token like ETH, USDC or any ERC20 as payment method."
                error={phaseErrors?.pricePerLot?.message}
                isDisabled={!paymentToken}
              >
                <div className="flex gap-2 w-full">
                  <input
                    type="number"
                    className="w-full"
                    disabled={!paymentToken}
                    {...register(`schedules.${index}.pricePerLot`, {
                      validate: {
                        positive: (value) =>
                          Number(value) > 0 || 'Value most be greater than 0',
                        lengthDecimals: (value) =>
                          getDecimalLength(value) <=
                            (paymentToken?.decimals || 0) ||
                          `Value can have a max of ${
                            paymentToken?.decimals || 0
                          } decimal units`,
                      },
                      required: 'This field is required',
                    })}
                  />
                  <div className="min-w-fit">
                    <TokenSelect value={watch('paymentAsset')} isDisabled />
                  </div>
                </div>
              </InputContainer>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <LargeHeader weight="bold" color={theme.text.primary}>
            Time
          </LargeHeader>
          <div className="flex flex-col gap-6">
            <div className="flex gap-6 w-full">
              <InputContainer
                label="From"
                className={{ container: 'w-full' }}
                error={phaseErrors?.startDate?.message}
              >
                <input
                  type="date"
                  className="w-full"
                  min={format(startOfToday(), DEFAULT_DATE_FORMAT)}
                  max={format(
                    endOfYear(new Date(2200, 0, 1)),
                    DEFAULT_DATE_FORMAT,
                  )}
                  {...register(`schedules.${index}.startDate`, {
                    required: 'This field is required',
                    min: {
                      value: format(startOfToday(), DEFAULT_DATE_FORMAT),
                      message: 'Please select a time in the future',
                    },
                    max: {
                      value: format(
                        endOfYear(new Date(2200, 0, 1)),
                        DEFAULT_DATE_FORMAT,
                      ),
                      message: 'Please select a date prior the year 2201',
                    },
                  })}
                />
              </InputContainer>
              <InputContainer
                label="Start Time (Your Local Time)"
                className={{ container: 'w-full' }}
                error={phaseErrors?.startTime?.message}
              >
                <input
                  type="time"
                  className="w-full"
                  {...register(`schedules.${index}.startTime`, {
                    required: 'This field is required',
                  })}
                />
              </InputContainer>
            </div>
            <div className="flex gap-6 w-full">
              <InputContainer
                label="To"
                className={{ container: 'w-full' }}
                error={phaseErrors?.endDate?.message}
              >
                <input
                  type="date"
                  min={watch(`schedules.${index}.startDate`)}
                  max={format(
                    endOfYear(new Date(2200, 0, 1)),
                    DEFAULT_DATE_FORMAT,
                  )}
                  className="w-full"
                  {...register(`schedules.${index}.endDate`, {
                    required: 'This field is required',
                    min: {
                      value: watch(`schedules.${index}.startDate`),
                      message: 'End day should be later than start day',
                    },
                    max: {
                      value: format(
                        endOfYear(new Date(2200, 0, 1)),
                        DEFAULT_DATE_FORMAT,
                      ),
                      message: 'Please select a date prior the year 2201',
                    },
                  })}
                />
              </InputContainer>
              <InputContainer
                label="End Time (Your Local Time)"
                className={{ container: 'w-full' }}
                error={phaseErrors?.endTime?.message}
              >
                <input
                  type="time"
                  className="w-full"
                  {...register(`schedules.${index}.endTime`, {
                    required: 'This field is required',
                  })}
                />
              </InputContainer>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationDeleteModal
        title="Delete phase"
        description="Are you sure you want to delete this phase?"
        onConfirm={remove}
        closeModal={closeDeleteModal}
        isOpen={isOpenDeleteModal}
      />
      <AllowlistModal
        title={`Allowlist ${index + 1}`}
        onSave={handleSaveAllowlistValue}
        closeModal={closeAllowlistModal}
        isOpen={isOpenAllowlistModal}
        defaultValues={
          allowlistValues || {
            isActive: false,
            list: '',
          }
        }
      />
    </>
  )
}

interface PhaseContainerProps {
  isActive: boolean
  index: number
  remove: (i: number) => void
  setActivePhase: (id: number) => void
}

const PhaseContainer: React.FC<PhaseContainerProps> = ({
  isActive,
  index,
  remove,
  setActivePhase,
}) => {
  const handleRemove = useCallback(() => {
    remove(index)
  }, [remove, index])
  const handleSetActivePhase = useCallback(() => {
    setActivePhase(index)
  }, [setActivePhase, index])

  if (isActive) return <PhaseForm index={index} remove={handleRemove} />

  return <PhaseInfo index={index} setActivePhase={handleSetActivePhase} />
}

interface PhaseInfoProps {
  index: number
  setActivePhase: () => void
}

const PhaseInfo: React.FC<PhaseInfoProps> = ({ index, setActivePhase }) => {
  const { control, setValue } = useFormContext<CreateFormInputState>()
  const { isOpen, close, open } = useModal()
  const paymentAsset = useWatch({
    control,
    name: 'paymentAsset',
  })
  const { token: paymentToken } = useFetchToken(paymentAsset)
  const phase = useWatch({
    control,
    name: `schedules.${index}`,
  })

  const pricePerLot = useMemo(() => {
    if (!paymentToken || !phase.pricePerLot) return BigNumber.from(0)
    return ethers.utils.parseUnits(phase.pricePerLot, paymentToken.decimals)
  }, [paymentToken, phase])

  const date = useMemo(() => {
    const { startDate, startTime, endDate, endTime } = phase
    if (!startDate || !startTime || !endDate || !endTime) return ''
    const startDateTime = `${phase.startDate}T${phase.startTime}`
    const endDateTime = `${phase.endDate}T${phase.endTime}`
    const start = dateToSeconds(new Date(startDateTime))
    const end = dateToSeconds(new Date(endDateTime))
    const duration = end - start
    return fullStartEndDatePhase(start, duration)
  }, [phase])
  const handleSaveAllowlist = useCallback(
    (value: AllowlistInput) => {
      setValue(`schedules.${index}.allowlist`, value)
    },
    [index, setValue],
  )

  return (
    <>
      <CollapsePhaseView
        index={index}
        amountTotal={phase.amountTotal}
        pricePerLot={pricePerLot}
        tokenAddress={paymentAsset}
        date={date}
        hasAllowlist={!!phase.allowlist?.isActive}
        isEditActive
        onClickAllowList={open}
        onClickEdit={setActivePhase}
      />
      <AllowlistModal
        title={`Allowlist ${index + 1}`}
        onSave={handleSaveAllowlist}
        closeModal={close}
        isOpen={isOpen}
        defaultValues={
          phase.allowlist || {
            isActive: false,
            list: '',
          }
        }
      />
    </>
  )
}

const FeesGeneratedSection: React.FC = () => {
  const { control } = useFormContext<CreateFormInputState>()
  const lotTokens = useWatch({
    control,
    name: 'lotInfo',
  })
  const schedules = useWatch({
    control,
    name: 'schedules',
  })
  const paymentAsset = useWatch({
    control,
    name: 'paymentAsset',
  })
  const totalFees = useGetGeneratedFee({
    schedules,
    paymentAddress: paymentAsset,
  })

  const totalPerToken = useGetTotalRewardPerToken({ lotTokens, schedules })

  return (
    <div className="flex flex-col gap-8">
      <CreateStepHeading
        title={dataInfo.fees.title}
        description={dataInfo.fees.description}
      />
      <div className="flex gap-6">
        <div className="flex flex-col gap-2 w-full">
          {totalPerToken.map((token, i) => (
            <InfoMathToken
              key={token.address}
              title={i === 0 ? 'Total tokens to use:' : ''}
              amount={token.total}
              token={token.address}
              description="For memberships"
              balanceVariant={BalanceLabelVariant.BalanceAndWarning}
            />
          ))}
        </div>
        <div className="w-full">
          <InfoMathToken
            title="Total Fees:"
            amount={totalFees}
            token={paymentAsset}
            description="In fees"
          />
        </div>
      </div>
    </div>
  )
}
