import { BigNumber, ethers } from 'ethers'
import { FC } from 'react'
import {
  Body,
  Caption,
  CircleImg,
  LargeHeader,
  SubHeader,
  useTheme,
} from '@tryrolljs/design-system'
import { useFetchToken } from '../../hooks/tokens'
import { formatNumber } from '../../utils'
import { BalanceLabel, BalanceLabelVariant } from '../balanceLabel'

interface InfoMathTokenProps {
  title: string
  amount?: BigNumber
  regularAmount?: number
  token: string
  description: string
  balanceVariant?: BalanceLabelVariant
}

export const InfoMathToken: FC<InfoMathTokenProps> = ({
  title,
  amount,
  token,
  regularAmount,
  description,
  balanceVariant,
}) => {
  const theme = useTheme()
  const { token: tokenData } = useFetchToken(token)

  const formatAmount =
    regularAmount ??
    ethers.utils.formatUnits(
      amount || BigNumber.from(0),
      tokenData?.decimals ?? 0,
    )

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-2 min-w-[320px]">
        <Body weight="semiBold" color={theme.text.primary}>
          {title}
        </Body>
        <div className="rounded-2xl p-4 bg-[#EAEEF3] flex justify-between items-center">
          <LargeHeader color={theme.text.secondary}>
            {formatNumber(formatAmount)}
          </LargeHeader>
          <div className="flex flex-col items-end">
            <div className="flex gap-2 items-center">
              {tokenData ? (
                <>
                  <CircleImg size={16} uri={tokenData.logoURI} />
                  <SubHeader weight="bold" color={theme.text.primary}>
                    {tokenData.symbol}
                  </SubHeader>
                </>
              ) : (
                <>
                  <div className="w-2 h-2 rounded-full bg-[#CCCCCC]" />
                  <SubHeader color={theme.text.primary}>Token</SubHeader>
                </>
              )}
            </div>
            <Caption color={theme.text.secondary}>{description}</Caption>
          </div>
        </div>
      </div>
      {balanceVariant && !!tokenData && (
        <BalanceLabel
          token={tokenData}
          wei={amount}
          variants={balanceVariant}
        />
      )}
    </div>
  )
}
