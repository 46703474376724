import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Memberships } from '@roll-network/contract-bindings'
import {
  useChainID,
  useSigner,
  CHAIN_ID_HARDHAT,
  CHAIN_ID_MAIN_NET,
  CHAIN_ID_ROPSTEN,
  CHAIN_ID_MUMBAI,
  CHAIN_ID_GOERLI,
  CHAIN_ID_POLYGON,
} from '@tryrolljs/design-system'
import { config, NetworkConfig } from '../config'
import { CHAIN_ID_FORM_TEST_NET } from '../contracts/constants'

export interface ContractPoolContext {
  membershipsFactory: Memberships.Memberships | null
  membershipsViewFactory: Memberships.MembershipsView | null
}

const ContractPoolCtx = createContext<ContractPoolContext>({
  membershipsFactory: null,
  membershipsViewFactory: null,
})

export const useContractPool = () => useContext(ContractPoolCtx)

export const ContractPoolProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const chainID = useChainID()
  const signer = useSigner()
  const [{ membershipsFactory, membershipsViewFactory }, setContracts] =
    useState<ContractPoolContext>({
      membershipsFactory: null,
      membershipsViewFactory: null,
    })

  const connectContracts = useCallback(
    (networkConfig: NetworkConfig) => {
      if (!signer) {
        throw new Error('unable to init contracts, missing signer')
      }
      const memberships = Memberships.Memberships__factory.connect(
        networkConfig.MEMBERSHIPS_ADDRESS,
        signer,
      )
      const membershipsView = Memberships.MembershipsView__factory.connect(
        networkConfig.MEMBERSHIPS_VIEW_ADDRESS,
        signer,
      )
      console.log('connected to contracts...', networkConfig)
      setContracts({
        membershipsFactory: memberships,
        membershipsViewFactory: membershipsView,
      })
    },
    [signer],
  )

  useEffect(() => {
    try {
      switch (chainID) {
        case CHAIN_ID_MAIN_NET:
          console.log('connecting to mainnet', config.NETWORK_MAINNET)
          connectContracts(config.NETWORK_MAINNET)
          break

        case CHAIN_ID_GOERLI:
          console.log('connecting to goerli', config.NETWORK_GOERLI)
          connectContracts(config.NETWORK_GOERLI)
          break

        case CHAIN_ID_ROPSTEN:
          console.log('connecting to ropsten', config.NETWORK_ROPSTEN)
          connectContracts(config.NETWORK_ROPSTEN)
          break
        case CHAIN_ID_MUMBAI:
          console.log('connecting to mumbai', config.NETWORK_MUMBAI)
          connectContracts(config.NETWORK_MUMBAI)
          break
        case CHAIN_ID_POLYGON:
          console.log('connecting to Polygon', config.NETWORK_POLYGON)
          connectContracts(config.NETWORK_POLYGON)
          break
        case CHAIN_ID_HARDHAT:
          console.log('connecting to local', config.NETWORK_LOCAL)
          connectContracts(config.NETWORK_LOCAL)
          break
        case CHAIN_ID_FORM_TEST_NET:
          console.log('connecting to form testnet', config.NETWORK_FORM_TESTNET)
          connectContracts(config.NETWORK_FORM_TESTNET)
          break

        default:
          throw new Error(`unsupported chainID ${chainID}`)
      }
    } catch (err) {
      console.error(err)
    }
  }, [chainID, connectContracts])

  return (
    <ContractPoolCtx.Provider
      value={{ membershipsFactory, membershipsViewFactory }}
    >
      {children}
    </ContractPoolCtx.Provider>
  )
}
