import { useCallback, useEffect, useMemo, useState } from 'react'
import { AddressZero } from '@ethersproject/constants'
import { useChainID, useEthAddress, useSigner } from '@tryrolljs/design-system'
import { BigNumber, ethers } from 'ethers'
import { useWithAnyAsync } from '..'
import {
  getPaymentTokens,
  getTokenAllowance,
  getTokenBalanceByWallet,
  getTokenByAddress,
  getTokensByAddress,
  approveToken,
} from '../../contracts/tokens'
import { AllowanceSpenders, Token } from '../../types'
import {
  useAllTokensSelector,
  useIsLoadingBatchTokenSelector,
  useIsLoadingTokenSelector,
  useMissingTokensSelector,
  useSetBatchTokens,
  useSetIsLoadingTokens,
  useSetIsTokenLoading,
  useSetToken,
  useTokenByAddressSelector,
  useTokensByAddressSelector,
  useUpdateToken,
} from '../selectors/token'
import { getTokens } from '../../state/tokens/services'
import { useApproveTokenNotifications } from '../notifications'
import { useFormValuesState } from '../selectors/create'
import { useNetworkConfig } from '../web3'
import { useContractPool } from '../../providers/contracts'
import { useGetTotalRewardPerToken } from '../create'

/**
 * Use to get a token from the store.
 * If token is not in the store it will first fetch it.
 * @param {string} tokenId - token address
 * @param {boolean} defer - If execution should be trigger manually
 */
export const useFetchToken = (tokenId: string, defer: boolean = false) => {
  const signer = useSigner()
  const networkConfig = useNetworkConfig()
  const chainId = useChainID()
  const token = useTokenByAddressSelector(tokenId)
  const isAlreadyLoadingToken = useIsLoadingTokenSelector(tokenId)
  const setIsLoadingToken = useSetIsTokenLoading()
  const setToken = useSetToken()
  const {
    execute,
    status,
    error,
    isLoading: isLoadingAsync,
  } = useWithAnyAsync()

  const managedExec = useCallback(
    async (newId?: string) => {
      const id = newId || tokenId
      if (!token && signer && !isAlreadyLoadingToken && id) {
        setIsLoadingToken(id, true)
        const value = await execute(async () => {
          return getTokenByAddress(
            id,
            signer,
            networkConfig.MEMBERSHIPS_ADDRESS,
            chainId,
          )
        })
        if (value?.data) {
          setToken(value.data)
        }
        setIsLoadingToken(id, false)
      }
    },
    [
      token,
      signer,
      chainId,
      isAlreadyLoadingToken,
      tokenId,
      execute,
      setIsLoadingToken,
      setToken,
      networkConfig,
    ],
  )

  useEffect(() => {
    if (!token && !defer) {
      managedExec()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, signer, isAlreadyLoadingToken])

  const isLoading = isLoadingAsync || isAlreadyLoadingToken

  return { status, error, token, managedExec, isLoading }
}

/**
 * Use to get a list of tokens from the store.
 * If some tokens are not in the store it will first fetch them
 * and the returns the full list.
 * @param {string[]} tokensId - List of tokens addresses
 * @param {boolean} defer - If Execution should be trigger manually
 */
export const useFetchTokens = (tokensId: string[], defer: boolean = false) => {
  const signer = useSigner()
  const networkConfig = useNetworkConfig()
  const tokens = useTokensByAddressSelector(tokensId)
  const missingTokens = useMissingTokensSelector(tokensId)
  const setBatchTokens = useSetBatchTokens()
  const loadingTokens = useIsLoadingBatchTokenSelector(missingTokens)
  const setIsLoadingTokens = useSetIsLoadingTokens()
  const chainId = useChainID()
  const {
    execute,
    status,
    error,
    isLoading: isLoadingTokens,
  } = useWithAnyAsync()

  const managedExec = useCallback(async () => {
    const missingTokensNotLoading = missingTokens.filter(
      (address) => !loadingTokens.includes(address),
    )
    if (missingTokensNotLoading.length && signer && chainId) {
      setIsLoadingTokens(missingTokensNotLoading, true)
      const value = await execute(async () => {
        return getTokensByAddress(
          missingTokensNotLoading,
          signer,
          networkConfig.MEMBERSHIPS_ADDRESS,
          chainId,
        )
      })
      if (value?.data) {
        setBatchTokens(value.data)
      }
      setIsLoadingTokens(missingTokensNotLoading, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missingTokens, loadingTokens, chainId])

  const allTokens = useMemo(() => {
    if (!missingTokens.length) return tokens
    return []
  }, [missingTokens, tokens])

  useEffect(() => {
    if (missingTokens.length && !defer && signer && chainId) {
      managedExec()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missingTokens, signer, chainId])

  const isLoading = isLoadingTokens || !!loadingTokens.length

  return { status, tokens: allTokens, error, managedExec, isLoading }
}

export const useListTokens = (isPayment: boolean) => {
  const tokens = useAllTokensSelector()
  const { membershipsViewFactory } = useContractPool()
  const chainId = useChainID()
  const [paymentTokens, setPaymentTokens] = useState<string[]>([])
  const { isLoading: isLoadingPaymentTokensData, tokens: paymentTokensData } =
    useFetchTokens(paymentTokens)
  const setBatchTokens = useSetBatchTokens()
  const { execute, status, error, isLoading } = useWithAnyAsync()

  const managedExec = useCallback(async () => {
    if (!chainId || !membershipsViewFactory) return
    const value = await execute(async () => {
      if (isPayment) {
        const tokensIds = await getPaymentTokens(membershipsViewFactory)
        setPaymentTokens(tokensIds)
      }
      return getTokens(chainId)
    })
    if (value?.data) {
      setBatchTokens(value.data)
    }
  }, [isPayment, chainId, execute, membershipsViewFactory, setBatchTokens])

  useEffect(() => {
    managedExec()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    tokens: isPayment
      ? paymentTokensData
      : // * Remove native token from list when is not the assest payment.
        tokens.filter((t) => t.address !== AddressZero),
    status,
    error,
    isLoading: isLoading || isLoadingPaymentTokensData,
  }
}

export const useUpdateBalance = () => {
  const signer = useSigner()
  const userAddr = useEthAddress()
  const chainId = useChainID()
  const updateToken = useUpdateToken()
  const { execute, isLoading } = useWithAnyAsync()

  const updateBalance = useCallback(
    async (address: string) => {
      if (!signer || !userAddr) return
      const response = await execute(() =>
        getTokenBalanceByWallet(address, userAddr, signer, chainId),
      )
      if (!response) return
      updateToken(address, { userBalance: response })
    },
    [signer, userAddr, chainId, updateToken, execute],
  )

  return { isLoading, updateBalance }
}

export const useTokenBalanceUser = (token?: Token) => {
  const balance = token?.userBalance || BigNumber.from(0)
  const { isLoading, updateBalance } = useUpdateBalance()

  const formatBalance = ethers.utils.formatUnits(balance, token?.decimals)

  useEffect(() => {
    if (token && !token.userBalance) {
      updateBalance(token.address)
    }
  }, [token, updateBalance])

  return { formatBalance, balance, isLoadingBalance: isLoading }
}

export const useTokenAllowance = (
  spender: AllowanceSpenders,
  token?: Token,
) => {
  const updateAllowance = useUpdateAllowance()
  const allowance =
    spender === AllowanceSpenders.memberships
      ? token?.membershipAllowance
      : token?.membershipImplAllowance
  const { execute, isLoading: isLoadingAllowance } = useWithAnyAsync()

  const getAllowance = useCallback(async () => {
    if (!token || !!allowance) return
    execute(() => updateAllowance(token.address, spender))
  }, [updateAllowance, token, allowance, execute, spender])

  useEffect(() => {
    getAllowance()
  }, [getAllowance])

  return {
    allowance: allowance || BigNumber.from(0),
    isLoadingAllowance,
  }
}

export const useUpdateAllowance = () => {
  const signer = useSigner()
  const networkConfig = useNetworkConfig()
  const userAddr = useEthAddress()
  const updateToken = useUpdateToken()

  const updateAllowance = useCallback(
    async (
      tokenAddr: string,
      spender: AllowanceSpenders,
      value?: BigNumber,
    ) => {
      if (!signer || !userAddr) return null
      const data =
        value ||
        (await getTokenAllowance({
          tokenAddr,
          provider: signer,
          signerAddr: userAddr,
          spender:
            spender === AllowanceSpenders.memberships
              ? networkConfig.MEMBERSHIPS_ADDRESS
              : networkConfig.MEMBERSHIPS_IMPL_ADDRESS,
        }))
      if (!data) return null
      if (spender === AllowanceSpenders.memberships) {
        return updateToken(tokenAddr, { membershipAllowance: data })
      }
      return updateToken(tokenAddr, { membershipImplAllowance: data })
    },
    [signer, userAddr, updateToken, networkConfig],
  )

  return updateAllowance
}

export const useApproveToken = () => {
  const signer = useSigner()
  const { isLoading, execute } = useWithAnyAsync()
  const networkConfig = useNetworkConfig()
  const updateAllowance = useUpdateAllowance()
  const approveNotifications = useApproveTokenNotifications()

  const approveToken_ = useCallback(
    async ({
      tokenId,
      value,
      formatValue,
      tokenSymbol,
      spender,
      isBuy = false,
    }: {
      tokenId: string
      value: BigNumber
      tokenSymbol: string
      formatValue: string
      spender: AllowanceSpenders
      isBuy?: boolean
    }) => {
      if (!signer) return null
      const response = await execute(() =>
        approveToken({
          value,
          token: tokenId,
          spender:
            spender === AllowanceSpenders.memberships
              ? networkConfig.MEMBERSHIPS_ADDRESS
              : networkConfig.MEMBERSHIPS_IMPL_ADDRESS,
          provider: signer,
          successMessage: `Successfully approved ${formatValue} ${tokenSymbol}. ${
            isBuy ? 'Now click on "Claim Now" to claim your tokens.' : ''
          }`,
          ...approveNotifications,
        }),
      )
      if (response) {
        updateAllowance(tokenId, spender, response)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [signer, execute, updateAllowance],
  )

  return { isLoading, approveToken: approveToken_ }
}

export const useAllTokensAllow = () => {
  const { schedules, lotInfo } = useFormValuesState()
  const { isLoading, tokens } = useFetchTokens(
    lotInfo.map((elem) => elem.lotToken),
  )

  const totalTokens = useGetTotalRewardPerToken({
    lotTokens: lotInfo,
    schedules,
  })

  const allTokensAllow = useMemo(() => {
    if (!tokens || !tokens.length || isLoading) return false
    return tokens.every((token) => {
      const allowance = token.membershipAllowance
      if (!allowance) return false
      const totalAmount =
        totalTokens.find((lot) => lot.address === token.address)?.total ??
        BigNumber.from(0)
      return allowance?.gte(totalAmount)
    })
  }, [isLoading, tokens, totalTokens])

  return { totalTokens, allTokensAllow }
}

export const useAllTokensHasBalance = ({
  tokensIds,
  balancesRequire,
}: {
  tokensIds: string[]
  balancesRequire: Record<string, BigNumber>
}) => {
  const { isLoading, tokens } = useFetchTokens(tokensIds)

  const hasBalanceMap = useMemo(() => {
    const map: Record<string, boolean> = {}
    tokens.forEach((token) => {
      const balanceRequire = balancesRequire[token.address]
      map[token.address] = token.userBalance?.gte(balanceRequire) ?? false
    })
    return map
  }, [tokens, balancesRequire])

  const hasAllBalance = useMemo(() => {
    return Object.values(hasBalanceMap).every((value) => !!value)
  }, [hasBalanceMap])

  return { hasBalanceMap, hasAllBalance: hasAllBalance && !isLoading }
}
