import React, { useCallback, useMemo, useState } from 'react'
import {
  Caption,
  CircleImg,
  SubHeader,
  useTheme,
  Button,
  Body,
  useEthAddress,
} from '@tryrolljs/design-system'
import { BigNumber, ethers } from 'ethers'
import clsx from 'clsx'
import { useFetchToken } from '../../hooks/tokens'
import {
  checkIsPhasePublic,
  getGeneratedFee,
  getStatusPhase,
} from '../../core/phases'
import { StatusPhase } from '../../types'
import { fullStartEndDatePhase } from '../../utils/dates'
import { PhaseStatusIcon } from '../../molecules/phaseStatusIcon'
import {
  useClaimedFees,
  useClaimPhaseOwner,
  useClaimReferralFee,
  useClaimUnsoldTokens,
  useReferralFee,
  UserType,
} from '../../hooks/phases'
import { useCampaignByAddressSelector } from '../../hooks/selectors/campaign'
import { getFee } from '../../utils'
import ArrowRightIcon from '../../assets/svg/arrow-right.svg'

interface MembershipPhasesElemProps {
  id: string
  title: string
  lots: number
  releasedLots: number
  earned: BigNumber
  rollFee: BigNumber
  date: string
  token: string
  status: StatusPhase
}

const MembershipPhaseElem: React.FC<MembershipPhasesElemProps> = ({
  id,
  earned,
  rollFee,
  title,
  lots,
  date,
  releasedLots,
  token,
  status,
}) => {
  const theme = useTheme()
  const { token: tokenData } = useFetchToken(token)
  const userAddress = useEthAddress()

  const {
    isLoading: isLoadingClaimedFees,
    claimed,
    getClaimed,
  } = useClaimedFees(id, UserType.OWNER)

  const {
    isLoading: isLoadingClaimedUnsold,
    claimed: unsoldTokensClaimed,
    getClaimed: getUnsoldTokensClaimed,
  } = useClaimedFees(id, UserType.UNSOLD)

  const {
    isLoading: isLoadingClaimedReferral,
    claimed: referralClaimed,
    getClaimed: getReferralClaimed,
  } = useClaimedFees(id, UserType.REFERRAL)

  const { claimFees, isLoading: isClaiming } = useClaimPhaseOwner(getClaimed)
  const {
    isLoading: isLoadingReferralFee,
    referralFee,
    referral,
  } = useReferralFee(id)

  const { claimUnsoldTokens, isLoading: isClaimingUnsoldTokens } =
    useClaimUnsoldTokens(getUnsoldTokensClaimed)

  const { claimReferralFees, isLoading: isClaimingReferralFee } =
    useClaimReferralFee(getReferralClaimed)

  const isReferral =
    userAddress?.toString().toLowerCase() === referral.toLowerCase()

  const fees = useMemo(() => {
    if (isReferral) {
      return getFee(earned, referralFee)
    }
    return getGeneratedFee(earned, rollFee, referralFee)
  }, [earned, rollFee, referralFee, isReferral])

  const isDone = status === StatusPhase.done
  const isSoldOut = status === StatusPhase.soldOut

  const enableBasedOnStatus = isDone || isSoldOut

  const isClaimed = useMemo(() => {
    if (isReferral) {
      return !!releasedLots && referralClaimed && referralClaimed.eq(fees)
    }
    return !!releasedLots && claimed && claimed.eq(fees)
  }, [isReferral, releasedLots, claimed, referralClaimed, fees])

  const isDisabled =
    !enableBasedOnStatus ||
    isClaiming ||
    !fees.gt(BigNumber.from(0)) ||
    isLoadingReferralFee ||
    isClaimingReferralFee ||
    isLoadingClaimedReferral

  const isUnsoldTokensClaimed = useMemo(() => {
    return unsoldTokensClaimed?.eq(lots - releasedLots)
  }, [lots, unsoldTokensClaimed, releasedLots])

  const showUnsoldButton = useMemo(() => {
    if (isLoadingClaimedUnsold || isReferral) return false
    return !isUnsoldTokensClaimed && isDone
  }, [isLoadingClaimedUnsold, isUnsoldTokensClaimed, isDone, isReferral])

  const handleClaimFees = useCallback(() => {
    if (isReferral) {
      return claimReferralFees(id)
    }
    return claimFees(id)
  }, [id, isReferral, claimReferralFees, claimFees])

  return (
    <div className="flex gap-3">
      <PhaseStatusIcon status={status} />
      <div className="flex flex-col gap-1">
        <Caption weight="semiBold" color={theme.text.primary}>
          {title}
        </Caption>
        <Caption color={theme.text.secondary}>{lots.toString()} lots</Caption>
        <Caption color={theme.text.secondary}>{date}</Caption>
        <div className="flex items-center gap-1">
          <Caption color={theme.text.secondary}>
            {ethers.utils.formatUnits(fees, tokenData?.decimals)}
          </Caption>
          {tokenData && (
            <>
              <CircleImg size={12} uri={tokenData.logoURI} />
              <Caption color={theme.text.secondary}>
                {tokenData.name} | {isReferral && 'Referral'} Fees Generated
              </Caption>
            </>
          )}
        </div>
        <div className="mt-2">
          {isLoadingClaimedFees && <p>Loading...</p>}
          {!isLoadingClaimedFees && (
            <div className="flex gap-4 items-center">
              <div className="flex gap-6 items-center">
                {isClaimed && (
                  <Caption color={theme.text.highlight}>Claimed</Caption>
                )}
                {!isClaimed && (
                  <Button
                    isHovering
                    onPress={handleClaimFees}
                    variant="secondary"
                    disabled={isDisabled}
                  >
                    <Body
                      color={
                        isDisabled
                          ? theme.background.tertiary
                          : theme.text.primary
                      }
                    >
                      Claim Fees
                    </Body>
                  </Button>
                )}
                {showUnsoldButton && (
                  <Button
                    isHovering
                    onPress={() => claimUnsoldTokens(id)}
                    variant="secondary"
                    disabled={isClaimingUnsoldTokens}
                  >
                    <Body
                      color={
                        isClaimingUnsoldTokens
                          ? theme.background.tertiary
                          : theme.text.primary
                      }
                    >
                      Claim Remaining Tokens
                    </Body>
                  </Button>
                )}
              </div>
              {!enableBasedOnStatus && !isClaimed && (
                <Caption color={theme.text.secondary}>
                  Able to claim after phase end
                </Caption>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

interface MembershipPhaseListProps {
  campaignId: string
}

export const MembershipPhasesList: React.FC<MembershipPhaseListProps> = ({
  campaignId,
}) => {
  const campaign = useCampaignByAddressSelector(campaignId)
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)
  const handleClickPhase = useCallback(() => {
    setIsOpen((prev) => !prev)
  }, [setIsOpen])
  if (!campaign?.phasesData) return null
  return (
    <div className="flex flex-col">
      <div className="flex items-center mb-4">
        <div
          className="flex items-center gap-1 cursor-pointer"
          onClick={handleClickPhase}
        >
          <div
            className={clsx(
              'transform transition-transform',
              isOpen && 'rotate-90',
            )}
          >
            <ArrowRightIcon />
          </div>
          <SubHeader weight="semiBold" color={theme.text.secondary}>
            PHASES
          </SubHeader>
        </div>
      </div>
      {isOpen && (
        <div className="flex flex-col gap-6 pl-7">
          {campaign.phasesData.map((phase) => (
            <MembershipPhaseElem
              key={phase.phaseId}
              id={phase.phaseId}
              releasedLots={phase.released}
              earned={phase.pricePerLot.mul(phase.released)}
              rollFee={phase.rollFee}
              title={
                checkIsPhasePublic(phase.merkleRoot) ? 'Public' : 'Allowlist'
              }
              lots={phase.amountTotal}
              date={fullStartEndDatePhase(phase.start, phase.duration)}
              status={getStatusPhase(phase)}
              token={phase.paymentAsset.token}
            />
          ))}
        </div>
      )}
    </div>
  )
}
