import {
  TryrollProvider,
  injectFonts,
  Web3Provider,
  // ModalProvider,
  // ModalManager,
  CHAIN_ID_HARDHAT,
  CHAIN_ID_MAIN_NET,
  CHAIN_ID_GOERLI,
  CHAIN_ID_MUMBAI,
  CHAIN_ID_POLYGON,
} from '@tryrolljs/design-system'
import { Provider } from 'react-redux'
import { config } from './config'
import { RootNavigator } from './navigation'
import './App.css'
import { store } from './store'
import { ContractPoolProvider } from './providers/contracts'
import { ConnectWalletModalProvider } from './providers/walletConnectModal'
import { CHAIN_ID_FORM_TEST_NET } from './contracts/constants'

injectFonts()

function App() {
  return (
    <Provider store={store}>
      <TryrollProvider>
        <Web3Provider
          supportedChainIDs={[
            CHAIN_ID_MAIN_NET,
            CHAIN_ID_HARDHAT,
            CHAIN_ID_GOERLI,
            CHAIN_ID_POLYGON,
            CHAIN_ID_MUMBAI,
            CHAIN_ID_FORM_TEST_NET,
          ]}
          fortmaticApiKey={config.FORTMATIC_API_KEY}
          portisDappID={config.PORTIS_DAPP_ID}
        >
          <ContractPoolProvider>
            <ConnectWalletModalProvider>
              <RootNavigator />
            </ConnectWalletModalProvider>
          </ContractPoolProvider>
        </Web3Provider>
      </TryrollProvider>
    </Provider>
  )
}

export default App
