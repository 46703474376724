import { BigNumber, Signer } from 'ethers'
import { SocialMoneyV1_5, Memberships } from '@roll-network/contract-bindings'
import { AddressZero } from '@ethersproject/constants'
import { Provider, Token, TransactionCBs } from '../types'
import { nativeTokens } from './constants'
import { getContractError } from './errors'

export const getTokenByAddress = async (
  address: string,
  provider: Provider,
  membershipsAddress: string,
  chainId?: number,
) => {
  if (address === AddressZero && chainId) {
    return {
      data: nativeTokens[chainId],
      error: '',
    }
  }
  const token = SocialMoneyV1_5.ERC20__factory.connect(address, provider)
  const response: { data: Token | null; error: string } = {
    data: null,
    error: '',
  }
  if (!token) return response
  const symbol = await token.symbol()
  const name = await token.name()
  const decimals = await token.decimals()
  const contractBalance = await token.balanceOf(membershipsAddress)
  const totalSupply = await token.totalSupply()
  try {
    response.data = {
      symbol,
      name,
      decimals,
      address,
      logoURI: '',
      totalSupply,
      membershipsContractBalance: contractBalance,
    }
    return response
  } catch (error) {
    response.error = getContractError(error).message
    return response
  }
}

export const getTokensByAddress = async (
  addresses: string[],
  provider: Provider,
  membershipsAddress: string,
  chainId: number,
) => {
  const response: { data: Token[]; error: string } = { data: [], error: '' }
  try {
    const tokensData = await Promise.all(
      addresses.map((address) =>
        getTokenByAddress(address, provider, membershipsAddress, chainId),
      ),
    )
    const tokens = tokensData
      .filter((elem) => elem.data)
      .map((elem) => elem.data as Token)
    response.data = tokens
    return response
  } catch (error) {
    response.error = getContractError(error).message
    return response
  }
}

export const getTokenBalanceByWallet = async (
  tokenAddr: string,
  signerAddr: string,
  provider: Provider,
  chainId?: number,
) => {
  if (tokenAddr === AddressZero && chainId) {
    const balance = await (provider as Signer).getBalance()
    return balance
  }
  const token = SocialMoneyV1_5.ERC20__factory.connect(tokenAddr, provider)
  if (!token) return BigNumber.from(0)
  const value = await token.balanceOf(signerAddr)
  return value ?? BigNumber.from(0)
}

interface GetTokenAllowanceProps {
  tokenAddr: string
  signerAddr: string
  provider: Provider
  spender: string
}

export const getTokenAllowance = async ({
  tokenAddr,
  signerAddr,
  provider,
  spender,
}: GetTokenAllowanceProps) => {
  const token = SocialMoneyV1_5.ERC20__factory.connect(tokenAddr, provider)
  if (!token) return BigNumber.from(0)
  const value = await token.allowance(signerAddr, spender)
  return value ?? BigNumber.from(0)
}

export const getPaymentTokens = async (
  contract: Memberships.MembershipsView,
) => {
  const response = await contract.getTokensAllowed()

  return [AddressZero, ...(response || [])]
}

interface ApproveTokenProps {
  spender: string
  token: string
  provider: Provider
  value: BigNumber
  successMessage: string
}

export const approveToken = async ({
  spender,
  token,
  provider,
  value,
  successMessage,
  ...cb
}: ApproveTokenProps & TransactionCBs) => {
  try {
    const t = SocialMoneyV1_5.ERC20__factory.connect(token, provider)
    const tx = await t.approve(spender, value)
    cb.onSubmit(tx.hash)
    await tx.wait()
    cb.onSuccess(successMessage)
    return value
  } catch (error) {
    cb.onError(getContractError(error).message)
  }
}
