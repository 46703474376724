import { BigNumber } from 'ethers'
import { FEE_SCALE } from '../contracts/constants'

/**
 * Get an array of numbers from start to finish inslusive
 * @param start - start of array
 * @param end - end of array
 */
export const range = (start: number, end: number): number[] => {
  return Array(end - start + 1)
    .fill(null)
    .map((_, idx) => start + idx)
}

export const isStringANumber = (value: string) => {
  return !isNaN(Number(value))
}

export const getDecimalLength = (value: string) => {
  if (!isStringANumber(value)) return 0
  const decimals = value.split('.')
  if (decimals.length < 2) return 0
  return decimals[1].length
}

export const getFee = (amount: BigNumber, fee: BigNumber): BigNumber => {
  const bigFeeScale = BigNumber.from(FEE_SCALE)
  if (!fee) return BigNumber.from(0)
  return amount.mul(fee).div(bigFeeScale)
}

/**
 * Format numbers adding comma separation.
 * 10000 -> 10,000
 * @param value {string | number} - String or number to format
 * @returns {string}
 */
export const formatNumber = (value: string | number): string => {
  return value
    .toString()
    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
    .replace(/(?:\.0+|(\.\d+?)0+)$/, '$1')
}
