import React from 'react'
import { padding } from '@tryrolljs/design-system'

/* Constants */
import { MAX_SCREEN_CONTENT_WIDTH } from '../../constants'

export const PageContent: React.FC<{ isFullWidth?: boolean }> = ({
  children,
  isFullWidth = false,
}) => {
  return (
    <div
      style={{ padding: isFullWidth ? 0 : padding.p32.padding }}
      className="p-8 flex flex-1 justify-center"
    >
      <div
        className="flex flex-1 flex-col"
        style={{ maxWidth: isFullWidth ? '' : MAX_SCREEN_CONTENT_WIDTH }}
      >
        {children}
      </div>
    </div>
  )
}
