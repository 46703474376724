import React from 'react'
import {
  Button,
  Caption,
  Modal,
  padding,
  SubHeader,
  useTheme,
} from '@tryrolljs/design-system'
import CloseXIcon from '../../assets/svg/close-x.svg'

export interface ConfirmationDeleteModalProps {
  title: string
  description: string
  onConfirm: () => void
  closeModal: () => void
  isOpen: boolean
}

export const ConfirmationDeleteModal: React.FC<
  ConfirmationDeleteModalProps
> = ({ title, description, onConfirm, closeModal, isOpen }) => {
  const theme = useTheme()
  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Modal.Content style={[padding.p24]}>
        <div className="flex justify-between items-center w-full mb-6">
          <SubHeader weight="bold" color={theme.text.primary}>
            {title}
          </SubHeader>
          <div className="w-6 text-black cursor-pointer" onClick={closeModal}>
            <CloseXIcon />
          </div>
        </div>
        <Caption weight="regular" color={theme.text.secondary}>
          {description}
        </Caption>
        <div className="mt-8 flex justify-end gap-4">
          <div className="w-fit">
            <Button variant="secondary" onPress={closeModal}>
              Cancel
            </Button>
          </div>
          <div className="w-fit">
            {/*
              // TODO: Add correct style to delete button.
            */}
            <Button
              variant="secondary"
              onPress={() => {
                onConfirm()
                closeModal()
              }}
            >
              Delete
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
}
