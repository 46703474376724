import clsx from 'clsx'
import React, { useMemo } from 'react'
import { Body, SubHeader, margin, useTheme } from '@tryrolljs/design-system'
import {
  useNumberOfLotsSelector,
  usePhaseIdSelector,
  useSetNumberOfLots,
} from '../../hooks/selectors/buy'
import { Tier } from '../../types'
import { InfoTooltip } from '../../molecules/infoTooltip'

interface TiersProps {
  tiers: Partial<Tier>[]
}

export const Tiers: React.FC<TiersProps> = ({ tiers }) => {
  const numberOfLots = useNumberOfLotsSelector()
  const phaseId = usePhaseIdSelector()
  const theme = useTheme()
  const setNumberOfLots = useSetNumberOfLots()

  const sortedTiers = useMemo(() => {
    return [...tiers].sort((a, b) => (a.lots || 0) - (b.lots || 0))
  }, [tiers])

  const minTierLots = useMemo(() => {
    const lotsArray = sortedTiers
      .map((tier) => Number(tier.lots))
      .filter((value) => typeof value === 'number') as number[]
    if (!lotsArray.length) return 0
    return Math.min(...lotsArray)
  }, [sortedTiers])

  const maxTierLots = useMemo(() => {
    const lotsArray = sortedTiers
      .map((tier) => Number(tier.lots))
      .filter((value) => typeof value === 'number') as number[]
    if (!lotsArray.length) return 0
    return Math.max(...lotsArray)
  }, [sortedTiers])

  const selectedTier = useMemo(() => {
    if (!numberOfLots) return null
    if (numberOfLots < minTierLots) return null
    return sortedTiers.reduce((selected, curr, i, arr) => {
      if (!curr.lots) return selected
      if (numberOfLots >= curr.lots && curr.lots > (arr[selected].lots ?? 0))
        return i
      return selected
    }, 0)
  }, [sortedTiers, numberOfLots, minTierLots])

  const onClickHandler = (tierLots?: number) => {
    if (!tierLots || !phaseId) return
    if (tierLots === maxTierLots && maxTierLots < numberOfLots) return
    setNumberOfLots(tierLots)
  }

  return (
    <div className="w-full">
      <div className="mb-4 flex items-center gap-2">
        <Body weight="semiBold" color={theme.text.primary}>
          Tiers
        </Body>
        <InfoTooltip title="Tiers are membership levels that you can access with lots of tokens. Claim lots below to become a member of this community." />
      </div>
      {!sortedTiers.length && <Body>No tiers</Body>}
      <ul className="flex flex-col gap-2">
        {sortedTiers.map((tier, i) => (
          <li key={`${tier.name}-${i}`}>
            <TierElem
              {...tier}
              isSelected={i === selectedTier}
              onClick={() => onClickHandler(tier.lots)}
            />
          </li>
        ))}
      </ul>
    </div>
  )
}

const TierElem: React.FC<
  Partial<Tier> & { isSelected?: boolean; onClick: () => void }
> = ({ name, description, lots, isSelected = false, onClick }) => {
  const theme = useTheme()
  const phaseId = usePhaseIdSelector()
  return (
    <div
      className={clsx(
        'py-4 px-3 rounded-xl border flex flex-col',
        phaseId ? 'cursor-pointer' : 'cursor-not-allowed',
      )}
      style={{
        borderColor: isSelected ? theme.text.highlight : '',
      }}
      onClick={onClick}
    >
      <SubHeader
        weight="semiBold"
        color={theme.text.primary}
        style={margin.mb4}
      >
        {name}
      </SubHeader>
      <div className="mb-3">
        <Body color={theme.text.primary}>Get {lots} Lots or more</Body>
      </div>
      <Body color={theme.text.primary}>{description}</Body>
    </div>
  )
}
