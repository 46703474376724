import React from 'react'
import { Button, SubHeader, Title, useTheme } from '@tryrolljs/design-system'
import {
  useAllCampaignsByOwner,
  useAllCampaignsByReferral,
} from '../../hooks/campaigns'
import { useNavigateCreate } from '../../navigation/actions'
import { DashboardCardLoader } from '../../molecules/loaders'
import {
  MembershipCard,
  MembershipCardVariant,
} from '../../organisms/membershipCard'
import { WithWalletContainer } from '../../organisms/withWalletContainer'
import { PageTemplate } from '../../templates/page'
import { range } from '../../utils'

const EmptyDashboardState: React.FC = () => {
  const theme = useTheme()
  const navigateCreate = useNavigateCreate()
  return (
    <div className="w-full flex justify-center">
      <div className="flex flex-col items-center gap-6">
        <SubHeader color={theme.text.secondary}>
          You have not created any memberships yet
        </SubHeader>
        <Button variant="secondary" onPress={navigateCreate}>
          <SubHeader weight="bold" color={theme.text.primary}>
            Create Membership
          </SubHeader>
        </Button>
      </div>
    </div>
  )
}

const DashboardPage: React.FC = () => {
  const { campaigns, isLoading: isLoadingOwners } = useAllCampaignsByOwner()
  const { campaigns: referralCampaigns, isLoading: isLoadingReferrals } =
    useAllCampaignsByReferral()

  const navigateCreate = useNavigateCreate()
  const isLoading = isLoadingOwners || isLoadingReferrals
  return (
    <PageTemplate withFooter>
      <div className="py-10">
        <WithWalletContainer>
          <div className="flex justify-between items-center mb-10">
            <Title weight="bold">Dashboard</Title>
            <Button
              variant="primary"
              title="Create Membership"
              onPress={navigateCreate}
            />
          </div>
          {isLoading && (
            <div className="flex flex-col gap-6">
              {range(1, 2).map((i) => (
                <DashboardCardLoader key={i} />
              ))}
            </div>
          )}
          {!isLoading && !campaigns?.length && !referralCampaigns?.length && (
            <EmptyDashboardState />
          )}
          {(!!campaigns?.length || !!referralCampaigns?.length) && (
            <div className="flex flex-col gap-6">
              {campaigns?.map((campaign) => (
                <MembershipCard
                  key={campaign.campaignId}
                  id={campaign.campaignId}
                />
              ))}
              {referralCampaigns?.map((campaign) => (
                <MembershipCard
                  key={campaign.campaignId}
                  id={campaign.campaignId}
                  variant={MembershipCardVariant.referral}
                />
              ))}
            </div>
          )}
        </WithWalletContainer>
      </div>
    </PageTemplate>
  )
}

export default DashboardPage
