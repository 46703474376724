import clsx, { ClassValue } from 'clsx'
import React from 'react'
import { Body, Caption, useTheme } from '@tryrolljs/design-system'
import { InfoTooltip } from '../infoTooltip'

interface InputAction {
  isVisible: boolean
  title: string
  onPress: () => void
}
interface InputContainerProps {
  className?: {
    container?: ClassValue
    inputContainer?: ClassValue
  }
  label?: string
  tooltip?: string
  error?: string
  count?: [number, number]
  isDisabled?: boolean
  rightIcon?: React.ReactElement
  action?: InputAction
}

export const InputContainer: React.FC<InputContainerProps> = ({
  className,
  label,
  error,
  children,
  tooltip,
  count,
  isDisabled = false,
  rightIcon,
  action,
}) => {
  const theme = useTheme()
  return (
    <div className={clsx('flex flex-col gap-2', className?.container)}>
      <div className="flex items-center gap-2">
        <Body weight="bold" color={theme.text.primary}>
          {label}
        </Body>
        {tooltip && <InfoTooltip title={tooltip} />}
      </div>
      <div className="flex flex-col gap-1">
        <div
          className={clsx(
            'w-full px-2 py-2 pl-4 rounded-[14px] border border-[#CCC] flex justify-between',
            isDisabled && 'bg-[#EAEEF3] cursor-not-allowed',
            className?.inputContainer,
          )}
        >
          {children}
          {rightIcon && <div>{rightIcon}</div>}
        </div>
        {count && (
          <div className="mt-1">
            <Caption color={theme.text.secondary}>
              {count[0]}/{count[1]} characters
            </Caption>
          </div>
        )}
        <div className="flex justify-between items-center">
          {error && (
            <div>
              <Caption color={theme.text.error}>{error}</Caption>
            </div>
          )}
          {action?.isVisible && (
            <div onClick={action.onPress} className="cursor-pointer">
              <Caption color={theme.text.highlight}>{action.title}</Caption>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
