import React, { useCallback, useMemo } from 'react'
import {
  Caption,
  CircleImg,
  useModal,
  useTheme,
} from '@tryrolljs/design-system'
import clsx from 'clsx'
import ChevronIcon from '../../assets/svg/chevron-down.svg'
import { Token } from '../../types'
import { useFetchToken } from '../../hooks/tokens'
import { SelectTokenModal } from '../../organisms/selectTokenModal'

interface TokenSelectProps {
  value: string
  onSelect?: (value: Token) => void
  isPaymentToken?: boolean
  isDisabled?: boolean
  disabledTokens?: string[]
}

export const TokenSelect: React.FC<TokenSelectProps> = ({
  value,
  onSelect,
  isPaymentToken = false,
  isDisabled = false,
  disabledTokens = [],
}) => {
  const theme = useTheme()
  const { token: tokenInfo, isLoading } = useFetchToken(value)
  const { close, open, isOpen } = useModal()
  const tokenName = useMemo(() => {
    if (!value) return 'Select a token'
    if (tokenInfo) return tokenInfo.symbol
    if (isLoading) return 'Loading...'
    return 'Select token'
  }, [tokenInfo, isLoading, value])

  const onSelectToken = useCallback(
    (token: Token) => onSelect && onSelect(token),
    [onSelect],
  )

  return (
    <>
      <div
        className={clsx(
          'px-[10px] py-[7px] rounded-lg bg-[#EAEEF3]',
          !isDisabled && 'cursor-pointer',
        )}
        onClick={!isDisabled ? open : undefined}
      >
        <div className="flex gap-2 items-center">
          {value && <CircleImg size={16} uri={tokenInfo?.logoURI} />}
          <Caption color={theme.text.secondary}>{tokenName}</Caption>
          {!isDisabled && (
            <div className="w-2">
              <ChevronIcon />
            </div>
          )}
        </div>
      </div>
      <SelectTokenModal
        cb={onSelectToken}
        disabledTokens={disabledTokens}
        isPaymentToken={isPaymentToken}
        onCloseModal={close}
        isOpen={isOpen}
      />
    </>
  )
}
