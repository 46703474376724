import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  routeCampaignDetails,
  routeHome,
  routeDashboard,
  routeCreateCampaign,
  routeEditCampaign,
} from './routes'

export const useNavigateCampaignDetails = () => {
  const navigate = useNavigate()
  return useCallback(
    (address: string) => {
      navigate(routeCampaignDetails(address))
    },
    [navigate],
  )
}

export const useNavigateHome = () => {
  const navigate = useNavigate()
  return useCallback(() => {
    navigate(routeHome())
  }, [navigate])
}

export const useNavigateDashboard = () => {
  const navigate = useNavigate()
  return useCallback(() => {
    navigate(routeDashboard())
  }, [navigate])
}

export const useNavigateCreate = () => {
  const navigate = useNavigate()
  return useCallback(() => {
    navigate(routeCreateCampaign())
  }, [navigate])
}

export const useNavigateEditPage = () => {
  const navigate = useNavigate()
  return useCallback(
    (addr: string) => {
      navigate(routeEditCampaign(addr))
    },
    [navigate],
  )
}
