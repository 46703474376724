import React, { useMemo } from 'react'
import { Caption, SubHeader, useTheme } from '@tryrolljs/design-system'
import clsx from 'clsx'
import {
  CreateStepData,
  CreateStepsId,
  CreateStepStatus,
} from '../../types/create'
import ActiveStatus from '../../assets/svg/phase-progress-active.svg'
import DoneStatus from '../../assets/svg/phase-progress-done.svg'
import TodoStatus from '../../assets/svg/todo.svg'
import { useGoToStep } from '../../hooks/selectors/create'

interface CreateStepStatusIconProps {
  status: CreateStepStatus
}

const CreateStepStatusIcon: React.FC<CreateStepStatusIconProps> = ({
  status,
}) => {
  if (status === CreateStepStatus.active) return <ActiveStatus />
  if (status === CreateStepStatus.todo) return <TodoStatus />
  if (status === CreateStepStatus.done) return <DoneStatus />
  return null
}

interface CreateNavSidebarStepProps {
  step: CreateStepData
  activeId: CreateStepsId
  index: number
  activeIndex: number
}

const CreateNavSidebarStep: React.FC<CreateNavSidebarStepProps> = ({
  step,
  activeId,
  index,
  activeIndex,
}) => {
  const theme = useTheme()
  const goToStep = useGoToStep()

  const isDisabled =
    step.status !== CreateStepStatus.done || activeIndex < index

  const handleClick = () => {
    if (isDisabled) return
    goToStep({ go: step.id, current: activeId })
  }

  return (
    <li
      onClick={handleClick}
      className={clsx(
        'flex items-center gap-4',
        isDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
      )}
    >
      <CreateStepStatusIcon status={step.status} />
      <SubHeader
        weight="bold"
        color={
          step.status === CreateStepStatus.active
            ? theme.text.highlight
            : theme.text.primary
        }
      >
        {step.title}
      </SubHeader>
    </li>
  )
}

interface CreateNavSidebarProps {
  title: string
  steps: CreateStepData[]
}

export const CreateNavSidebar: React.FC<CreateNavSidebarProps> = ({
  title,
  steps,
}) => {
  const theme = useTheme()
  const [activeStepId, activeStepIndex] = useMemo(() => {
    let index = steps.findIndex(
      (step) => step.status === CreateStepStatus.active,
    )
    if (index < 0) index = 0
    return [steps[index].id, index]
  }, [steps])
  return (
    <div
      className="w-full relative border-r"
      style={{ borderColor: 'rgba(0, 0, 0, 0.1)' }}
    >
      <div className="flex fixed flex-col w-fit pl-20 py-10 gap-6">
        <SubHeader
          weight="bold"
          color="#CCC" // TODO: Add this color to the theme.
        >
          {title}
        </SubHeader>
        <ul className="flex flex-col gap-4 mb-6">
          {steps.map((step, index) => (
            <CreateNavSidebarStep
              key={step.id}
              index={index}
              step={step}
              activeId={activeStepId}
              activeIndex={activeStepIndex}
            />
          ))}
        </ul>
        <Caption weight="bold" color={theme.text.secondary}>
          Learn more about Roll Memberships
        </Caption>
      </div>
    </div>
  )
}
