import React, { useMemo } from 'react'
import {
  Body,
  Button,
  Caption,
  SubHeader,
  useTheme,
  useEthAddress,
  useWeb3Conntectors,
  Spinner,
} from '@tryrolljs/design-system'
import { useConnectWalletModal } from '../../hooks/web3'
import { TokenTitle } from '../../molecules/tokenTitle'
import { AmountToken } from '../../molecules/amountToken'
import { LotsClaimedProgress } from '../../molecules/lotsClaimedProgress'
import { formatMetadata } from '../../core/phases'
import {
  getAllTokenLotSize,
  getAllTokensInCampaign,
} from '../../core/campaigns'
import { useCampaignByAddressSelector } from '../../hooks/selectors/campaign'
import { InfoTooltip } from '../../molecules/infoTooltip'
import ExportIcon from '../../assets/svg/export.svg'
import { Tiers } from '../tiers/tiers'

interface MintProps {
  campaignId: string
}

export const Mint: React.FC<MintProps> = ({ campaignId }) => {
  const theme = useTheme()
  const address = useEthAddress()
  const campaign = useCampaignByAddressSelector(campaignId)
  const metadata = useMemo(
    () => formatMetadata(campaign?.metadata ?? ''),
    [campaign],
  )

  const tiers = useMemo(() => metadata?.tiers ?? [], [metadata])

  const tokens = useMemo(() => getAllTokensInCampaign(campaign), [campaign])

  const tokensLostSize = useMemo(() => getAllTokenLotSize(campaign), [campaign])

  if (!campaign) return null

  return (
    <div className="flex justify-center">
      <div className="flex flex-col items-start w-full">
        <div className="flex items-center mb-2">
          <TokenTitle tokens={tokens} />
        </div>
        <div className="flex flex-col gap-10 w-full">
          <div className="flex flex-col gap-2">
            <Body color={theme.text.primary}>
              {metadata?.description ?? ''}
            </Body>
            {metadata?.website && (
              <a href={metadata?.website} target="_blank" rel="noreferrer">
                <Caption weight="semiBold" color={theme.text.highlight}>
                  <div className="flex items-center">
                    Website{' '}
                    <span>
                      <ExportIcon />
                    </span>
                  </div>
                </Caption>
              </a>
            )}
          </div>
          <div>
            <div className="flex justify-between items-start mb-2 w-full">
              <div className="flex items-center gap-2">
                <SubHeader weight="semiBold" color={theme.text.primary}>
                  Tokens in each lot
                </SubHeader>
                <InfoTooltip title="Lots are groups of tokens in equal amounts that users can claim. More lots means more perks. Claim lots below to start getting membership benefits." />
              </div>
              <ul className="flex flex-col items-end gap-1">
                {tokensLostSize.map((elem) => (
                  <AmountToken
                    key={elem.addr}
                    bigTokenAmount={elem.amount}
                    address={elem.addr}
                  />
                ))}
              </ul>
            </div>
            <div className="w-full">
              <LotsClaimedProgress campaignId={campaign.campaignId} />
            </div>
          </div>
          {!!tiers.length && <Tiers tiers={tiers} />}
        </div>
        {!address && <ConnectToClaim />}
      </div>
    </div>
  )
}

const ConnectToClaim = () => {
  const connectWallet = useConnectWalletModal()
  const { isActivating } = useWeb3Conntectors()
  if (isActivating) {
    return <Spinner />
  }
  return (
    <Button
      title="Connect Wallet to Claim"
      onPress={connectWallet}
      variant="secondary"
    />
  )
}
