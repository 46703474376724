import { BigNumber } from 'ethers'
import React, { useMemo } from 'react'
import { Caption, FONT_SIZE_CAPTION, useTheme } from '@tryrolljs/design-system'
import { useCampaignByAddressSelector } from '../../hooks/selectors/campaign'
import { AmountToken } from '../amountToken'

export const TokenQuantity: React.FC<{ campaignId: string }> = ({
  campaignId,
}) => {
  const campaign = useCampaignByAddressSelector(campaignId)
  const theme = useTheme()
  const data = useMemo(() => {
    if (!campaign?.phasesData) return
    const data: Record<string, { total: BigNumber; released: BigNumber }> = {}
    campaign.phasesData.forEach((elem) => {
      elem.lotToken.forEach((token, i) => {
        data[token] = {
          total: (data[token]?.total ?? BigNumber.from(0)).add(
            elem.lotSize[i].mul(BigNumber.from(elem.amountTotal)),
          ),
          released: (data[token]?.released ?? BigNumber.from(0)).add(
            elem.lotSize[i].mul(BigNumber.from(elem.released)),
          ),
        }
      })
    })
    return data
  }, [campaign])
  if (!data) return null
  return (
    <div className="flex gap-14">
      <div className="flex flex-col gap-2">
        <Caption weight="semiBold" color={theme.text.primary}>
          Total amount of tokens
        </Caption>
        <ul className="flex flex-col gap-1">
          {Object.entries(data).map(([addr, value]) => (
            <li key={addr}>
              <AmountToken
                address={addr}
                bigTokenAmount={value.total}
                circleSize={16}
                fontSize={FONT_SIZE_CAPTION}
                color={theme.text.secondary}
              />
            </li>
          ))}
        </ul>
      </div>
      <div className="flex flex-col gap-2">
        <Caption weight="semiBold" color={theme.text.primary}>
          Remaining amount of tokens
        </Caption>
        <ul className="flex flex-col gap-1">
          {Object.entries(data).map(([addr, value]) => (
            <li key={addr}>
              <AmountToken
                address={addr}
                bigTokenAmount={value.total.sub(value.released)}
                circleSize={16}
                fontSize={FONT_SIZE_CAPTION}
                color={theme.text.secondary}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
