import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MerkleTrees } from '../../types'

export interface BuyState {
  numberOfLots: number
  phaseId: string
  maxLots: number
  merkleRoot: string
  merkleTreeLeaves: MerkleTrees | null
  isLoadingLeaves: boolean
}

const initialState: BuyState = {
  phaseId: '',
  maxLots: 0,
  numberOfLots: 0,
  merkleRoot: '',
  merkleTreeLeaves: null,
  isLoadingLeaves: false,
}

export const campaignSlice = createSlice({
  name: 'buy',
  initialState,
  reducers: {
    setPhase: (state, action: PayloadAction<{ id: string; root: string }>) => {
      state.phaseId = action.payload.id
      state.merkleRoot = action.payload.root
    },
    setMerkleTreeLeaves: (state, action: PayloadAction<MerkleTrees>) => {
      state.merkleTreeLeaves = action.payload
    },
    setIsLoadingLeaves: (state, action: PayloadAction<boolean>) => {
      state.isLoadingLeaves = action.payload
    },
    setMaxLots: (state, action: PayloadAction<number>) => {
      state.maxLots = action.payload
    },
    setNumberOfLots: (state, action: PayloadAction<number>) => {
      if (action.payload <= state.maxLots) {
        state.numberOfLots = action.payload
      }
    },
    increaseLots: (state) => {
      if (state.numberOfLots < state.maxLots) {
        state.numberOfLots++
      }
    },
    decreaseLots: (state) => {
      if (state.numberOfLots > 1) {
        state.numberOfLots--
      }
    },
    resetAfterBuy: (state) => {
      state.numberOfLots = 0
      state.merkleRoot = ''
      state.maxLots = 0
      state.phaseId = ''
    },
    resetBuyState: () => initialState,
  },
})

export const {
  setPhase,
  setMaxLots,
  increaseLots,
  decreaseLots,
  setNumberOfLots,
  resetBuyState,
  setMerkleTreeLeaves,
  setIsLoadingLeaves,
  resetAfterBuy,
} = campaignSlice.actions

export default campaignSlice.reducer
