import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../..'
import {
  resetAfterBuy,
  resetBuyState,
  setIsLoadingLeaves,
  setMaxLots,
  setMerkleTreeLeaves,
  setNumberOfLots,
  setPhase,
} from '../../../state/buy/reducer'
import { MerkleTrees } from '../../../types'

export const usePhaseIdSelector = () => {
  return useAppSelector((state) => state.buy.phaseId)
}

export const useMerkleRootSelector = () => {
  return useAppSelector((state) => state.buy.merkleRoot)
}

export const useMerkleTreeLeavesSelector = () => {
  return useAppSelector((state) => state.buy.merkleTreeLeaves)
}

export const useIsLoadingLeavesSelector = () => {
  return useAppSelector((state) => state.buy.isLoadingLeaves)
}

export const useNumberOfLotsSelector = () => {
  return useAppSelector((state) => state.buy.numberOfLots)
}

export const useMaxLotsSelector = () => {
  return useAppSelector((state) => state.buy.maxLots)
}

// Actions
export const useResetBuyState = () => {
  const dispatch = useAppDispatch()
  const resetBuyState_ = useCallback(() => {
    return dispatch(resetBuyState())
  }, [dispatch])
  return resetBuyState_
}

export const useResetAfterBuy = () => {
  const dispatch = useAppDispatch()
  const resetAfterBuy_ = useCallback(() => {
    return dispatch(resetAfterBuy())
  }, [dispatch])
  return resetAfterBuy_
}

export const useSetPhase = () => {
  const dispatch = useAppDispatch()
  const setPhase_ = useCallback(
    (props: { id: string; root: string }) => {
      return dispatch(setPhase(props))
    },
    [dispatch],
  )
  return setPhase_
}

export const useSetMaxLots = () => {
  const dispatch = useAppDispatch()
  const setMaxLots_ = useCallback(
    (value: number) => {
      return dispatch(setMaxLots(value))
    },
    [dispatch],
  )
  return setMaxLots_
}

export const useSetNumberOfLots = () => {
  const dispatch = useAppDispatch()
  const setNumberOfLots_ = useCallback(
    (value: number) => {
      return dispatch(setNumberOfLots(value))
    },
    [dispatch],
  )
  return setNumberOfLots_
}

export const useSetMerkleTreeLeaves = () => {
  const dispatch = useAppDispatch()
  const setMerkleTreeLeaves_ = useCallback(
    (value: MerkleTrees) => {
      return dispatch(setMerkleTreeLeaves(value))
    },
    [dispatch],
  )
  return setMerkleTreeLeaves_
}

export const useSetIsLoadingLeaves = () => {
  const dispatch = useAppDispatch()
  const setMerkleTreeLeaves_ = useCallback(
    (value: boolean) => {
      return dispatch(setIsLoadingLeaves(value))
    },
    [dispatch],
  )
  return setMerkleTreeLeaves_
}
