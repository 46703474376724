import React, { useCallback } from 'react'
import { useFetchToken } from '../../hooks/tokens'
import { toastError, toastSuccess } from '../../molecules/toasts'

interface AddToWalletProps {
  address: string
}

export const AddToWallet: React.FC<AddToWalletProps> = ({
  address,
  children,
}) => {
  const { token, isLoading } = useFetchToken(address)

  const handleClick = useCallback(async () => {
    if (isLoading || !token || !window.ethereum) return
    try {
      await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: token.address,
            symbol: token.symbol,
            decimals: token.decimals,
            image: token.logoURI,
          },
        },
      })
      toastSuccess({ description: `$${token.symbol} added to MetaMask` })
    } catch (error) {
      toastError({ description: `Couldn't add $${token.symbol} to MetaMask` })
    }
  }, [token, isLoading])

  return (
    <div onClick={handleClick} className="cursor-pointer">
      {children}
    </div>
  )
}
