import { formatMetadata } from '../../core/phases'
import { useMountBuy, useSetBuyLeaves } from '../../hooks/phases'
import {
  useIsLoadingLeavesSelector,
  usePhaseIdSelector,
} from '../../hooks/selectors/buy'
import { useCampaignByAddressSelector } from '../../hooks/selectors/campaign'
import { LotCardLoader } from '../../molecules/loaders'
import { LotCard } from '../../molecules/lotCard'
import { range } from '../../utils'
import { BuySection } from '../buySection'

interface BuyProps {
  campaignId: string
}

export const Buy: React.FC<BuyProps> = ({ campaignId }) => {
  const campaign = useCampaignByAddressSelector(campaignId)
  const phaseId = usePhaseIdSelector()
  const isLoadingLeaves = useIsLoadingLeavesSelector()
  const allowlistHash = campaign
    ? formatMetadata(campaign.metadata)?.merkleTreesHash ?? ''
    : ''
  useSetBuyLeaves({ campaignId })
  useMountBuy({ campaignId })

  return (
    <div className="flex flex-col gap-16">
      <div className="mb-4 w-full flex flex-col gap-3">
        {isLoadingLeaves && range(1, 3).map((i) => <LotCardLoader key={i} />)}
        {!isLoadingLeaves &&
          campaign?.phasesData?.map((phase) => (
            <LotCard
              key={phase.phaseId}
              phase={phase}
              allowlistHash={allowlistHash}
            />
          ))}
      </div>
      {!!phaseId && !isLoadingLeaves && <BuySection campaignId={campaignId} />}
    </div>
  )
}
