import React from 'react'
import { Caption, Header, useTheme } from '@tryrolljs/design-system'

export type TStats = {
  value: string
  description: string
}

interface StatsInfoCardProps {
  description: string
  stats: TStats[]
}

export const StatsInfoCard: React.FC<StatsInfoCardProps> = ({
  description,
  stats,
}) => {
  const theme = useTheme()
  return (
    <div className="flex flex-col gap-2 items-end">
      <Caption color={theme.text.secondary} weight="semiBold">
        {description}
      </Caption>
      <div
        style={{ backgroundColor: theme.background.page }}
        className="rounded-2xl py-3 px-4 grid grid-cols-3 gap-[18px]"
      >
        {stats.map((stat, i) => (
          <div className="flex justify-between" key={`${stat.value}-${i}`}>
            <div className="w-full flex flex-col gap-2">
              <Header weight="semiBold" color={theme.text.primary}>
                {stat.value}
              </Header>
              <Caption color={theme.text.primary}>{stat.description}</Caption>
            </div>
            {i < stats.length - 1 && (
              <div className="h-full w-px bg-gray-400" />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
