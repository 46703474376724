import { useContext, useMemo } from 'react'
import {
  useChainID,
  CHAIN_ID_MAIN_NET,
  CHAIN_ID_GOERLI,
  CHAIN_ID_ROPSTEN,
  CHAIN_ID_MUMBAI,
  CHAIN_ID_HARDHAT,
  CHAIN_ID_POLYGON,
} from '@tryrolljs/design-system'
import { config } from '../config'
import { WalletConnectModalContext } from '../providers/walletConnectModal'
import { CHAIN_ID_FORM_TEST_NET } from '../contracts/constants'

export const useConnectWalletModal = () => {
  const context = useContext(WalletConnectModalContext)
  return context.connectWallet
}

export const useNetworkConfig = () => {
  const chainID = useChainID()

  return useMemo(() => {
    switch (chainID) {
      case CHAIN_ID_MAIN_NET:
        return config.NETWORK_MAINNET
      case CHAIN_ID_GOERLI:
        return config.NETWORK_GOERLI
      case CHAIN_ID_ROPSTEN:
        return config.NETWORK_ROPSTEN
      case CHAIN_ID_MUMBAI:
        return config.NETWORK_MUMBAI
      case CHAIN_ID_POLYGON:
        return config.NETWORK_POLYGON
      case CHAIN_ID_HARDHAT:
        return config.NETWORK_LOCAL
      case CHAIN_ID_FORM_TEST_NET:
        return config.NETWORK_FORM_TESTNET
      default:
        return config.NETWORK_LOCAL
    }
  }, [chainID])
}
