import clsx from 'clsx'
import { BigNumber, ethers } from 'ethers'
import React, { useCallback, useMemo } from 'react'
import { Button, Caption, SubHeader, useTheme } from '@tryrolljs/design-system'
import { useBuyPhase } from '../../hooks/phases'
import {
  useMerkleRootSelector,
  useNumberOfLotsSelector,
  usePhaseIdSelector,
} from '../../hooks/selectors/buy'
import {
  useApproveToken,
  useFetchToken,
  useTokenAllowance,
  useTokenBalanceUser,
} from '../../hooks/tokens'
import { AllowanceSpenders } from '../../types'
import { BuyPhaseProps } from '../../contracts/phases'
import { isNativeToken } from '../../core/tokens'

interface BuyBalanceProps {
  tokenAddr: string
  totalToPay: BigNumber
  isDisabled?: boolean
}

export const BuyBalance: React.FC<BuyBalanceProps> = ({
  tokenAddr,
  totalToPay,
  isDisabled = false,
}) => {
  const theme = useTheme()

  const { token } = useFetchToken(tokenAddr)
  const { isLoading: isApproving, approveToken } = useApproveToken()
  const { isLoadingAllowance, allowance } = useTokenAllowance(
    AllowanceSpenders.membershipsImpl,
    token,
  )
  const { balance, formatBalance, isLoadingBalance } =
    useTokenBalanceUser(token)
  const phaseId = usePhaseIdSelector()
  const merkleRoot = useMerkleRootSelector()
  const numberOfLots = useNumberOfLotsSelector()
  const { buyPhase, isLoading: isBuying } = useBuyPhase()

  const enoughBalance = useMemo(() => {
    return token && balance.gte(totalToPay)
  }, [token, balance, totalToPay])

  const enoughAllowance = useMemo(() => {
    if (!token) return false
    if (isNativeToken(token.address)) return true
    return allowance.gte(totalToPay)
  }, [token, allowance, totalToPay])

  const isBuyDisabled = useMemo(() => {
    return (
      isDisabled ||
      isBuying ||
      isLoadingBalance ||
      !enoughBalance ||
      isLoadingAllowance ||
      isApproving
    )
  }, [
    isDisabled,
    isBuying,
    isLoadingAllowance,
    isApproving,
    isLoadingBalance,
    enoughBalance,
  ])

  const buttonText = useMemo(() => {
    if (!enoughAllowance) return `Approve ${token?.name ?? ''}`
    return 'Claim now'
  }, [enoughAllowance, token])

  const handleBuy = useCallback(async () => {
    if (!token) return
    if (!enoughAllowance) {
      return approveToken({
        tokenId: token.address,
        value: totalToPay,
        spender: AllowanceSpenders.membershipsImpl,
        formatValue: ethers.utils.formatUnits(totalToPay, token.decimals),
        tokenSymbol: token.symbol,
        isBuy: true,
      })
    }

    const params: BuyPhaseProps = {
      phaseId,
      paymentAddr: token.address,
      tokenName: token.name,
      lots: numberOfLots,
      totalToPay,
    }

    return buyPhase({ ...params, root: merkleRoot })
  }, [
    token,
    enoughAllowance,
    approveToken,
    buyPhase,
    totalToPay,
    numberOfLots,
    phaseId,
    merkleRoot,
  ])

  return (
    <div className="flex flex-col items-end gap-2">
      <div
        className={clsx(
          isBuyDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
        )}
      >
        <Button variant="primary" onPress={handleBuy} disabled={isBuyDisabled}>
          <SubHeader weight="bold" color={theme.background.page}>
            {buttonText}
          </SubHeader>
        </Button>
      </div>
      <Caption color={enoughBalance ? theme.text.secondary : theme.text.error}>
        Balance: {formatBalance} {token?.symbol}
      </Caption>
    </div>
  )
}
