export enum ErrorCode {
  ErrorME01InvalidFee = 'ErrorME01InvalidFee',
  ErrorME02TokenNotAllowed = 'ErrorME02TokenNotAllowed',
  ErrorME03NotEnoughTokens = 'ErrorME03NotEnoughTokens',
  ErrorME04NotEnoughPhases = 'ErrorME04NotEnoughPhases',
  ErrorME05OnlyOwnerAllowed = 'ErrorME05OnlyOwnerAllowed',
  ErrorME06ScheduleDoesNotExists = 'ErrorME06ScheduleDoesNotExists',
  ErrorME07ScheduleRevoked = 'ErrorME07ScheduleRevoked',
  ErrorME08ScheduleNotActive = 'ErrorME08ScheduleNotActive',
  ErrorME09ScheduleNotFinished = 'ErrorME09ScheduleNotFinished',
  ErrorME10ActionAllowlisted = 'ErrorME10ActionAllowlisted',
  ErrorME11TransferError = 'ErrorME11TransferError',
  ErrorME12IndexOutOfBounds = 'ErrorME12IndexOutOfBounds',
  ErrorME13InvalidAddress = 'ErrorME13InvalidAddress',
  ErrorME14BetaPeriodAlreadyFinish = 'ErrorME14BetaPeriodAlreadyFinish',
  ErrorME15InvalidDate = 'ErrorME15InvalidDate',
  ErrorME16InvalidDuration = 'ErrorME16InvalidDuration',
  ErrorME17InvalidPrice = 'ErrorME17InvalidPrice',
  ErrorME18LotArrayLengthMismatch = 'ErrorME18LotArrayLengthMismatch',
  ErrorME19NotEnoughEth = 'ErrorME19NotEnoughEth',
  ErrorME20InvalidReferral = 'ErrorME20InvalidReferral',
  ErrorME21InvalidReferralFee = 'ErrorME21InvalidReferralFee',
  ErrorME22MaxBuyPerWalletExceeded = 'ErrorME22MaxBuyPerWalletExceeded',
  ErrorME23TotalClaimedError = 'ErrorME23TotalClaimedError',
  ErrorME24InvalidProof = 'ErrorME24InvalidProof',
  ErrorME25ScheduleNotFinishedOrSoldOut = 'ErrorME25ScheduleNotFinishedOrSoldOut',
  ErrorME26OnlyMembershipsImpl = 'ErrorME26OnlyMembershipsImpl',
  ErrorME27TotalAmountExceeded = 'ErrorME27TotalAmountExceeded',
  ErrorME28InvalidAmount = 'ErrorME28InvalidAmount',
  ErrorME29InvalidMaxBuyPerWallet = 'ErrorME29InvalidMaxBuyPerWallet',
}

export const errorMessages: Record<ErrorCode, string> = {
  ErrorME01InvalidFee: 'Error',
  ErrorME02TokenNotAllowed:
    'Token not supported, please choose a different one',
  ErrorME03NotEnoughTokens: 'Insufficient fund in wallet',
  ErrorME04NotEnoughPhases: 'Please create at least 1 phase',
  ErrorME05OnlyOwnerAllowed: 'Error',
  ErrorME06ScheduleDoesNotExists: 'Error',
  ErrorME07ScheduleRevoked: 'Error',
  ErrorME08ScheduleNotActive: 'This phase is not active anymore',
  ErrorME09ScheduleNotFinished: 'Please claim after the phase has ended',
  ErrorME10ActionAllowlisted: 'You are not whitelisted in this phase',
  ErrorME11TransferError: 'Error',
  ErrorME12IndexOutOfBounds: 'Error',
  ErrorME13InvalidAddress: 'Error',
  ErrorME14BetaPeriodAlreadyFinish: 'Error',
  ErrorME15InvalidDate: 'Please select a time in the future',
  ErrorME16InvalidDuration: 'End day should be later than start day',
  ErrorME17InvalidPrice: 'Price per lot should be greater than 0',
  ErrorME18LotArrayLengthMismatch: 'Error',
  ErrorME19NotEnoughEth: 'Insufficient fund in wallet',
  ErrorME20InvalidReferral: 'Wallet address not valid',
  ErrorME21InvalidReferralFee:
    'Please enter a value higher than 0 and lower than 100 for referral fee',
  ErrorME22MaxBuyPerWalletExceeded: 'Max lots per wallet reached',
  ErrorME23TotalClaimedError: 'You have already claimed fees for this phase',
  ErrorME24InvalidProof: 'You are not whitelisted in this phase',
  ErrorME25ScheduleNotFinishedOrSoldOut:
    'Please claim fees after the phase has ended or sold out',
  ErrorME26OnlyMembershipsImpl: 'Error',
  ErrorME27TotalAmountExceeded: 'Total amount of lots exeeded',
  ErrorME28InvalidAmount: 'Amount of lots should be greater than 0',
  ErrorME29InvalidMaxBuyPerWallet:
    'Max buy per wallet should be greater than 0',
}

const errorCodes = Object.keys(errorMessages)

type ErrorWithMessage = {
  code?: string
  message: string
}

type ErrorWithReason = {
  code?: string
  reason: string
}

const isErrorWithMessage = (error: unknown): error is ErrorWithMessage => {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string'
  )
}

const toErrorWithMessage = (maybeError: unknown): ErrorWithMessage => {
  if (isErrorWithMessage(maybeError)) return maybeError

  try {
    return new Error(JSON.stringify(maybeError))
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError))
  }
}

const isErrorWithReason = (error: unknown): error is ErrorWithReason => {
  return (
    typeof error === 'object' &&
    error !== null &&
    'reason' in error &&
    typeof (error as Record<string, unknown>).reason === 'string'
  )
}

const getCodeMessageFromError = (error: ErrorWithReason) => {
  const codeArray = error.reason.match(/"([^"]*)"|'([^']*)'/g)
  if (!codeArray) return
  const [code] = codeArray
  let formatCode = code.replaceAll("'", '')
  formatCode = formatCode.replaceAll('()', '')
  if (!formatCode || !errorCodes.includes(formatCode)) {
    return {
      code: '',
      message: error.reason,
    }
  }
  return {
    code: formatCode as ErrorCode,
    message: errorMessages[formatCode as ErrorCode],
  }
}

const getCodeFromError = (error: ErrorWithMessage) => {
  if (!errorCodes.includes(error.message)) return
  return {
    code: error.message as ErrorCode,
    message: errorMessages[error.message as ErrorCode],
  }
}

/**
 * Handle errors from contract interactions.
 * @param {unknown} error - error object from contract.
 * @returns {ErrorWithMessage} An error object with message prop.
 */
export const getContractError = (error: unknown): ErrorWithMessage => {
  if (isErrorWithReason(error)) {
    const ethError = getCodeMessageFromError(error)
    if (ethError) return ethError
  }
  if (isErrorWithMessage(error)) {
    const ethError = getCodeFromError(error)
    if (ethError) return ethError
  }
  return toErrorWithMessage(error)
}

/**
 * Handle all errors from app.
 * Note: For contract error use getContractError instead.
 * @param {unknown} error - error object
 * @returns {string} Error message
 */
export function getErrorMessage(error: unknown): string {
  return toErrorWithMessage(error).message
}
