import clsx from 'clsx'
import { ethers } from 'ethers'
import React, { useMemo } from 'react'
import { useTheme, margin, Caption, Tag } from '@tryrolljs/design-system'
import { useFetchToken } from '../../hooks/tokens'
import { Phase, StatusPhase, TimeLabel } from '../../types'
import {
  checkIsPhasePublic,
  getPhaseTime,
  getStatusPhase,
  getTimeLabel,
} from '../../core/phases'
import { TimeCounter } from '../timeCounter'
import {
  usePhaseIdSelector,
  useSetMaxLots,
  useSetNumberOfLots,
  useSetPhase,
} from '../../hooks/selectors/buy'
import { formatNumber } from '../../utils'
import ReceiptSearch from '../../assets/svg/receipt-search.svg'
import { getPinataURL } from '../../utils/pinata'

interface LotCardProps {
  phase: Phase
  allowlistHash?: string
}

export const LotCard: React.FC<LotCardProps> = ({ phase, allowlistHash }) => {
  const theme = useTheme()
  const { token } = useFetchToken(phase.paymentAsset.token)
  const selectedPhaseId = usePhaseIdSelector()
  const setPhase = useSetPhase()
  const setNumberOfLots = useSetNumberOfLots()
  const setMaxLots = useSetMaxLots()
  const status = getStatusPhase(phase)
  const isPhasePublic = checkIsPhasePublic(phase.merkleRoot)
  const tagTitle = isPhasePublic ? 'Public' : 'Allowlist'
  const lots = phase.amountTotal
  const lotsPerWallet = phase.maxBuyPerWallet
  const price = ethers.utils.formatUnits(phase.pricePerLot, token?.decimals)
  const claimedQuantity = phase.released

  const timeLabel = useMemo(() => getTimeLabel(phase), [phase])

  const hasMaxLotsPerWallet = lotsPerWallet !== lots

  const isDisabled = status !== StatusPhase.active || claimedQuantity >= lots

  const onClickHandler = () => {
    if (!isDisabled) {
      setPhase({ id: phase.phaseId, root: phase.merkleRoot })
      setMaxLots(phase.maxBuyPerWallet)
      setNumberOfLots(1)
    }
  }

  return (
    <div
      // eslint-disable-next-line react-native/no-inline-styles
      style={{
        borderColor:
          selectedPhaseId === phase.phaseId ? theme.text.highlight : '',
      }}
      className={clsx(
        'border rounded-xl p-3 w-full',
        isDisabled ? 'cursor-default' : 'cursor-pointer',
      )}
      onClick={onClickHandler}
    >
      <div className="flex w-full justify-between items-center mb-4">
        <div className="flex items-center gap-4">
          <Tag title={tagTitle} color={theme.background.secondary} />
          {!isPhasePublic && allowlistHash && (
            <a
              href={getPinataURL(allowlistHash)}
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex items-center gap-1 cursor-pointer">
                <ReceiptSearch />
                <Caption weight="regular" color={theme.text.highlight}>
                  View list
                </Caption>
              </div>
            </a>
          )}
        </div>
        <div className="flex items-center">
          <TimeCounter
            label={timeLabel}
            time={getPhaseTime(phase)}
            showTime={timeLabel !== TimeLabel.soldOut}
          />
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div>
          <Caption style={margin.mr4}>{`${formatNumber(lots)} Lots •`}</Caption>
          {hasMaxLotsPerWallet && (
            <Caption style={margin.mr4}>{`Max ${formatNumber(
              lotsPerWallet,
            )} Lots Per Wallet •`}</Caption>
          )}
          <Caption style={margin.mr4}>{`Price ${price} ${
            token?.symbol ?? ''
          }`}</Caption>
        </div>
        {status === StatusPhase.active && (
          <Caption color={theme.text.secondary}>
            {`Claimed: [${claimedQuantity}/${lots}]`}
          </Caption>
        )}
      </div>
    </div>
  )
}
