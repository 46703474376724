import { BigNumber } from 'ethers'
import { Campaign, contractCampaign, Phase, StatusPhase } from '../../types'
import { getStatusPhase } from '../phases'

/**
 * Use it to get the sum of all lots per phases.
 */
export const getTotalLots = (campaign?: Campaign): number => {
  if (!campaign?.phasesData) return 0
  return campaign.phasesData.reduce((acc, curr) => acc + curr.amountTotal, 0)
}

/**
 * Get the lot size of each token used in a campaign
 */
export const getAllTokenLotSize = (
  campaign?: Campaign,
): { addr: string; amount: BigNumber }[] => {
  if (!campaign?.phasesData) return []
  // * Is using only the first phase because this is what we have in the UI.
  // TODO: Check if we should show them all.
  return campaign.phasesData[0].lotToken.map((addr, i) => ({
    addr,
    amount: campaign.phasesData?.[0].lotSize[i] ?? BigNumber.from(0),
  }))
}

/**
 * Use to get a list of token lots without repetitions.
 */
export const getAllTokensInCampaign = (campaign?: Campaign): string[] => {
  if (!campaign?.phasesData) return []
  return campaign.phasesData
    ?.flatMap((elem) => elem.lotToken)
    .reduce(
      (acc, curr) => (acc.includes(curr) ? acc : [...acc, curr]),
      [] as string[],
    )
}

/**
 * Use to get the closes phase in a campaign.
 * If all phases are done it returns null
 * Else it returns the active or the closest locked phase.
 * @param campaign - Campaign to get the closest phase
 */
export const getClosestPhase = (campaign?: Campaign): Phase | null => {
  // 1. Check if phases exist
  if (!campaign?.phasesData) return null
  // 2. Define the return value
  // closestPhase: will be null only if all phases are done.
  // closestPhase: will be the active face or the closes phase to come if any.
  let closestPhase: Phase | null = null
  // 3. Loop over phases
  campaign.phasesData.forEach((phase) => {
    const closestPhaseStatus = closestPhase
      ? getStatusPhase(closestPhase)
      : StatusPhase.done
    const phaseStatus = getStatusPhase(phase)
    // 3.1. check if closest phase is already active in that case skip.
    if (closestPhaseStatus === StatusPhase.active) return
    // 3.2 Check if current phase is donde if true skip.
    if (phaseStatus === StatusPhase.done || phaseStatus === StatusPhase.soldOut)
      return
    // 3.3 Check if current phase is active if true return curren phase.
    if (phaseStatus === StatusPhase.active) {
      closestPhase = phase
      return
    }
    // 3.4 Check if closest phase is already locked
    if (closestPhase && closestPhaseStatus === StatusPhase.locked) {
      // 3.4.1 Check if current phase is before closest phase if true return phase
      if (phase.start < closestPhase.start) {
        closestPhase = phase
        return
      }
    }
    // 3.5 return phase (locked) only if closestPhase is still null
    if (!closestPhase) {
      closestPhase = phase
    }
  })
  // 4. return closest phase
  return closestPhase
}

export const isFullSoldOut = (campaign?: Campaign): boolean => {
  if (!campaign?.phasesData) return false
  return campaign.phasesData.every(
    (phase) => phase.amountTotal === phase.released,
  )
}

/**
 * Use to format a campaign from contract to use in the app.
 */
export const formatCampaign = (
  val: contractCampaign,
  phases: Phase[],
): Campaign => {
  return {
    campaignId: val.campaignId,
    metadata: val.metadata,
    phases: val.phases,
    phasesData: phases,
  }
}
