import { BigNumber } from 'ethers'
import { FEE_SCALE } from '../../contracts/constants'
import { ErrorCode } from '../../contracts/errors'
import {
  CreateFormInputLots,
  CreateFormInputMetadata,
  CreateFormInputMetadataTier,
  CreateFormInputState,
  CreateMintingScheduleParamsStruct,
} from '../../types'
import { isAddressZero } from '../tokens'

export function checkFormInputState(obj: any): obj is CreateFormInputState {
  const properties = [
    'metadata',
    'lotInfo',
    'schedules',
    'fees',
    'paymentAsset',
  ]
  const hasAllProperties = properties.every((elem) => !!obj[elem])
  if (!hasAllProperties) return false
  if (!checkMetadata(obj.metadata)) return false
  const hasLots =
    !!obj.lotInfo &&
    Array.isArray(obj.lotInfo) &&
    obj.lotInfo.every(checkLotInfo)
  if (!hasLots) return false
  const hasSchedules =
    !!obj.schedules &&
    Array.isArray(obj.schedules) &&
    obj.schedules.every(checkSchedule)
  if (!hasSchedules) return false
  if (!checkFees(obj.fees)) return false
  return true
}

export function checkMetadata(obj: any): obj is CreateFormInputMetadata {
  const properties = ['website', 'description']
  const isMetadata = properties.every((elem) => !!obj[elem])
  if (!isMetadata) return false
  if (!obj.tiers && Array.isArray(obj.tiers)) return false
  return obj.tiers.every(checkTier)
}

function checkTier(obj: any): obj is CreateFormInputMetadataTier {
  const properties = ['name', 'lots', 'description']
  return properties.every((elem) => !!obj[elem])
}

function checkLotInfo(obj: any): obj is CreateFormInputLots {
  const properties = ['lotToken', 'lotSize']
  return properties.every((elem) => !!obj[elem])
}
function checkSchedule(obj: any): obj is CreateFormInputLots {
  const properties = [
    'startDate',
    'startTime',
    'endDate',
    'endTime',
    'pricePerLot',
    'amountTotal',
  ]
  return properties.every((elem) => !!obj[elem])
}
function checkFees(obj: any): obj is CreateFormInputLots {
  return 'referral' in obj && 'referralFee' in obj
}

const MINIMUN_SECONDS_INTERVAL = 300

export const validateCreate = (phases: CreateMintingScheduleParamsStruct[]) => {
  phases.forEach((phase) => {
    if (phase.start < new Date().getTime() / 1000 + MINIMUN_SECONDS_INTERVAL) {
      throw new Error(ErrorCode.ErrorME15InvalidDate)
    }
    if (phase.duration <= 0) {
      throw new Error(ErrorCode.ErrorME16InvalidDuration)
    }
    if (phase.pricePerLot <= 0) {
      throw new Error(ErrorCode.ErrorME17InvalidPrice)
    }
    if (phase.amountTotal <= 0) {
      throw new Error(ErrorCode.ErrorME28InvalidAmount)
    }
    if (BigNumber.from(phase.referralFee).add(phase.rollFee).gte(FEE_SCALE)) {
      throw new Error(ErrorCode.ErrorME21InvalidReferralFee)
    }
    if (!isAddressZero(phase.referral) && !phase.referralFee) {
      throw new Error(ErrorCode.ErrorME21InvalidReferralFee)
    }
    if (phase.maxBuyPerWallet <= 0) {
      throw new Error(ErrorCode.ErrorME29InvalidMaxBuyPerWallet)
    }
  })
}
