import clsx from 'clsx'
import * as React from 'react'

interface ToggleProps {
  isActive: boolean
  isDisabled?: boolean
  onClick: () => void
}

export const Toggle: React.FC<ToggleProps> = ({
  isActive,
  onClick,
  isDisabled = false,
}) => {
  return (
    <div
      onClick={() => !isDisabled && onClick()}
      className={clsx(
        'group relative flex items-center h-[18px] w-8 cursor-pointer transition-all duration-300 rounded-2xl px-0.5',
        { 'bg-gray-300 hover:bg-gray-400': !isActive && !isDisabled },
        { 'bg-blue-600': isActive && !isDisabled },
        { 'bg-blue-200': isDisabled && isActive },
        { 'bg-gray-300': isDisabled && !isActive },
      )}
    >
      <div
        className={clsx(
          'w-[14px] h-[14px] bg-offwhite rounded-full transform transition-all duration-300 bg-white',
          { 'translate-x-full': isActive },
        )}
      />
    </div>
  )
}
