import React from 'react'
import { Link } from 'react-router-dom'
import {
  useTheme,
  Body,
  ConnectWeb3Button,
  useWeb3Conntectors,
} from '@tryrolljs/design-system'
import MembershipsLogo from '../../assets/svg/memberships-logo.svg'
import { MAX_SCREEN_CONTENT_WIDTH } from '../../constants'
import { useConnectWalletModal } from '../../hooks/web3'
import { useNavigateDashboard, useNavigateHome } from '../../navigation/actions'
import { useCurrentPath } from '../../hooks'
import { routeDashboard, routeHome } from '../../navigation/routes'

export const PageHeader = () => {
  const theme = useTheme()
  const navigateHome = useNavigateHome()
  const navigateDashboard = useNavigateDashboard()
  const { isActivating } = useWeb3Conntectors()
  const connectWallet = useConnectWalletModal()

  return (
    <div
      className="flex h-16 justify-center shadow-xl"
      style={{ backgroundColor: theme.background.primary }}
    >
      <div
        className="flex flex-row w-4/5 h-full justify-between"
        style={{ maxWidth: MAX_SCREEN_CONTENT_WIDTH }}
      >
        <Link className="self-center" to="/">
          <MembershipsLogo />
        </Link>
        <div className="flex h-full">
          <div className="flex items-center h-full gap-10">
            <NavLink
              navigate={navigateHome}
              path={routeHome()}
              title="Explore Memberships"
            />
            <NavLink
              navigate={navigateDashboard}
              path={routeDashboard()}
              title="Dashboard"
            />
            <ConnectWeb3Button
              activity={isActivating}
              onPress={connectWallet}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

interface NavLinkProps {
  navigate: () => void
  path: string
  title: string
}

const NavLink: React.FC<NavLinkProps> = ({ navigate, path, title }) => {
  const theme = useTheme()
  const currentPath = useCurrentPath()
  return (
    <div className={'h-full flex items-center relative'}>
      <Body weight="bold" onPress={navigate}>
        {title}
      </Body>
      {currentPath === path && (
        <div
          style={{ backgroundColor: theme.text.highlight }}
          className="w-full absolute h-[3px] bottom-0 left-0"
        />
      )}
    </div>
  )
}
