import React, { useMemo } from 'react'
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import {
  Body,
  Button,
  LargeHeader,
  useModal,
  useTheme,
} from '@tryrolljs/design-system'
import {
  useCompleteStep,
  useFormValuesState,
  useUpdateFormState,
} from '../../hooks/selectors/create'
import { CreateStepHeading } from '../../molecules/createStepHeading'
import { InputContainer } from '../../molecules/inputContainer'
import { RemoveButtonIcon } from '../../molecules/removeButtonIcon'
import { CreateFormInputState } from '../../types'
import { CreateStepsId } from '../../types/create'
import AddIcon from '../../assets/svg/add.svg'
import { ConfirmationDeleteModal } from '../../molecules/confirmationDeleteModal'

const dataInfo = {
  description: {
    title: 'Description',
    description:
      'The content below will be shown on your memberships page and the information will be stored on-chain',
  },
  tiers: {
    title: 'Tiers',
    description:
      'Create tiers to offer your users different levels of membership.\nYou can name your tier, choose the amount of lots required to become a member and give a description.',
  },
}

const defaultTier = (): CreateFormInputState['metadata']['tiers'][number] => ({
  name: '',
  lots: 0,
  description: '',
})

export const CreateTierStep: React.FC = () => {
  const completeStep = useCompleteStep()
  const theme = useTheme()
  const defaultValues = useFormValuesState()
  const { updateMetadata } = useUpdateFormState()
  const methods = useForm<CreateFormInputState>({
    defaultValues,
    mode: 'onChange',
  })

  const { fields, append, remove } = useFieldArray({
    name: 'metadata.tiers',
    control: methods.control,
  })

  const onSubmit = () => {
    methods.handleSubmit((data) => {
      updateMetadata(data.metadata)
      completeStep(CreateStepsId.tiers)
    })()
  }

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-8">
          <CreateStepHeading
            title={dataInfo.description.title}
            description={dataInfo.description.description}
            tooltip="Roll Memberships publishes your membership on the Ethereum Blockchain, creating on-chain memberships perks that can be utilized across platforms."
          />
          <div className="flex gap-3 justify-between">
            <InputContainer
              label="Short Description"
              className={{ container: 'w-full' }}
              error={methods.formState.errors.metadata?.description?.message}
              count={[
                Number(methods.watch('metadata.description').length) || 0,
                400,
              ]}
            >
              <textarea
                className="w-full h-36"
                placeholder="Describe your membership"
                {...methods.register('metadata.description', {
                  maxLength: {
                    value: 400,
                    message: "Can't be longer than 400 characters",
                  },
                  required: {
                    value: true,
                    message: 'This field is required',
                  },
                })}
              />
            </InputContainer>
            <InputContainer
              label="Website Link"
              className={{ container: 'w-full' }}
              error={methods.formState.errors.metadata?.website?.message}
            >
              <input
                type="text"
                className="w-full"
                placeholder="https://website.com"
                {...methods.register('metadata.website', {
                  required: {
                    value: true,
                    message: 'This field is required',
                  },
                  pattern: {
                    value:
                      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
                    message: 'Pattern does not match',
                  },
                })}
              />
            </InputContainer>
          </div>
        </div>
        <hr className="border-[#EAEEF3]" />
        <div className="flex flex-col gap-6">
          <CreateStepHeading
            title={dataInfo.tiers.title}
            description={dataInfo.tiers.description}
          />
          {fields.map((field, i) => (
            <TierElem key={field.id} index={i} remove={() => remove(i)} />
          ))}
          <div className="w-fit">
            <Button variant="secondary" onPress={() => append(defaultTier())}>
              <div className="flex items-center gap-2">
                <AddIcon />
                <Body weight="regular" color={theme.text.primary}>
                  Add a tier
                </Body>
              </div>
            </Button>
          </div>
        </div>
        <div className="w-fit">
          <Button variant="primary" title="Continue" onPress={onSubmit} />
        </div>
      </div>
    </FormProvider>
  )
}

const TierElem: React.FC<{
  index: number
  remove: () => void
}> = ({ index, remove }) => {
  const theme = useTheme()
  const { isOpen, close, open } = useModal()
  const {
    register,
    control,
    formState: { errors },
    watch,
  } = useFormContext<CreateFormInputState>()
  const tierError = errors.metadata?.tiers?.[index]
  const tiersValue = useWatch({
    name: 'metadata.tiers',
    control,
  })
  const tierLotsValue = useMemo(
    () => tiersValue?.map((tier) => tier.lots) || [],
    [tiersValue],
  )
  return (
    <>
      <div className="flex flex-col gap-6 px-6 py-4 border border-[#EAEEF3] rounded-2xl">
        <div className="flex items-center gap-4">
          <LargeHeader weight="bold" color={theme.text.highlight}>
            #{index + 1}
          </LargeHeader>
          <RemoveButtonIcon onPress={open} />
        </div>
        <div className="flex flex-col gap-2 w-full">
          <LargeHeader weight="bold" color={theme.text.primary}>
            Details
          </LargeHeader>
          <div className="flex gap-6 w-full">
            <div className="flex flex-col gap-6 w-full">
              <InputContainer
                label="Tier Name"
                error={tierError?.name?.message}
              >
                <input
                  type="text"
                  className="w-full"
                  placeholder="Tier"
                  {...register(`metadata.tiers.${index}.name`, {
                    required: {
                      value: true,
                      message: 'This field is required',
                    },
                    maxLength: {
                      value: 100,
                      message: "Can't be longer than 100 characters",
                    },
                  })}
                />
              </InputContainer>
              <InputContainer
                label="Lots required"
                error={tierError?.lots?.message}
              >
                <input
                  type="number"
                  className="w-full"
                  {...register(`metadata.tiers.${index}.lots`, {
                    valueAsNumber: true,
                    required: {
                      value: true,
                      message: 'This field is required',
                    },
                    validate: {
                      fullNumber: (v) => v % 1 === 0 || 'Please enter a number',
                      repeaat: (v) =>
                        !tierLotsValue
                          .filter((_, i) => i !== index)
                          .includes(v) ||
                        'The lot number cannot be the same for different tiers',
                    },
                    min: {
                      value: 1,
                      message: 'This value has to be greater than 0',
                    },
                  })}
                />
              </InputContainer>
            </div>
            <div className="w-full">
              <InputContainer
                label="Description"
                error={tierError?.description?.message}
                count={[
                  Number(watch(`metadata.tiers.${index}.description`).length) ||
                    0,
                  200,
                ]}
              >
                <textarea
                  className="w-full h-36"
                  placeholder="Describe this tier"
                  {...register(`metadata.tiers.${index}.description`, {
                    required: {
                      value: true,
                      message: 'This field is required',
                    },
                    maxLength: {
                      value: 200,
                      message: "Can't be longer than 200 characters",
                    },
                  })}
                />
              </InputContainer>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationDeleteModal
        title="Delete Tier"
        description="Are you sure you want to delete this tier?"
        onConfirm={remove}
        closeModal={close}
        isOpen={isOpen}
      />
    </>
  )
}
