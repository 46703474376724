import { useForm } from 'react-hook-form'
import { Button, Header, LargeTitle, useTheme } from '@tryrolljs/design-system'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import { InputContainer } from '../inputContainer'

const url =
  'https://tryroll.us19.list-manage.com/subscribe/post?u=b458a18589f170620a8f919fc&amp;id=65fea334c7&amp;f_id=00cc8ae3f0'

export const MailChimpBanner = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string }>()
  const theme = useTheme()

  const onSubmit = (subscribe: (value: any) => void) => {
    handleSubmit((data) => {
      subscribe({
        MERGE0: data.email,
      })
    })()
  }

  return (
    <div className="flex w-full flex-col py-24 justify-center bg-gradient-to-r from-[#FCFCFC] to-[#C8CDE6] items-center">
      <div className="mb-4">
        <LargeTitle weight="bold">Never miss a Membership launch!</LargeTitle>
      </div>
      <div className="mb-10">
        <Header color={theme.text.secondary}>
          Subscribe to our list and be the first to know about upcoming Roll
          Memberships launches
        </Header>
      </div>
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status }) => (
          <div className="w-96 flex flex-col gap-4">
            {status === 'success' ? (
              <div className="flex w-full justify-center">
                <Header color={theme.text.primary}>
                  Thanks for subscribing!
                </Header>
              </div>
            ) : (
              <>
                <InputContainer
                  className={{ inputContainer: 'bg-white w-full' }}
                  error={errors.email?.message}
                >
                  <input
                    type="text"
                    placeholder="Your email address"
                    className="w-full"
                    {...register('email', {
                      required: {
                        value: true,
                        message: 'This field is required',
                      },
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: 'Invalid email address',
                      },
                    })}
                  />
                </InputContainer>
                <Button
                  variant="primary"
                  title="Subscribe"
                  onPress={() => onSubmit(subscribe)}
                  disabled={status === 'sending'}
                />
              </>
            )}
          </div>
        )}
      />
    </div>
  )
}
