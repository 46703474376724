import {
  Body,
  Button,
  LargeHeader,
  margin,
  useTheme,
  useSigner,
} from '@tryrolljs/design-system'
import getStartedImage from '../../assets/get-started.png'
import { useConnectWalletModal } from '../../hooks/web3'

export const GetStartedBanner = () => {
  const signer = useSigner()
  const connectWallet = useConnectWalletModal()
  const theme = useTheme()

  if (signer) {
    return null
  }

  return (
    <div className="flex items-center py-6 px-10 border rounded-2xl border-[#EAEEF3] w-[771px] max-w-[100%] m-auto">
      <div className="w-[183px] max-w-[100%]">
        <img src={getStartedImage} alt="Get started image" />
      </div>

      <div className="flex flex-col ml-12 justify-center items-start">
        <LargeHeader weight="bold">Ready to get started?</LargeHeader>
        <Body style={[margin.mt8]}>
          Connect your wallet to explore, claim and create memberships right
          now!{' '}
        </Body>
        <Button style={[margin.mt24]} variant="primary" onPress={connectWallet}>
          <Body weight="bold" color={theme.background.primary}>
            Connect Wallet
          </Body>
        </Button>
      </div>
    </div>
  )
}
