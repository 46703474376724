import { BigNumber, ethers } from 'ethers'
import { AddressZero } from '@ethersproject/constants'

export const getWeiBasedOnToken = (
  value: number | BigNumber,
  decimals: number,
) => {
  let stringValue = BigNumber.isBigNumber(value)
    ? value.toString()
    : String(value)

  const wei = ethers.utils.parseUnits(stringValue, decimals)
  return BigNumber.from(wei)
}

export const formatUnits = (value: BigNumber, decimals: number) => {
  return ethers.utils.formatUnits(value, decimals)
}

export const isAddressZero = (address: string) => {
  return address === AddressZero
}

export const isNativeToken = (address: string) => {
  return isAddressZero(address)
}
