import { useState, useEffect, useCallback, useMemo } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AppDispatch, RootState } from "../store";
import { NetworkStatus } from "../types";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAsync = <T, V = any, E = string>(
  asyncFunction: (arg: any) => Promise<T>,
  {
    variables,
    immediate = false
  }: {variables?: V, immediate?: boolean}
) => {
  const [status, setStatus] = useState<NetworkStatus>(NetworkStatus.idle);
  const [value, setValue] = useState<T | null>(null);
  const [error, setError] = useState<E | null>(null);

  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(async(ov?: V) => {
    setStatus(NetworkStatus.loading);
    setValue(null);
    setError(null);
    try {
      const response = await asyncFunction(ov || variables);
      setValue(response);
      setStatus(NetworkStatus.succeeded);
      return response;
    } catch (error) {
      setError((error as Error).message as any);
      setStatus(NetworkStatus.failed);
    }
  }, [asyncFunction]);

  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);

  return useMemo(() => {
    return { execute, status, value, error };
  }, [execute, status, value, error]);
};

export const useWithAnyAsync = <E>() => {
  const [status, setStatus] = useState<NetworkStatus>(NetworkStatus.idle);
  const [error, setError] = useState<E | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const execute = useCallback(async<T>(asyncFunction: () => Promise<T>) => {
    setStatus(NetworkStatus.loading);
    setIsLoading(true);
    setError(null);
    try {
      const response = await asyncFunction();
      setStatus(NetworkStatus.succeeded);
      return response;
    } catch (error) {
      setError((error as Error).message as any);
      setStatus(NetworkStatus.failed);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return useMemo(() => ({
    status,
    error,
    execute,
    isLoading
  }), [status, error, execute, isLoading]);
};

export const useCurrentPath = () => {
  const location = useLocation();

  const currentPath = location.pathname;

  return currentPath;
};
