import React from 'react'
import { Body, Button, Title, useTheme } from '@tryrolljs/design-system'
import DocumentTextIcon from '../../assets/svg/document-text.svg'
import { InfoTooltip } from '../infoTooltip'

export interface CreateStepHeadingProps {
  title: string
  description: string
  tooltip?: string
  action?: {
    title: string
    cb: () => void
  }
}

export const CreateStepHeading: React.FC<CreateStepHeadingProps> = ({
  title,
  description,
  action,
  tooltip,
}) => {
  const theme = useTheme()
  return (
    <div className="flex justify-between">
      <div className="flex flex-col">
        <div className="flex gap-2 items-center">
          <Title weight="bold" color={theme.text.primary}>
            {title}
          </Title>
          {tooltip && <InfoTooltip title={tooltip} />}
        </div>
        <Body color={theme.text.secondary}>{description}</Body>
      </div>
      {action && (
        <div className="w-fit">
          <Button variant="secondary" onPress={action.cb}>
            <div className="flex items-center gap-2">
              <DocumentTextIcon />
              <Body weight="regular" color={theme.text.primary}>
                {action.title}
              </Body>
            </div>
          </Button>
        </div>
      )}
    </div>
  )
}
