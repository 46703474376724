import { Toast, ToastProps } from '@tryrolljs/design-system'

export const toastSuccess = ({ title = 'Success!', ...props }: ToastProps) =>
  Toast.show({
    title,
    variant: 'success',
    ...props,
  })
export const toastError = ({
  title = 'Oh no, something went wrong',
  ...props
}: ToastProps) =>
  Toast.show({
    title,
    variant: 'error',
    ...props,
  })

export const toastInfo = ({ title = '', ...props }: ToastProps) =>
  Toast.show({
    title,
    variant: 'dark',
    ...props,
  })
